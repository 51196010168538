import { Heading, Spinner, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';

export const Pay = () => {
  const params = useParams<{ token: string }>();

  const { data, error } = trpc.generateCheckoutSession.useQuery(
    { token: params.token },
    { retry: false, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (data) window.location.href = data;
  }, [data]);

  if (error) {
    return (
      <Page title="Pay" showHeader={false}>
        <Stack spacing={4} h="90vh" align="center" justify="center">
          <Heading textAlign="center">Unable to redirect</Heading>
          <Heading textAlign="center" size="md" color="red.500">
            {error.message}
          </Heading>
        </Stack>
      </Page>
    );
  }

  return (
    <Page title="Pay" showHeader={false}>
      <Stack spacing={4} h="90vh" align="center" justify="center">
        <Spinner size="xl" />
        <Heading textAlign="center">Redirecting to payment</Heading>
      </Stack>
    </Page>
  );
};
