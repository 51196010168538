import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useSuccess = () => {
  const toast = useToast();

  const handleSuccess = useCallback(
    (message?: string) => {
      toast({
        title: 'Success',
        description: message ?? '',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    [toast],
  );

  return {
    handleSuccess,
  };
};
