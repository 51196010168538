import { CargoType, SelectOption } from '@pelicargo/types';

export const commodities = [
  {
    label: 'Machinery',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Spare Parts',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Electrical Equipment',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Nutritional Supplements',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Aircraft Parts',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  { label: 'Textiles & Fashion', cargoTypes: [CargoType.GeneralCargo] },
  {
    label: 'Consumer Commodities',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'General Cargo',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable, CargoType.DangerousGoods],
  },
  {
    label: 'Consolidated Cargo',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable, CargoType.DangerousGoods],
  },
  {
    label: 'Foliage',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Fresh Flowers',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Plants',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Seeds',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Foodstuff (processed)',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Milk Powder',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other Foodstuffs',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other Beverages (non-alcohol)',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other Drinks',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Alcoholic Beverage',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other Perishables',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  { label: 'Meat', cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable] },
  {
    label: 'Seafood',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  { label: 'Fish', cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable] },
  {
    label: 'Crabs (Live)',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Lobsters (Live)',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Fruits',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Vitamins',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other Fruits',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Vegetables',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Aircraft Engine',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Ship Spares',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Car Parts',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Equipment',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Vehicle / Car',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Vaccines',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Medical Supplies',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable, CargoType.DangerousGoods],
  },
  {
    label: 'Pharmaceuticals',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Other',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable, CargoType.DangerousGoods],
  },
  {
    label: 'Chemicals',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable, CargoType.DangerousGoods],
  },
  {
    label: 'Household Goods & Personal Effects',
    cargoTypes: [CargoType.GeneralCargo],
  },
  { label: 'Other Papers', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Documents', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Precious Gems', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Precious Metals', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Other Valuables', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Arts', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Bank Notes', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Machine Parts', cargoTypes: [CargoType.GeneralCargo] },
  { label: 'Metal, Non-Scrap', cargoTypes: [CargoType.GeneralCargo] },
  {
    label: 'Military Equipment (non-munitions)',
    cargoTypes: [CargoType.GeneralCargo],
  },
  { label: 'Office Supplies', cargoTypes: [CargoType.GeneralCargo] },
  {
    label: 'Cosmetics',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Oilfield Equipment',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'IT Equipment',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Cherries',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Grapes',
    cargoTypes: [CargoType.GeneralCargo, CargoType.Perishable],
  },
  {
    label: 'Lithium Metal Batteries',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Lithium Ion Batteries',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Equipment with Lithium Metal Batteries',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Equipment with Lithium Ion Batteries',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
  {
    label: 'Vehicle / Motorcycle',
    cargoTypes: [CargoType.GeneralCargo, CargoType.DangerousGoods],
  },
];

export const getCommodities = (key: CargoType): SelectOption[] => {
  const filteredCommodities: string[] = [];

  for (const commodity of commodities) {
    if (commodity.cargoTypes.includes(key)) {
      filteredCommodities.push(commodity.label);
    }
  }

  return filteredCommodities?.map((commodity) => ({
    label: commodity,
    value: commodity,
  }));
};
