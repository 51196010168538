import { Heading, HStack, Icon, Image, Stack, Text } from '@chakra-ui/react';
import { prettyDate } from '@pelicargo/utils';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { Link } from 'react-router-dom';

type LatestBlogsProps = {
  heading: string;
  subheading: string;
  max: number;
};
export const LatestBlogs = ({ heading, subheading, max }: LatestBlogsProps) => {
  const [blogs, setBlogs] = useState([]);

  const fetchLatestBlogs = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://api.storyblok.com/v2/cdn/stories?cv=1714505546&is_startpage=false&resolve_relations=blogPost.author%2CblogPost.category&starts_with=resources%2Fposts%2F&token=BV1xQiv9vknZwyfLqUo0NQtt&version=published',
      );
      const cappedBlogs = response.data.stories.slice(0, max);
      setBlogs(cappedBlogs);
    } catch (error) {
      console.error(error);
    }
  }, [max]);

  useEffect(() => {
    fetchLatestBlogs();
  }, [fetchLatestBlogs]);

  const renderBlog = (blog: any) => (
    <Stack
      key={blog.id}
      as={Link}
      p="4"
      borderWidth="1px"
      borderRadius="md"
      w="fit-content"
      maxW="md"
      to={`https://pelicargo.com/${blog.full_slug}`}
    >
      <Image src={blog.content.image.filename} alt={blog.content.title} borderRadius="md" />
      <Heading size="md">{blog.content.title}</Heading>
      <HStack>
        <Icon as={HiOutlineCalendar} />
        <Text>{prettyDate(blog.published_at, { timeZone: 'UTC' })}</Text>
      </HStack>
    </Stack>
  );

  return (
    <Stack>
      <Heading fontSize="3xl">{heading}</Heading>
      <Text align="left">{subheading}</Text>
      <Stack direction={{ base: 'column', md: 'row' }}>{blogs.map(renderBlog)}</Stack>
    </Stack>
  );
};
