import { trpc, TrpcInputs, TrpcOutputs } from '../../config/trpc';

export type RequestInput = NonNullable<TrpcInputs['createRequest']>;
export type RequestOutput = TrpcOutputs['getRequests']['requests'][0];

export const useRequests = (queryParams: TrpcInputs['getRequests']) => {
  return trpc.getRequests.useQuery(queryParams, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
