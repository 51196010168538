// available options according to docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#stripifinteger
// StripIfInteger actually just strips trailing zeroes if there are any, regardless of whether the number is an integer or decimal
export enum TrailingZeroDisplayOptions {
  Auto = 'auto',
  StripIfInteger = 'stripIfInteger',
}

export enum RoundingModeOptions {
  Ceil = 'ceil',
  HalfCeil = 'halfCeil',
}

type Options = {
  trailingZeroDisplay?: TrailingZeroDisplayOptions;
  roundingMode?: RoundingModeOptions;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const defaultOptions: Options = {
  trailingZeroDisplay: TrailingZeroDisplayOptions.StripIfInteger,
  roundingMode: RoundingModeOptions.HalfCeil,
  maximumFractionDigits: 0,
};

export const prettyNumber = (value: number | string | any, options?: Options) => {
  const { trailingZeroDisplay, roundingMode, minimumFractionDigits, maximumFractionDigits } = {
    ...defaultOptions,
    ...options,
  };
  const number = Number(value);
  if (isNaN(number)) return value;
  // we have to type the options as any because of tsconfig lib mismatch
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
    roundingMode,
    trailingZeroDisplay,
  } as any).format(number);
};
