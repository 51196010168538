import { Heading, Stack, Text } from '@chakra-ui/react';

type Props = {
  label: string;
  value?: string | number;
};

export const NewLabeledValue = ({ label, value }: Props) => {
  return (
    <Stack direction="column" spacing="0" w="auto">
      <Text textStyle="label" color="text.subtle">
        {label}
      </Text>
      <Heading as="h4" variant="h4">
        {value}
      </Heading>
    </Stack>
  );
};
