const baseStyle = {
  textAlign: 'left',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
};

const variants = {
  h1: {
    fontSize: '48px',
    lineHeight: '60px',
    fontWeight: 600,
  },
  h2: {
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 600,
  },
  h3: {
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 600,
  },
  h4: {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 600,
  },
  h5: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 600,
  },
  h6: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
  },
};

const heading = {
  baseStyle,
  variants,
};

export default heading;
