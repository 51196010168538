import {
  Badge,
  Button,
  Center,
  CenterProps,
  HStack,
  Icon,
  SkeletonText,
  Square,
  Stack,
  Text,
  useToast,
  VisuallyHiddenInput,
} from '@chakra-ui/react';
import { Document, DocumentType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { convertFileToBase64 } from '@pelicargo/ui';
import { omit } from 'lodash';
import { DragEvent, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiUpload } from 'react-icons/hi';
import { HiOutlineDocument, HiTrash } from 'react-icons/hi2';
import { useSessionStorage } from 'usehooks-ts';

import { trpc } from '../config/trpc';
import { RequestFormValues } from '../hooks/request/useForm';

type Props = CenterProps & {
  name: string;
  documentType: DocumentType;
  requestId?: number;
};

export const SimpleDropzone = ({ name, documentType, requestId, ...rest }: Props) => {
  const toast = useToast({ position: 'bottom', duration: 4000 });
  const [request, setRequest] = useSessionStorage<RequestFormValues>('request', {} as any);
  const [documentSessionId] = useSessionStorage('documentSessionId', '');

  const { setValue, register } = useFormContext();
  register(name);

  const documents = useWatch({ name, defaultValue: request?.documents || [] });

  const { mutateAsync: uploadDocument } = trpc.uploadDocument.useMutation();
  const { mutateAsync: deleteDocument, isLoading: isDeleting } = trpc.deleteDocument.useMutation();

  const [isUploading, setIsUploading] = useState(false);

  const onDrop = (files) => {
    uploadFile(files?.[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadFile = useCallback(
    async (file: File) => {
      try {
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 5) throw new Error('File size exceeds 5MB. Please choose a smaller file.');

        setIsUploading(true);
        const newDocument = await uploadDocument({
          content: await convertFileToBase64(file),
          filename: file.name,
          content_type: file.type,
          document_type: documentType,
          request_id: requestId,
          documentSessionId,
        });

        if (!newDocument?.id) throw new Error('Something went wrong. Please try again.');

        if (newDocument.type === DocumentType.DGD) {
          setValue(name, [newDocument]);
          setRequest({ ...request, documents: [newDocument] });
        } else {
          setValue(name, [...(request?.documents || []), newDocument]);
          setRequest({ ...request, documents: [...(request?.documents || []), newDocument] });
        }
      } catch (error) {
        toast({ title: 'Error', description: error?.message, status: 'error' });
      } finally {
        setIsUploading(false);
      }
    },
    [request, documentType, documentSessionId],
  );

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        await deleteDocument({ id });
        const nextDocuments = request?.documents?.filter((doc) => doc.id !== id) || [];

        setValue(name, nextDocuments);
        setRequest({ ...request, documents: nextDocuments });
      } catch (error) {
        toast({ title: 'Error', description: error?.message, status: 'error' });
      }
    },
    [deleteDocument, name, request, setRequest, setValue, toast],
  );

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (!file) return;
    await uploadFile(file);
  };

  const inputProps = getInputProps();

  const renderItem = useCallback(
    (item: Partial<Document>) => {
      return (
        <Stack direction="row" align="center" justify="space-between" w="full">
          <Stack direction="row" align="center">
            <Icon as={HiOutlineDocument} />
            <SkeletonText w="auto" noOfLines={1} isLoaded={!isUploading}>
              <Text fontWeight="bold">{item?.original_filename}</Text>
            </SkeletonText>
            <SkeletonText noOfLines={1} isLoaded={!isUploading}>
              <Badge colorScheme="green">{item?.type}</Badge>
            </SkeletonText>
          </Stack>
          <Button
            variant="link"
            colorScheme="red"
            rightIcon={<HiTrash />}
            isLoading={isDeleting}
            onClick={() => handleDelete(item.id)}
          >
            Click to delete
          </Button>
        </Stack>
      );
    },
    [isUploading, isDeleting, handleDelete],
  );

  const copy = useMemo(() => {
    return documentType === DocumentType.DGD && documents?.length > 0 ? 'Click to replace' : 'Click to upload';
  }, [documentType, documents?.length]);

  return (
    <Stack>
      <Show if={(documentType === DocumentType.DGD && !documents?.length) || documentType === DocumentType.MSDS}>
        <Center
          borderWidth="1px"
          borderColor="gray.300"
          borderStyle="dashed"
          borderRadius="lg"
          px="6"
          py="4"
          flexDirection="column"
          w="full"
          {...getRootProps()}
          {...rest}
        >
          <Stack spacing="2" py="2" align="center" onDrop={handleDrop}>
            <VisuallyHiddenInput type="file" display="none" {...omit(inputProps, 'size')} />
            <Square size="10" borderRadius="lg">
              <Icon as={HiUpload} boxSize="5" />
            </Square>
            <Stack align="center" textAlign="center" spacing="1">
              <HStack spacing="1" align="center" whiteSpace="nowrap">
                <Button variant="link" colorScheme="blue" size="md" p="0">
                  {copy}
                </Button>
                <Text fontSize="md">or drag and drop</Text>
              </HStack>
              <Text fontSize="xs">PDFs are recommended.</Text>
            </Stack>
          </Stack>
        </Center>
      </Show>
      <Stack spacing="2" w="full">
        {documents?.map(renderItem)}
      </Stack>
    </Stack>
  );
};
