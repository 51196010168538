import { Container, Heading, Input, InputGroup, InputRightAddon, Spinner, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';
import { CONTAINER_2XL } from '../../utils/constants';

export const AirlineDashboard = () => {
  const { airlineId } = useParams<{ airlineId: string }>();
  const {
    data: iframeUrl,
    error,
    isLoading,
  } = trpc.getAirlineDashboardUrl.useQuery(
    { airlineId },
    { refetchOnWindowFocus: false, enabled: !!airlineId, retry: false },
  );
  const [height, setHeight] = useState<number>(1900);
  const [airlineInput, setAirlineInput] = useState<string>('');
  const airlineValue = useDebounce(airlineInput, 1000);
  const navigate = useNavigate();

  useEffect(() => {
    const recalcScreen = () => {
      // Not the prettiest solution, but I'm not a frontend dev.
      // Metabase reorganizes itself at a width of 770, but we need to account for
      // the main page's padding around the iframe, which bumps it up to a magic 822
      if (window.innerWidth >= 1092) {
        setHeight(1900);
      } else if (window.innerWidth >= 992) {
        setHeight(3930);
      } else {
        setHeight(1900);
      }
    };
    recalcScreen();
    addEventListener('resize', recalcScreen);

    return () => removeEventListener('resize', recalcScreen);
  }, []);

  useEffect(() => {
    if (airlineValue) navigate(`/intelligence/${airlineValue}`);
  }, [airlineValue, navigate]);

  const handleOnChangeAirline = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAirlineInput(value);
  };

  if (!airlineId || !!error?.message || isLoading) {
    return (
      <Page title="Airline Dashboard" flex="1" alignContent="center">
        <Container maxW="xl" px={{ base: '4', lg: '16' }} py="10">
          <Stack minH="full" w="full" spacing="6" justify="center" align="center">
            <Heading variant="h3">Airline Intelligence</Heading>
            <Text>
              In order to view the market intelligence of an airline, please enter the 2-character airline ID below.
            </Text>
            <Stack w="full">
              <InputGroup>
                <Input
                  placeholder="Enter Airline ID"
                  borderRightWidth={0}
                  _focus={{
                    borderWidth: 1,
                    borderColor: 'gray.200',
                    borderRightWidth: 0,
                    outline: 'none !important;',
                    boxShadow: 'none',
                  }}
                  _hover={{
                    borderWidth: 1,
                    borderColor: 'gray.200',
                    borderRightWidth: 0,
                  }}
                  defaultValue={airlineId}
                  onChange={handleOnChangeAirline}
                />
                <InputRightAddon bg="transparent">
                  <Show if={isLoading && !!airlineId}>
                    <Spinner size="sm" />
                  </Show>
                </InputRightAddon>
              </InputGroup>
              <Show if={!!error?.message && !!airlineId}>
                <Text color="red.500">{error?.message}</Text>
              </Show>
            </Stack>
          </Stack>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Airline Dashboard" flex="1">
      <Container maxW={CONTAINER_2XL} px={{ base: '4', lg: '16' }} py="10">
        <Stack minH="full" w="full" spacing="6">
          <Heading variant="h3">Airline Intelligence</Heading>
          <Show if={!!iframeUrl}>
            <iframe
              src={iframeUrl}
              frameBorder={0}
              // If more rows are added, this will have to be adjusted, but otherwise should fit all
              height={height}
              style={{ backgroundColor: 'white' }}
            />
          </Show>
        </Stack>
      </Container>
    </Page>
  );
};
