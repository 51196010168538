import {
  AccountType,
  AircraftType,
  AircraftVariant,
  BacklogStatus,
  BatteryType,
  BatteryTypeVariant,
  BusinessType,
  CancelReason,
  CargoCooling,
  CargoTemperatureRange,
  CargoType,
  ClarificationReason,
  DimensionUnit,
  DocumentType,
  EarliestTenderOption,
  EstimatedTransitTime,
  ExpiryReason,
  FreightStatus,
  HandlingOptions,
  LineItemType,
  MarkupCategory,
  OnboardingStep,
  PackingGroup,
  PackingInstruction,
  PerUnitType,
  QuoteStatus,
  RateCategory,
  RateImportState,
  RequestStatus,
  RouteEditorAction,
  Section,
  ServiceType,
  ShipmentStatus,
  SurchargeCategory,
  SurchargeUnit,
  SurchargeWeightType,
  TimeOfDay,
  VariantType,
  WeightUnit,
} from '@pelicargo/types';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

import {
  getPrettyAccountType,
  getPrettyAircraftType,
  getPrettyAircraftVariant,
  getPrettyBacklogStatus,
  getPrettyBatteryType,
  getPrettyBatteryTypeVariant,
  getPrettyBusinessType,
  getPrettyCancelReason,
  getPrettyCargoCooling,
  getPrettyCargoTempRange,
  getPrettyCargoType,
  getPrettyClarificationReason,
  getPrettyDimensionUnit,
  getPrettyDocumentType,
  getPrettyEarliestTenderOption,
  getPrettyEstimatedTransitTime,
  getPrettyExpiryReason,
  getPrettyFreightStatus,
  getPrettyHandling,
  getPrettyLineItemType,
  getPrettyMarkupCategory,
  getPrettyOnboardingStep,
  getPrettyPackingGroup,
  getPrettyPackingInstruction,
  getPrettyPerUnitType,
  getPrettyQuoteStatus,
  getPrettyRateCategory,
  getPrettyRateImportState,
  getPrettyRequestStatus,
  getPrettyRouteEditorAction,
  getPrettySection,
  getPrettyServiceType,
  getPrettyShipmentStatus,
  getPrettySurchargeCategory,
  getPrettySurchargeUnit,
  getPrettySurchargeWeightType,
  getPrettyTimeOfDay,
  getPrettyVariantType,
  getPrettyWeightUnit,
} from './enums';

type PrettyOptions<T> = {
  label: string;
  value: T;
};

type OptGroupOptions<T> = {
  groupLabel: string;
  options: PrettyOptions<T>[];
};

const getPrettyOptions = <T>(enumValues: T[], getLabel: (value: T) => string): PrettyOptions<T>[] => {
  return enumValues.map((value) => ({
    label: getLabel(value),
    value,
  }));
};

const getPrettyOptGroupOptions = <T>(
  enumValues: { [key: string]: T[] },
  getLabel: (value: T) => string,
): OptGroupOptions<T>[] => {
  return Object.entries(enumValues).map(([groupLabel, values]) => ({
    groupLabel,
    options: values.map((value) => ({
      label: getLabel(value),
      value,
    })),
  }));
};

// Actual Options
export const countryCodeOptions = getPrettyOptions(
  getCountries(),
  (countryCode) => `${countryCode} (+${getCountryCallingCode(countryCode)})`,
);

export const handlingOptions = getPrettyOptions(Object.values(HandlingOptions), getPrettyHandling);

export const allCargoTypeOptions = getPrettyOptions(Object.values(CargoType), getPrettyCargoType);

export const adminCargoTypeOptions = getPrettyOptions(
  [CargoType.GeneralCargo, CargoType.DangerousGoods, CargoType.Perishable, CargoType.Pharma, CargoType.Valuable],
  getPrettyCargoType,
);

export const customerCargoTypeOptions = getPrettyOptions(
  [CargoType.GeneralCargo, CargoType.DangerousGoods, CargoType.Batteries, CargoType.Perishable],
  getPrettyCargoType,
);

export const cargoCoolingOptions = getPrettyOptions(Object.values(CargoCooling), getPrettyCargoCooling);

export const cargoTemperatureRangeOptions = getPrettyOptions(
  Object.values(CargoTemperatureRange),
  getPrettyCargoTempRange,
);

export const aircraftVariantOptions = getPrettyOptions(Object.values(AircraftVariant), getPrettyAircraftVariant);

export const surchargeUnitOptions = getPrettyOptions(Object.values(SurchargeUnit), getPrettySurchargeUnit);

export const perUnitTypeOptions = getPrettyOptions(Object.values(PerUnitType), getPrettyPerUnitType);

export const surchargeWeightTypeOptions = getPrettyOptions(
  Object.values(SurchargeWeightType),
  getPrettySurchargeWeightType,
);

export const serviceTypeOptions = getPrettyOptions(Object.values(ServiceType), getPrettyServiceType);

export const variantTypeOptions = getPrettyOptions(Object.values(VariantType), getPrettyVariantType);

export const surchargeCategoryOptions = getPrettyOptions(Object.values(SurchargeCategory), getPrettySurchargeCategory);

export const backlogStatusOptions = getPrettyOptions(Object.values(BacklogStatus), getPrettyBacklogStatus);

export const estimatedTransitTimeOptions = getPrettyOptions(
  Object.values(EstimatedTransitTime),
  getPrettyEstimatedTransitTime,
);

export const quoteStatusOptions = getPrettyOptions(Object.values(QuoteStatus), getPrettyQuoteStatus);

export const requestStatusOptions = getPrettyOptions(Object.values(RequestStatus), getPrettyRequestStatus);

export const weightUnitOptions = getPrettyOptions(Object.values(WeightUnit), getPrettyWeightUnit);

export const dimensionUnitOptions = getPrettyOptions(Object.values(DimensionUnit), getPrettyDimensionUnit);

export const aircraftTypeOptions = getPrettyOptions(Object.values(AircraftType), getPrettyAircraftType);

export const freightStatusOptions = getPrettyOptions(Object.values(FreightStatus), getPrettyFreightStatus);

export const expiryReasonOptions = getPrettyOptions(Object.values(ExpiryReason), getPrettyExpiryReason);

export const packingGroupOptions = getPrettyOptions(Object.values(PackingGroup), getPrettyPackingGroup);

export const packingInstructionOptions = getPrettyOptions(
  Object.values(PackingInstruction),
  getPrettyPackingInstruction,
);

export const sectionOptions = getPrettyOptions(Object.values(Section), getPrettySection);

export const batteryTypeOptions = getPrettyOptions(Object.values(BatteryType), getPrettyBatteryType);

export const batteryTypeVariantOptions = getPrettyOptions(
  Object.values(BatteryTypeVariant),
  getPrettyBatteryTypeVariant,
);

export const documentTypeOptions = getPrettyOptions(Object.values(DocumentType), getPrettyDocumentType);

export const accountTypeOptions = getPrettyOptions(Object.values(AccountType), getPrettyAccountType);

export const businessTypeOptions = getPrettyOptions(Object.values(BusinessType), getPrettyBusinessType);

export const shipmentStatusOptions = getPrettyOptions(Object.values(ShipmentStatus), getPrettyShipmentStatus);

export const rateImportStateOptions = getPrettyOptions(Object.values(RateImportState), getPrettyRateImportState);

export const timeOfDayOptions = getPrettyOptions(Object.values(TimeOfDay), getPrettyTimeOfDay);

export const earliestTenderOptions = getPrettyOptions(
  Object.values(EarliestTenderOption),
  getPrettyEarliestTenderOption,
);

export const onboardingStepOptions = getPrettyOptions(Object.values(OnboardingStep), getPrettyOnboardingStep);

export const clarificationReasonOptions = getPrettyOptions(
  Object.values(ClarificationReason),
  getPrettyClarificationReason,
);

export const cancelReasonOptions = getPrettyOptions(Object.values(CancelReason), getPrettyCancelReason);

export const routeEditorActions = getPrettyOptions(Object.values(RouteEditorAction), getPrettyRouteEditorAction);

export const markupCategoryOptions = getPrettyOptions(Object.values(MarkupCategory), getPrettyMarkupCategory);

// Option Groups

const lineItemTypes = {
  Rate: {
    values: Object.values(RateCategory),
    labelProvider: getPrettyRateCategory,
  },
  Surcharge: {
    values: Object.values(SurchargeCategory),
    labelProvider: getPrettySurchargeCategory,
  },
  Markup: {
    values: Object.values(MarkupCategory),
    labelProvider: getPrettyLineItemType,
  },
  Other: {
    values: [LineItemType.OTHER],
    labelProvider: getPrettyLineItemType,
  },
};

export const lineItemTypeOptions = getPrettyOptGroupOptions(
  Object.fromEntries(Object.entries(lineItemTypes).map(([key, { values }]) => [key, values])),
  (value: RateCategory | SurchargeCategory | LineItemType | MarkupCategory) => {
    if (Object.values(RateCategory).includes(value as RateCategory)) {
      return lineItemTypes.Rate.labelProvider(value as RateCategory);
    }
    if (Object.values(SurchargeCategory).includes(value as SurchargeCategory)) {
      return lineItemTypes.Surcharge.labelProvider(value as SurchargeCategory);
    }
    return lineItemTypes.Other.labelProvider(value as LineItemType);
  },
);
