import { TrpcOutputs } from '../config/trpc';

export namespace API {
  export type AcceptAccountInviteInput = TrpcOutputs['acceptAccountInvite'];

  // Quote Listing Page
  export type RequestQuotes = TrpcOutputs['getRequestQuote']['quotes'];
  export type RequestQuote = TrpcOutputs['getRequestQuote']['quotes'][0];

  // Book Quote Page
  export type GetQuote = TrpcOutputs['getQuote'];
  export enum NotificationCategory {
    Quote = 'Quote',
    Booking = 'Booking',
    All = 'All',
  }
}
