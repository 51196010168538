import { Checkbox, CheckboxGroup, IconButton, Stack, Switch, Text } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { HiChevronLeft } from 'react-icons/hi';

import { QuoteFilters } from '../../hooks/useQuoteFilters';

type Props = {
  filters: QuoteFilters;
  origins?: string[];
  destinations?: string[];
  handleCheckboxFilterChange: (key: string) => (nextValue: string[]) => void;
  resetView: () => void;
};

export const AirportFilter = ({
  filters,
  origins = [],
  destinations = [],
  handleCheckboxFilterChange,
  resetView,
}: Props) => {
  const isAllSelected = useMemo(() => {
    return filters?.origins?.length + filters?.destinations?.length === origins?.concat(destinations)?.length;
  }, [filters, origins, destinations]);

  const handleChange = useCallback(() => {
    if (isAllSelected) {
      handleCheckboxFilterChange('origins')([]);
      handleCheckboxFilterChange('destinations')([]);
    } else {
      handleCheckboxFilterChange('origins')(origins);
      handleCheckboxFilterChange('destinations')(destinations);
    }
  }, [isAllSelected, origins, destinations, handleCheckboxFilterChange]);

  return (
    <Stack spacing="2" pt="2">
      <Stack direction="row" align="center" spacing="2">
        <IconButton
          aria-label="Go back"
          icon={<HiChevronLeft />}
          variant="link"
          size="sm"
          display={{ base: 'flex', md: 'none' }}
          minW="auto"
          onClick={resetView}
        />
        <Text textStyle="label">Airports</Text>
      </Stack>
      <Stack direction="row" align="center" justify="space-between" w="full">
        <Text>Select all airports</Text>
        <Switch
          size="sm"
          isChecked={filters?.origins?.length + filters?.destinations?.length === origins?.concat(destinations)?.length}
          onChange={handleChange}
        />
      </Stack>
      <Stack>
        <Text textStyle="label">Origin</Text>
        <CheckboxGroup value={filters?.origins} onChange={handleCheckboxFilterChange('origins')}>
          {origins?.map((node) => (
            <Checkbox key={node} value={node}>
              {node}
            </Checkbox>
          ))}
        </CheckboxGroup>
        <Text textStyle="label">Destination</Text>
        <CheckboxGroup value={filters?.destinations} onChange={handleCheckboxFilterChange('destinations')}>
          {destinations?.map((node) => (
            <Checkbox key={node} value={node}>
              {node}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Stack>
    </Stack>
  );
};
