import { Icon, IconProps, useColorModeValue } from '@chakra-ui/react';

export const IconStackable = ({ ...rest }: IconProps) => {
  return (
    <Icon w="24px" h="24px" fill="none" {...rest}>
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        fillRule="evenodd"
        d="M12.356 1.59a.75.75 0 0 0-.712 0l-9.75 5.25a.75.75 0 0 0 0 1.32l2.953 1.59-2.953 1.59a.75.75 0 0 0 0 1.32l2.953 1.59-2.953 1.59a.75.75 0 0 0 0 1.32l9.75 5.25c.222.12.49.12.712 0l9.75-5.25a.75.75 0 0 0 0-1.32l-2.953-1.59 2.953-1.59a.75.75 0 0 0 0-1.32l-2.953-1.59 2.953-1.59a.75.75 0 0 0 0-1.32l-9.75-5.25ZM3.832 12l2.597-1.398 5.215 2.808c.222.12.49.12.712 0l5.215-2.808L20.168 12 12 16.398 3.832 12Zm0 4.5 2.597-1.398 5.215 2.808c.222.12.49.12.712 0l5.215-2.808 2.597 1.398L12 20.898 3.832 16.5ZM12 11.898 3.832 7.5 12 3.102 20.168 7.5 12 11.898Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
