import { Box, Flex, FormLabel, Heading, Icon, Spinner, Stack, Textarea, Tooltip } from '@chakra-ui/react';
import { HandlingOptions } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { useDebounce, useSessionStorage } from 'usehooks-ts';

import { CargoPlane } from '../../components/CargoPlane';
import { SimpleRequestForm } from '../../components/Forms/SimpleRequestForm';
import { Hint } from '../../components/Hint';
import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { RequestFormValues } from '../../hooks/request/useForm';
import { formatRequestForm } from '../../utils/formatRequestForm';

export const Home = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [ingestedRequest, setIngestedRequest] = useState();
  const [, setRequest] = useSessionStorage('request', {});
  const [requestTextInput, setRequestText] = useState('');
  const requestText = useDebounce(requestTextInput, 500);
  const { mutate: ingestRequest, data, isLoading } = trpc.ingestRequest.useMutation();

  useEffect(() => {
    if (requestText) ingestRequest({ requestText });
  }, [ingestRequest, requestText]);

  useEffect(() => {
    if (data) {
      setIngestedRequest({
        ...data,
        is_llm_generated: true,
        cargo: data.cargo.map((cargo) => ({
          ...cargo,
          handling: cargo?.handling?.[0] as HandlingOptions,
        })),
      });
    }
  }, [data, setIngestedRequest]);

  const onSubmit = useCallback(
    async (values: RequestFormValues) => {
      const formattedRequestForm = formatRequestForm(values);
      setRequest(formattedRequestForm);

      if (!isLoggedIn) {
        navigate('/login');
        return;
      }

      navigate('/request-quote');
    },
    [isLoggedIn, navigate, setRequest],
  );

  return (
    <Page title="Home" flex="1" position="relative">
      <CargoPlane maxW="800px" bottom="0" />
      <Stack
        spacing={4}
        px={{ base: '4', md: '8', lg: '20', xl: '40' }}
        py={{ base: '16', md: '16', lg: '20' }}
        maxW="full"
        w="full"
        minH="90vh"
        h="full"
      >
        <Heading variant="h1">Choose the best quotes</Heading>
        <Stack spacing="16" direction="row" zIndex="2">
          <Hint name="home-requestForm" placement="auto-start">
            <Box w="full" maxW="4xl" flex="2">
              <SimpleRequestForm onSubmit={onSubmit} ingestedRequest={ingestedRequest} />
            </Box>
          </Hint>
          <Show if={isLoggedIn}>
            <Box w="full" h="auto" maxH="3xl" flex="1" position="relative" display={{ base: 'none', md: 'unset' }}>
              <Flex
                direction="column"
                h="full"
                bg="white"
                borderWidth={1}
                borderColor="border.default"
                borderRadius="md"
                p="4"
              >
                <Stack direction="row" justify="space-between">
                  <FormLabel textTransform="uppercase" fontSize="xs" fontWeight="600" mb="1">
                    Shipment Importer
                  </FormLabel>
                  <Tooltip
                    label="You are responsible for the details that you submit. Always double-check that information is correct before proceeding."
                    placement="top"
                  >
                    <Box>
                      <Icon as={HiOutlineInformationCircle} color="gray.500" />
                    </Box>
                  </Tooltip>
                </Stack>
                <Textarea
                  h="full"
                  variant="unstyled"
                  placeholder="Paste your shipment details here to pre-fill with Pelicargo AI"
                  onChange={(event) => setRequestText(event?.target?.value)}
                />
              </Flex>
              <Show if={isLoading}>
                <Stack
                  position="absolute"
                  w="full"
                  h="full"
                  top="0"
                  left="0"
                  zIndex="3"
                  justify="center"
                  align="center"
                  bg="gray"
                  opacity="0.5"
                  borderRadius="md"
                >
                  <Spinner />
                </Stack>
              </Show>
            </Box>
          </Show>
        </Stack>
      </Stack>
    </Page>
  );
};
