export default {
  slate: {
    '50': '#ebf1fd',
    '100': '#cfd6e5',
    '200': '#b1bbce',
    '300': '#92a0b9',
    '400': '#7485a5',
    '500': '#5a6c8b',
    '600': '#46546d',
    '700': '#313c4e',
    '800': '#1b2431',
    '900': '#060c17',
  },
  gray: {
    '50': '#f9fafa',
    '100': '#f1f1f2',
    '200': '#e7e7e8',
    '300': '#d3d4d5',
    '400': '#abadaf',
    '500': '#7d7f83',
    '600': '#52555a',
    '700': '#33373d',
    '800': '#1d2025',
    '900': '#171a1d',
  },
  purple: {
    '50': '#f9f6fd',
    '100': '#e5daf8',
    '200': '#d3bef4',
    '300': '#b795ec',
    '400': '#a379e7',
    '500': '#8952e0',
    '600': '#7434db',
    '700': '#6023c0',
    '800': '#4f1d9e',
    '900': '#3b1676',
  },
  pink: {
    '50': '#fdf5f9',
    '100': '#f8d9e7',
    '200': '#f3b9d3',
    '300': '#eb8db8',
    '400': '#e56ba2',
    '500': '#dc3882',
    '600': '#c4246c',
    '700': '#a01d58',
    '800': '#7d1745',
    '900': '#5d1133',
  },
  red: {
    '50': '#fdf6f5',
    '100': '#f8d9d8',
    '200': '#f1b8b4',
    '300': '#e98d87',
    '400': '#e4726c',
    '500': '#dc4a41',
    '600': '#d2140a',
    '700': '#ac0900',
    '800': '#930800',
    '900': '#6d0600',
  },
  orange: {
    '50': '#fdfaf6',
    '100': '#f9ebdb',
    '200': '#f1d4b1',
    '300': '#e6b273',
    '400': '#dc9239',
    '500': '#c37b24',
    '600': '#a5681e',
    '700': '#835318',
    '800': '#674113',
    '900': '#553610',
  },
  yellow: {
    '50': '#fffefb',
    '100': '#fff8e9',
    '200': '#feecbd',
    '300': '#fddc87',
    '400': '#fbc434',
    '500': '#d2a01e',
    '600': '#a88018',
    '700': '#836413',
    '800': '#624b0e',
    '900': '#513e0c',
  },
  green: {
    '50': '#f7fdfb',
    '100': '#d2f2e7',
    '200': '#9fe3cd',
    '300': '#64d2ad',
    '400': '#1dbd88',
    '500': '#0ea371',
    '600': '#0c875e',
    '700': '#096949',
    '800': '#07563c',
    '900': '#064731',
  },
  teal: {
    '50': '#f1fcfc',
    '100': '#c0f1f4',
    '200': '#84e4e9',
    '300': '#2dd1da',
    '400': '#22b2ba',
    '500': '#1d979e',
    '600': '#187b80',
    '700': '#125f64',
    '800': '#0f5053',
    '900': '#0d4244',
  },
  cyan: {
    '50': '#f4fbfd',
    '100': '#d0eef7',
    '200': '#bae7f3',
    '300': '#a2deee',
    '400': '#53c2e1',
    '500': '#2ab4d9',
    '600': '#24a2c4',
    '700': '#1e86a2',
    '800': '#196e85',
    '900': '#135567',
  },
  blue: {
    '50': '#f1f6fd',
    '100': '#cde0f6',
    '200': '#a8c8f0',
    '300': '#7fafe8',
    '400': '#5896e1',
    '500': '#347fdb',
    '600': '#236abf',
    '700': '#1b5192',
    '800': '#164278',
    '900': '#123662',
  },
  indigo: {
    '50': '#f8f7fc',
    '100': '#e1ddf5',
    '200': '#c8c0ec',
    '300': '#a89de2',
    '400': '#9789dc',
    '500': '#7f6ed4',
    '600': '#6a58c9',
    '700': '#5546a1',
    '800': '#483c88',
    '900': '#342b62',
  },
};
