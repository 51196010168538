import { Button, Stack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { OnboardingStep } from '@pelicargo/types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleCheckbox } from '../../components/SimpleCheckbox';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleTagsInput } from '../../components/SimpleTagsInput';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { useOnboarding } from '../../hooks/onboarding/useOnboarding';
import { useScreenView } from '../../hooks/useScreenView';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  emails: string[];
  is_open_to_domain_whitelist: boolean;
};

const schema = z.object({
  emails: z.array(z.string().email()).min(1, 'Please add at least one email address to invite.'),
  is_open_to_domain_whitelist: z.boolean(),
});

export const RegisterInvite = () => {
  const toast = useToast();
  const navigate = useNavigate();
  useScreenView(MixpanelEvent.InviteView);
  const { setOnboardingStep } = useOnboarding();
  const { mutateAsync: sendAccountInvites, isLoading: isSubmitting } = trpc.sendAccountInvites.useMutation();
  const { user } = useAuth();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      emails: [],
      is_open_to_domain_whitelist: true,
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      trackEvent(MixpanelEvent.InviteSubmit);
      await sendAccountInvites({
        emails: values.emails,
        is_open_to_domain_whitelist: values.is_open_to_domain_whitelist,
      });
      toast({
        title: 'Invites sent',
        description: 'We sent an invite to your colleague(s)',
        status: 'success',
      });
      navigate('/');
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error.message,
        status: 'error',
      });
    }
  };

  const handleSkip = async () => {
    await setOnboardingStep(OnboardingStep.COMPLETE);
    navigate('/');
  };

  const workspaceDomain = useMemo(() => {
    // Get all unique domains
    const domain = user?.email?.split('@')?.[1] || '';
    return `@${domain}`;
  }, [user?.email]);

  return (
    <Page title="Company" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Invite users"
        subHeading="Your account will be linked to your colleagues so please invite them to work on shipments together."
        step={3}
      >
        <SimpleForm {...(methods as any)} onSubmit={handleSubmit}>
          <SimpleTagsInput name="emails" placeholder="Ex. jack@example.com, jill@example.com" />
          <Stack direction={{ base: 'column', md: 'row' }} align="center" justify="flex-start" spacing="6" w="full">
            <SimpleCheckbox name="is_open_to_domain_whitelist" colorScheme="primary" size="lg" borderWidth="0">
              Let anyone with a <b>{workspaceDomain}</b> email join this workspace
            </SimpleCheckbox>
            <Stack direction="row" align="center" justify="flex-end" spacing="6">
              <Button size="lg" colorScheme="gray" variant="link" onClick={handleSkip}>
                Skip this step
              </Button>
              <Button size="lg" colorScheme="primary" type="submit" isLoading={isSubmitting}>
                Continue
              </Button>
            </Stack>
          </Stack>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
