import {
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DataTable, DataTableSearch } from '@pelicargo/datatable';
import { Address } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { HiCheck } from 'react-icons/hi2';

import { useAccounts } from '../hooks/account/useAccounts';
import { useTargetAccount } from '../hooks/useTargetAccount';

type TableColumns = {
  select: boolean;
  id: number;
  name: string;
  address: Address;
  iata_number: string;
  is_iata_number_verified: boolean;
  requests: { created_at: Date }[];
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountsModal = ({ isOpen, onClose }: Props) => {
  const { targetAccount, changeTargetAccount } = useTargetAccount();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [query, setQuery] = useState('');

  const take = 10;
  const skip = take * pageIndex;
  const { data, count } = useAccounts({
    skip,
    take,
    search: query,
  });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [query]);

  const onRowClick = useCallback(
    (row: TableColumns) => {
      changeTargetAccount({
        id: row?.id,
        name: row?.name,
        iata_number: row?.iata_number,
        is_iata_number_verified: row?.is_iata_number_verified,
      });

      onClose();
    },
    [changeTargetAccount, onClose],
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const columnHelper = createColumnHelper<TableColumns>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        header: '',
        cell: (info) => (
          <Show if={String(targetAccount?.id) === String(info.row.original.id)}>
            <Icon as={HiCheck} />
          </Show>
        ),
      }),
      columnHelper.accessor('id', {
        header: 'ID',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('name', {
        header: 'Freight Forwarder',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('address', {
        header: 'City',
        cell: (info) => info.getValue()?.city,
      }),
      columnHelper.accessor('iata_number', {
        header: 'IATA',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('is_iata_number_verified', {
        header: '',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('requests', {
        header: 'Last Request',
        cell: (row: any) => {
          const value = row.getValue();
          const createdAt = value?.[0]?.created_at;
          if (!createdAt) return null;
          return createdAt ? format(new Date(createdAt), 'MMM d, Y') : null;
        },
      }),
    ],
    [columnHelper, targetAccount?.id],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  return (
    <Modal size={{ base: 'full', lg: '4xl' }} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="full" maxH={{ base: 'full', lg: '83vh' }} overflow="auto">
        <ModalCloseButton />
        <ModalHeader>
          <Stack direction="row" align="center" justify="space-between">
            <Stack spacing="1">
              <Heading variant="h4">Accounts</Heading>
              <Text textStyle="caption">Click on an account to view</Text>
            </Stack>
          </Stack>
        </ModalHeader>

        <ModalBody h="full">
          <Stack spacing="4" h="full">
            <DataTableSearch value={query} onChange={handleSearchChange} />
            <DataTable
              columns={columns}
              data={data ?? []}
              dataCount={count ?? 0}
              pagination={pagination}
              setPagination={setPagination}
              columnVisibility={{
                id: false,
                is_iata_number_verified: false,
              }}
              onRowClick={onRowClick}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
