import { Center, CenterProps, Text } from '@chakra-ui/react';

import { DayStyleConfig } from '../type';

type Props = CenterProps & {
  day?: number | string;
  dayStyleConfig: DayStyleConfig;
};

export const Active = ({ day, dayStyleConfig, ...rest }: Props) => {
  const { size, activeBgColor, activeColor, fontSize } = dayStyleConfig;
  return (
    <Center
      {...rest}
      m="auto"
      h={size}
      w={size}
      cursor="pointer"
      pos="relative"
      _before={{
        content: '""',
        pos: 'absolute',
        zIndex: '0',
        borderRadius: 'md',
        h: 'full',
        w: 'full',
        bg: activeBgColor,
        transition: 'all 1200ms ease',
      }}
    >
      <Text pos="relative" fontSize={fontSize} color={activeColor} zIndex="1">
        {day}
      </Text>
    </Center>
  );
};
