import { Button, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { supabaseClient } from '../../utils/supabaseClient';

type FormValues = {
  email: string;
};

const validationSchema = z.object({
  email: z.string({ required_error: 'Email is required' }).email(),
});

export const ForgotPassword = () => {
  const toast = useToast({ position: 'bottom', size: 'sm', duration: 3000 });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsSubmitting(true);

      const { error } = await supabaseClient.auth.resetPasswordForEmail(values.email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw Error(error?.message);
      toast({
        title: 'Magic Link Sent',
        description: 'Check your email for the login link',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page title="Forgot Password" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Forgot Password"
        subHeading="Please type your registered email below so you can reset your password."
      >
        <SimpleForm onSubmit={handleSubmit} {...methods}>
          <SimpleInput name="email" label="Email" placeholder="Email" variant="outline" />
          <Button colorScheme="primary" size="lg" alignSelf="flex-end" type="submit" isLoading={isSubmitting}>
            Send Reset Link
          </Button>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
