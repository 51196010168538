import { Button, Stack, Text, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimpleSignature } from '../../components/SimpleSignature';
import { trpc } from '../../config/trpc';
import { useScreenView } from '../../hooks/useScreenView';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  title: string;
  signature: string;
};

const schema = z.object({
  title: z.string({ required_error: 'Title is required' }),
  signature: z.string({ required_error: 'Signature is required' }),
});

export const RegisterSignature = () => {
  const toast = useToast({ position: 'bottom', size: 'sm', duration: 3000 });
  useScreenView(MixpanelEvent.SignatureView);

  const { mutateAsync: acceptTos, isLoading: isSubmitting } = trpc.acceptTos.useMutation();

  const methods = useForm<Partial<FormValues>>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        trackEvent(MixpanelEvent.SignatureSubmit);
        await acceptTos({ title: values.title, signature: values.signature });

        toast({
          title: 'Success',
          description: 'All signed up!',
          status: 'success',
        });
        window.location.reload();
      } catch (error) {
        toast({
          title: 'Company could not be created',
          description: error.message,
          status: 'error',
        });
      }
    },
    [acceptTos, toast],
  );

  return (
    <Page title="Sign" showHeader={false} showFooter={false}>
      <OnboardingLayout heading="Welcome to Pelicargo" step={2}>
        <SimpleForm {...methods} onSubmit={handleSubmit}>
          <Stack>
            <Text textStyle="bodySmall">
              By signing below you accept the Pelicargo general{' '}
              <Button as={Link} to="/terms" size="sm" variant="link" colorScheme="primary">
                Terms of Service
              </Button>{' '}
              and{' '}
              <Button as={Link} to="/privacy" size="sm" variant="link" colorScheme="primary">
                Privacy Policy
              </Button>
              .
            </Text>
            <Text textStyle="bodySmall">
              1. Forwarder authorizes Pelicargo to solicit quotes and bookings on its behalf from Carriers for the air
              transportation of Forwarder's customers' cargo, and to communicate with Forwarder and Carrier personnel
              for that purpose. This authorization is specifically limited to the activities described in the foregoing
              sentence and may be revoked by Forwarder at any time upon written notice to Pelicargo or account
              cancelation.
            </Text>
            <Text textStyle="bodySmall">
              2. Forwarder acknowledges that, under the Pelicargo Terms of Service, Forwarder is responsible for
              providing accurate information about its air-transportation needs to Pelicargo, and that Pelicargo relies
              on the information regarding quotes for air transportation that it receives from Carriers. That said,
              Pelicargo is responsible for its own errors conveying information supplied by Forwarder to Carriers, or in
              conveying information supplied by Carriers to Forwarder.
            </Text>
            <Text textStyle="bodySmall">
              3. Forwarder acknowledges that, under the Pelicargo Terms of Service, Pelicargo will handle any
              competitively sensitive information it obtains in a manner consistent with U.S. antitrust laws.
            </Text>
          </Stack>
          <Stack>
            <SimpleInput name="title" variant="outline" label="Job Title" w="full" />
            <SimpleSignature name="signature" variant="outline" />
          </Stack>
          <Button colorScheme="primary" size="lg" alignSelf="flex-end" type="submit" isLoading={isSubmitting}>
            Continue
          </Button>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
