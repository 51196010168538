import { Image, useBreakpointValue } from '@chakra-ui/react';

import primaryLogo from './logo.svg';

const DesktopLogo = () => {
  return <Image src={primaryLogo} height={19} width={194} alt="Pelicargo Logo" />;
};

type Props = {
  desktopOnly?: boolean;
};

export const Logo = ({ desktopOnly = false }: Props) => {
  const logo = useBreakpointValue({
    ssr: false,
    base: <DesktopLogo />,
    md: <DesktopLogo />,
  });

  return desktopOnly ? <DesktopLogo /> : logo;
};
