import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { Request, RequireOnly } from '@pelicargo/types';
import { useEffect } from 'react';
import { HiChevronLeft } from 'react-icons/hi2';
import { Link, useParams } from 'react-router-dom';

import { ENV } from '../config/env';
import { trpc } from '../config/trpc';
import { useAuth } from '../hooks/auth/useAuth';
import { useFrontChatBoot } from '../hooks/useFrontChatBoot';

type LinkedRequestModalProps = {
  disclosure: UseDisclosureProps;
  request: RequireOnly<Request, 'main_request_id'>;
};

export const LinkedRequestModal = ({ request, disclosure }: LinkedRequestModalProps) => {
  const modalDisclosure = useDisclosure(disclosure);
  const { data: frontSecret } = trpc.getFrontSecret.useQuery();
  const { initialize } = useFrontChatBoot(document.body);
  const params: { requestId?: string } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (request?.main_request_id) {
      modalDisclosure.onOpen();
    } else {
      modalDisclosure.onClose();
    }
  }, [modalDisclosure, request?.main_request_id]);

  const handleSupport = () => {
    if (!initialize || !frontSecret) {
      return;
    }

    initialize({
      chatId: ENV.FRONT_CHAT_ID,
      token: frontSecret,
      email: user?.email,
      name: user?.full_name,
      requestId: params?.requestId,
      useDefaultLauncher: true,
    });
  };

  return (
    <Modal {...modalDisclosure} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent alignSelf="center">
        <ModalHeader>This is a duplicate request</ModalHeader>
        <ModalBody>
          <Stack>
            <Text>
              This request has been marked as duplicate and can no longer be actioned. Please make any changes or
              bookings to the main request.
            </Text>
            <Text fontSize="sm" color="gray.500">
              If you believe this is incorrect, please{' '}
              <Button onClick={handleSupport} variant="link" color="blue.300" fontWeight="unset">
                contact support
              </Button>
              .
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" justify="flex-end" w="full" spacing={4}>
            <Button as={Link} to="/requests" tabIndex={-1} leftIcon={<HiChevronLeft />}>
              Requests
            </Button>
            <Button as={Link} to={`/requests/${request?.main_request_id}`} colorScheme="primary" tabIndex={0}>
              View Request
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
