export const convertFileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const result = fileReader.result as string;
      resolve(result.substring(result.indexOf(',') + 1));
    };
    fileReader.onerror = () => {
      reject(new Error('Failed to read the file.'));
    };
  });
};
