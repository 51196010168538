import { BusinessType } from '@pelicargo/types';
import { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useAuth } from './hooks/auth/useAuth';
import { useTargetAccount } from './hooks/useTargetAccount';
import { AirlineDashboard } from './screens/AirlineDashboard';
import { BookQuote } from './screens/BookQuote';
import { ForgotPassword } from './screens/ForgotPassword';
import { Home } from './screens/Home';
import { Login } from './screens/Login';
import { Metrics } from './screens/Metrics';
import { NotFound } from './screens/NotFound';
import { Pay } from './screens/Pay';
import { PaySuccess } from './screens/PaySuccess';
import { PendingVerification } from './screens/PendingVerification';
import { Privacy } from './screens/PrivacyPolicy';
import { Profile } from './screens/Profile';
import { QuoteConfirmationDetails } from './screens/QuoteConfirmation';
import { QuoteListing } from './screens/QuoteListing';
import { RegisterCompany } from './screens/Register/Company';
import { RegisterSelectCompany } from './screens/Register/CompanySelect';
import { RegisterConfirm } from './screens/Register/Confirm';
import { RegisterInvite } from './screens/Register/Invite';
import { RegisterPersonal } from './screens/Register/Personal';
import { RegisterSignature } from './screens/Register/Signature';
import { RegisterSignup } from './screens/Register/Signup';
import { RequestQuote } from './screens/RequestQuote';
import { Requests } from './screens/Requests';
import { ResetPassword } from './screens/ResetPassword';
import { Terms } from './screens/Terms';
import { Unsubscribe } from './screens/Unsubscribe';
import { UpdateCompany } from './screens/UpdateCompany';

interface Props {
  children?: ReactElement;
}

const AuthGuard = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const AdminGuard = ({ children }: Props) => {
  const { isLoggedIn, isSuperAdmin } = useAuth();

  if (!isLoggedIn || !isSuperAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const HomeGuard = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();
  const { targetAccount } = useTargetAccount();
  const isAirline = targetAccount?.business_type === BusinessType.AIRLINE;

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (isAirline) {
    return <Navigate to="/metrics" replace />;
  }

  return children;
};

export const Router = () => {
  // Allows us to attribute a hash to an element and scroll to it via the URL
  useEffect(() => {
    // See if there's a # in the URL
    const hash = window.location.hash;
    if (!hash) return;

    // Scroll to the element
    const element = document.getElementById(hash.substring(1));
    if (!element) return;

    element.scrollIntoView();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeGuard>
            <Home />
          </HomeGuard>
        }
      />
      <Route path="/request-quote" element={<RequestQuote />} />
      <Route
        path="/requests"
        element={
          <AuthGuard>
            <Requests />
          </AuthGuard>
        }
      />
      <Route
        path="/requests/:requestId"
        element={
          <AuthGuard>
            <QuoteListing />
          </AuthGuard>
        }
      />
      <Route
        path="/requests/:requestId/quotes/:quoteId"
        element={
          <AuthGuard>
            <BookQuote />
          </AuthGuard>
        }
      />
      <Route
        path="/requests/:requestId/quotes/:quoteId/booked"
        element={
          <AuthGuard>
            <QuoteConfirmationDetails />
          </AuthGuard>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthGuard>
            <Profile />
          </AuthGuard>
        }
      />
      <Route
        path="/company"
        element={
          <AuthGuard>
            <UpdateCompany />
          </AuthGuard>
        }
      />
      <Route
        path="/metrics"
        element={
          <AuthGuard>
            <Metrics />
          </AuthGuard>
        }
      />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<RegisterSignup />} />
      <Route
        path="/register/confirm"
        element={
          <AuthGuard>
            <RegisterConfirm />
          </AuthGuard>
        }
      />

      <Route
        path="/register/personal"
        element={
          <AuthGuard>
            <RegisterPersonal />
          </AuthGuard>
        }
      />
      <Route
        path="/register/company"
        element={
          <AuthGuard>
            <RegisterCompany />
          </AuthGuard>
        }
      />
      <Route
        path="/register/company/select"
        element={
          <AuthGuard>
            <RegisterSelectCompany />
          </AuthGuard>
        }
      />
      <Route
        path="/register/invite"
        element={
          <AuthGuard>
            <RegisterInvite />
          </AuthGuard>
        }
      />
      <Route
        path="/register/signature"
        element={
          <AuthGuard>
            <RegisterSignature />
          </AuthGuard>
        }
      />
      <Route
        path="/verification-pending"
        element={
          <AuthGuard>
            <PendingVerification />
          </AuthGuard>
        }
      />
      <Route
        path="/intelligence/:airlineId"
        element={
          <AdminGuard>
            <AirlineDashboard />
          </AdminGuard>
        }
      />
      <Route
        path="/intelligence"
        element={
          <AdminGuard>
            <AirlineDashboard />
          </AdminGuard>
        }
      />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
      <Route path="/pay/:token" element={<Pay />} />
      <Route path="/pay/success" element={<PaySuccess />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
