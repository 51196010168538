import { Textarea, TextareaProps } from '@chakra-ui/textarea';
import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import { SimpleField, SimpleXProps } from './SimpleField';

type SimpleTextareaProps<FormSchema> = SimpleXProps<FormSchema> & TextareaProps;

export const SimpleTextarea = <FormSchema,>({
  name,
  label,
  helper,
  size,
  placeholder,
  variant,
  ...rest
}: SimpleTextareaProps<FormSchema>) => {
  const { register } = useFormContext();
  const { ref, ...inputProps } = register(name);
  const defaultValue = rest.defaultValue || '';

  const formProps = register(name);

  return (
    <SimpleField name={name} label={label} helper={helper} variant={variant} h={rest?.h || rest?.height}>
      <Textarea
        as={TextareaAutosize}
        {...inputProps}
        ref={ref}
        defaultValue={defaultValue}
        variant="unstyled"
        rows={8}
        size={size}
        placeholder={placeholder}
        border="none"
        fontSize="md"
        _placeholder={{ color: 'text.placeholder' }}
        {...formProps}
        {...rest}
      />
    </SimpleField>
  );
};
