import { useEffect, useState } from 'react';

import { trpc, TrpcOutputs } from '../../config/trpc';

type RequestOutput = TrpcOutputs['getRequest'];
type UseRequest = [RequestOutput, boolean, string?];

export const useRequest = (id?: number, initialRequest?: RequestOutput): UseRequest => {
  const [request, setRequest] = useState<RequestOutput>();

  const {
    data: initialData,
    isLoading,
    error,
  } = trpc.getRequest.useQuery({ id }, { enabled: !!id, refetchOnWindowFocus: false, retry: false });

  useEffect(() => {
    if (initialRequest) setRequest(initialRequest);
    if (initialData) setRequest(initialData);
  }, [initialData, initialRequest]);

  trpc.onRequestUpdated.useSubscription({ id }, { enabled: !!id, onData: ({ request }) => setRequest(request) });

  return [request, isLoading, error?.message];
};
