import { Center, CenterProps, Text } from '@chakra-ui/react';

import { DayStyleConfig } from '../type';

type Props = CenterProps & {
  day?: number | string;
  dayStyleConfig: DayStyleConfig;
};

export const Disable = ({ day, dayStyleConfig, ...rest }: Props) => {
  const { size, fontSize } = dayStyleConfig;

  return (
    <Center m="auto" h={size} w={size} cursor="not-allowed" pos="relative" {...rest}>
      <Text fontSize={fontSize} color="gray.600" opacity="0.5">
        {day}
      </Text>
    </Center>
  );
};
