import {
  AircraftType,
  AircraftVariant,
  BatteryType,
  BatteryTypeVariant,
  DimensionUnit,
  HandlingOptions,
  Hint,
  MaxAircraftParams,
  PackingGroup,
  PackingInstruction,
  QuoteStatus,
  Section,
  ServiceType,
  WeightUnit,
} from '@pelicargo/types';

export type options = Record<string, string>;

export const METERS_TO_MILES = 0.000621371;

// We export here so we don't have to import full types pkg into the api
export type Units = {
  weightUnit: WeightUnit;
  dimensionUnit: DimensionUnit;
};

// All max params are in cm and kg
export const MAX_WIDE_BODY_PARAMS: MaxAircraftParams = {
  length: 396.24,
  width: 243.84,
  height: 162.56,
  weight_per_piece: 10000,
};
export const MAX_FREIGHTER_PARAMS: MaxAircraftParams = {
  length: 7000,
  width: 381,
  height: 304,
  weight_per_piece: 250000,
};
export const MAX_NARROW_BODY_PARAMS: MaxAircraftParams = {
  length: 121.92,
  width: 101.6,
  height: 78.74,
  weight_per_piece: 150,
};
export const MAX_RFS_PARAMS: MaxAircraftParams = {
  length: Number.MAX_SAFE_INTEGER,
  width: Number.MAX_SAFE_INTEGER,
  height: Number.MAX_SAFE_INTEGER,
  weight_per_piece: Number.MAX_SAFE_INTEGER,
};

export const MAX_PARAMS = {
  [AircraftType.Freighter]: MAX_FREIGHTER_PARAMS,
  [AircraftType.WideBody]: MAX_WIDE_BODY_PARAMS,
  [AircraftType.NarrowBody]: MAX_NARROW_BODY_PARAMS,
  [AircraftType.RFS]: MAX_RFS_PARAMS,
};

/**
 * Hints are used to provide a quick overview of the page or feature.
 *
 * Names are determined by the page and the feature and it's variant, for example:
 * [page]-[feature]-[variant]
 * quoteListing-requestSummary-unverified
 *
 * Or if there's no variant:
 * home-requestForm
 */
export const HINTS: Hint[] = [
  {
    name: 'home-requestForm',
    displayed_at: null,
    content: {
      title: 'Welcome to Pelicargo!',
      description:
        "This is where you will begin to request a quote for your shipment. We ask that you enter in as many details as possible including type of cargo, dimensions and weight. Once completed, click on 'Search Deals' to continue!",
    },
  },
  {
    name: 'home-dangerousGoodsForm',
    displayed_at: null,
    content: {
      title: 'Dangerous Goods',
      description:
        'If you have your DGD document, please upload it here, otherwise click on “Upload MSDS/Manual Input” and you can enter in the information you have now.\n\nYou will have the opportunity to upload the DGD document prior to confirming your booking!',
    },
  },
  {
    name: 'requestQuote-requestSummary',
    displayed_at: null,
    content: {
      title: 'Request Summary',
      description:
        'Here you can see a summary of your request details. There are also optional additional fields for any details you wish to provide. Target rates are helpful on confirmed shipments. Select "Request Quotes" to continue.',
    },
  },
  {
    name: 'quoteListing-requestSummary-unverified',
    displayed_at: null,
    content: {
      title: 'Quotes',
      description:
        'Depending on the shipment details and once your account is verified, quotes may be immediately available to be booked or need time for carrier validation. However our Pelicargo service team will always source the best quote for you!',
    },
  },
  {
    name: 'quoteListing-requestSummary-verified',
    displayed_at: null,
    content: {
      title: 'Quotes',
      description:
        'Depending on the information entered, some quotes are immediately available to be booked, however our Pelicargo engine will continue to source the best quote for you!',
    },
  },
  {
    name: 'quoteListing-quoteVariants',
    displayed_at: null,
    content: {
      title: 'Service Levels',
      description:
        'Here you can see the available Service Levels for your request as well as the breakdown for fees and surcharges.',
    },
  },
  {
    name: 'quoteConfirmation-costSummary',
    displayed_at: null,
    content: {
      title: 'Booking Complete!',
      description:
        "With your quote variant selected you can see a summary of the transit including length, transport type and drop-off details. From here, our Pelicargo team will followup via email to confirm the booking.\n\nIf you wish to start a new request, please click the logo on the top left of the screen to return to the start page - don't forget to bookmark that page!",
    },
  },
  {
    name: 'quoteListing-filters',
    displayed_at: null,
    content: {
      title: 'New Filters and Sorting!',
      description: 'You can now filter Quotes by Airlines, Airports, number of stops, service type and more.',
    },
  },
  {
    name: 'quoteListing-quote-cta',
    displayed_at: null,
    content: {
      title: '',
      description:
        'This rate does not include screening and fees. Click anywhere on the white bar to see a full breakdown or on the orange button to book.',
    },
  },
];

export const NARROW_BODY_THRESHOLDS = {
  length: 35,
  width: 35,
  height: 30,
  weight: 150,
};

export const WIDE_BODY_THRESHOLDS = {
  length: 125,
  width: 96,
  height: 64,
  weight: Infinity, // there is no weight restriction for wide-bodies rn
};

// Determines what a gateway is in the request form + the order for it to be shown.
// People hate change, hence this order
export const GATEWAYS = [
  'ATL',
  'MIA',
  'SEA',
  'CVG',
  'DFW',
  'IAH',
  'ORD',
  'SFO',
  'LAX',
  'JFK',
  'IAD',

  'DXB',
  'TPE',
  'FRA',
  'PVG',
  'CGK',
  'HKG',
  'ICN',
  'LHR',
];

export const OPENAI_REQUEST_INGESTION_PROMPT = `Extract all shipment request information. Respond with a JSON object with snake case keys! Find the following information: 
- origin_airports (must be array of US airport's three-letter code)
- destination_airports (must be array of non-US airport's three-letter code)
- cargo_origin (line one, line two, city, state, zip code, country)
- dropoff_by (output this in ISO-Date. default null)
- cargo_type (options are GeneralCargo, Perishable, DangerousGoods, Pharma, Valuable or Batteries. default GeneralCargo)
- is_known_shipper (boolean. default true)
- commodity (options are Machinery, Spare Parts, Electrical Equipment, Nutritional Supplements, Aircraft Parts, Textiles & Fashion, Consumer Commodities, General Cargo, Consolidated Cargo, Foliage, Fresh Flowers, Plants, Seeds, Foodstuff (processed), Milk Powder, Other Foodstuffs, Other Beverages (non-alcohol), Other Drinks, Alcoholic Beverage, Other Perishables, Meat, Seafood, Fish, Crabs (Live), Lobsters (Live), Fruits, Vitamins, Other Fruits, Vegetables, Aircraft Engine, Ship Spares, Car Parts, Equipment, Vehicle / Car, Vaccines, Medical Supplies, Pharmaceuticals, Other, Chemicals, Household Goods & Personal Effects, Other Papers, Documents, Precious Gems, Precious Metals, Other Valuables, Arts, Bank Notes, Machine Parts, Metal (Non-Scrap), Military Equipment (non-munitions), Office Supplies, Cosmetics, Oilfield Equipment, IT Equipment, Cherries, Grapes, Lithium Metal Batteries, Lithium Ion Batteries, Equipment with Lithium Metal Batteries, Equipment with Lithium Ion Batteries, Vehicle / Motorcycle)
- cargo_details (options are "Weight Per Piece" or "Gross Weight". Default "Weight Per Piece")
- gross_weight (if cargo details is "Gross Weight" default to gross weight, else default null)
- original_dimension_unit (${Object.values(DimensionUnit).join(', ')}. default ${DimensionUnit.in})
- original_weight_unit (${Object.values(WeightUnit).join(', ')}. default ${WeightUnit.kg})
- aircraft_types (${Object.values(AircraftType).join(', ')}. default to [${Object.values(AircraftType).join(', ')}])
- cargo (array of objects. quantity (default 1), length, width, height, weight (if cargo details is "Gross Weight" default null, else default to previous weight), handling (array of enums. options are ${Object.values(HandlingOptions).join(', ')}. default [${HandlingOptions.Stackable}]))
- service_type (${Object.values(ServiceType).join(', ')}. default [${ServiceType.General}])
- un_number (number. default null. Example: "UN3481" is 3481)
- class (string. default null)
- packing_group (${Object.values(PackingGroup).join(', ')}. default null)
- aircraft_variant (${Object.values(AircraftVariant).join(', ')}. default null)
- packing_instruction (${Object.values(PackingInstruction).join(', ')}. default null)
- section (${Object.values(Section).join(', ')}. default null)
- battery_type (${Object.values(BatteryType).join(', ')}. default null)
- battery_type_variant (${Object.values(BatteryTypeVariant).join(', ')}. default null)
- dgdTab ("DGD" or "MSDS". default null)
Examples
cargo details: 
1. "2 Skids @ 1359kgs
1 @ 53x27x31
1 @ 49x25x29"
Is "Gross Weight"
2. "2 Skids
1 @ 53x27x31 700kgs
1 @ 49x25x29 700kgs"
Is "Weight Per Piece"
3. "Pieces nr  , 10 pl
Total gross weight,3.215   kg
Dimensions,102*122*152 cms"
Is "Gross Weight"
4. "Total Cargo:  8 Pallets  ( 269ctn ) /  G.W 3824 kgs  / 17.15 cbm   
PALLETS : 100×120×186cm - 7 Pallets
                   100×120×127cm  - 1 Pallet"
Is "Gross Weight"
Only return the data, do not repeat the question, do not comment, and take your time!`;

export const QuotesStatusesToBeValidated: QuoteStatus[] = [QuoteStatus.ReadyForQC, QuoteStatus.InProgress];
