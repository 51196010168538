import { Icon, useColorModeValue } from '@chakra-ui/react';

export const IconTakeOff = () => {
  return (
    <Icon w="24px" h="24px" fill="none">
      <path
        fill={useColorModeValue('black', 'white')}
        d="M2.77 14.452s4.409 1.578 5.554 1.27l13.211-3.539c1.214-.325 1.883-1.575 1.712-2.213-.169-.638-1.339-1.397-2.587-1.064-.087.022-2.46.656-5.472 1.46L6.085 4.543a.374.374 0 0 0-.411.041l-.88.864a.287.287 0 0 0-.04.376l4.608 6.098-3.695.986-3.893-2.416a.324.324 0 0 0-.381.041l-.578.565a.32.32 0 0 0-.046.378s-.2-.169 2 2.976ZM0 22.134v1.303h24v-1.303H0Z"
      />
    </Icon>
  );
};
