import { Icon, IconProps, useColorModeValue } from '@chakra-ui/react';

export const IconNonStackable = ({ ...rest }: IconProps) => {
  return (
    <Icon w="24px" h="24px" fill="none" {...rest}>
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        d="M16.922 4.048 12.356 1.59a.75.75 0 0 0-.712 0L7.223 3.97 8.33 5.078 12 3.102l3.815 2.054 1.107-1.107ZM19.446 7.889l.722-.389-.217-.117 1.108-1.107 1.047.564a.75.75 0 0 1 0 1.32l-2.953 1.59 2.953 1.59a.75.75 0 0 1 0 1.32l-2.953 1.59 2.953 1.59a.75.75 0 0 1 0 1.32l-1.1.593-1.108-1.108.27-.145-.9-.484-2.295-2.296L20.168 12l-2.597-1.398-1.817.978 3.692-3.691ZM4.194 7.305l-.362.195 1.206.65 3.691 3.69-2.3-1.238L3.832 12l3.34 1.798-1.957 1.957-1.383.745.415.224L3.14 17.83l-1.246-.67a.75.75 0 0 1 0-1.321l2.953-1.59-2.953-1.59a.75.75 0 0 1 0-1.32l2.953-1.59-2.953-1.59a.75.75 0 0 1 0-1.32l1.192-.642 1.108 1.107ZM7.276 20.058l4.368 2.352c.222.12.49.12.712 0l4.513-2.43-1.107-1.107L12 20.898l-3.616-1.947-1.108 1.107ZM10.201 17.133l1.443.777c.222.12.49.12.712 0l1.588-.855-1.108-1.107-.836.45-.691-.372-1.108 1.107Z"
      />
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        d="M21.622 3.591a.75.75 0 0 0-1.06-1.06l-8.45 8.45-8.45-8.45A.75.75 0 0 0 2.6 3.59l8.45 8.45-8.52 8.52a.75.75 0 1 0 1.06 1.061l8.52-8.52 8.521 8.52a.75.75 0 0 0 1.06-1.06l-8.52-8.521 8.45-8.45Z"
      />
    </Icon>
  );
};
