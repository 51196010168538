import { OnboardingStep } from '@pelicargo/types';

export const getRouteForOnboardingStep = (step: OnboardingStep) => {
  const routes = {
    [OnboardingStep.CONFIRM]: '/register/confirm',
    [OnboardingStep.PERSONAL]: '/register/personal',
    [OnboardingStep.COMPANY_SELECT]: '/register/company/select',
    [OnboardingStep.COMPANY]: '/register/company',
    [OnboardingStep.SIGNATURE]: '/register/signature',
    [OnboardingStep.INVITE]: '/register/invite',
    [OnboardingStep.COMPLETE]: '/',
  };

  return routes[step];
};
