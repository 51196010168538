import { IconButton, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiEye, HiEyeSlash } from 'react-icons/hi2';

import { SimpleField, SimpleXProps } from './SimpleField';

export type SimpleInputProps<FormSchema> = SimpleXProps<FormSchema> & InputProps;

export const SimplePasswordInput = ({
  name,
  label,
  placeholder,
  helper,
  variant,
  maxLength,
  pattern,
  ...rest
}: SimpleInputProps<any>) => {
  const { register, watch } = useFormContext();
  const formProps = register(name);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const value = watch(name);

  return (
    <SimpleField name={name} helper={helper} label={label} variant={variant} {...rest}>
      <InputGroup>
        <Input
          variant="unstyled"
          placeholder={placeholder}
          fontSize="md"
          borderRadius="0"
          {...formProps}
          value={value}
          maxLength={maxLength}
          pattern={pattern}
          type={isPasswordVisible ? 'text' : 'password'}
          _placeholder={{ color: 'text.placeholder' }}
        />
        <InputRightElement alignItems="flex-start">
          <IconButton
            aria-label="Toggle password visibility"
            size="sm"
            variant="ghost"
            icon={isPasswordVisible ? <HiEyeSlash /> : <HiEye />}
            onClick={toggleVisibility}
          />
        </InputRightElement>
      </InputGroup>
    </SimpleField>
  );
};
