import { get } from 'lodash';
import { FieldError, useFormContext } from 'react-hook-form';

export function useFieldValidationStates(name: string): {
  error: FieldError | undefined;
  isTouched: boolean;
  isInvalid: boolean;
} {
  const {
    formState: { errors, touchedFields },
  } = useFormContext();

  const error = get(errors, name) as FieldError;
  const isTouched = get(touchedFields, name);
  const isInvalid = !!error;

  return { error, isTouched, isInvalid };
}
