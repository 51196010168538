import { BusinessType } from '@pelicargo/types';

export const getHeaderLinksByBusinessType = (businessType: BusinessType) => {
  switch (businessType) {
    case BusinessType.FREIGHT_FORWARDER:
    case BusinessType.GSA:
      return [
        {
          title: 'New Request',
          href: '/',
        },
        {
          title: 'Request History',
          href: '/requests',
        },
        {
          title: 'Metrics',
          href: '/metrics',
        },
      ];
    case BusinessType.AIRLINE:
      return [
        {
          title: 'Dashboard',
          href: '/metrics',
        },
      ];
    default:
      return [];
  }
};
