import { Input, InputProps } from '@chakra-ui/react';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleField, SimpleXProps } from './SimpleField';

type SimpleInputProps<FormSchema> = SimpleXProps<FormSchema> &
  InputProps & {
    country?: CountryCode;
  };

export const SimplePhoneNumberInput = <FormSchema,>({
  name,
  label,
  placeholder,
  helper,
  country,
  ...rest
}: SimpleInputProps<FormSchema>) => {
  const { setValue, register } = useFormContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rawNumber = event.target.value;
    const formatter = new AsYouType(country);
    const formatted = formatter.input(rawNumber);
    // setValue expects some weird typings
    setValue(name, formatted as any);
  };

  return (
    <SimpleField name={name} label={label} helper={helper} {...rest}>
      <Input
        type="tel"
        inputMode="tel"
        variant="unstyled"
        placeholder={placeholder}
        {...register(name)}
        onChange={handleChange}
        _placeholder={{ color: 'text.placeholder' }}
      />
    </SimpleField>
  );
};
