import { Button, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { OnboardingStep } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { removeDash } from '@pelicargo/utils';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleCheckbox } from '../../components/SimpleCheckbox';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimpleSelect } from '../../components/SimpleSelect';
import { trpc } from '../../config/trpc';
import { countries } from '../../constants/countries';
import { useOnboarding } from '../../hooks/onboarding/useOnboarding';
import { useScreenView } from '../../hooks/useScreenView';
import { formatIataCode } from '../../utils/inputFormatters';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  company_name: string;
  country: string;
  iata_number: string;
  no_iata_number: boolean;
  has_iac: boolean;
};

const schema = z
  .object({
    company_name: z.string({ required_error: 'Company name is required' }).min(2, {
      message: 'Company name is required',
    }),
    country: z.string({ required_error: 'Country is required' }),
    iata_number: z.string().nullish(),
    no_iata_number: z.boolean().nullish(),
    has_iac: z.boolean().nullish(),
  })
  .superRefine(({ iata_number, no_iata_number }, ctx) => {
    if (!no_iata_number && !iata_number) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `IATA code is required.`,
        path: ['iata_number'],
      });
    }

    if (!no_iata_number && removeDash(iata_number).length < 11) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `IATA code must be 11 digits.`,
        path: ['iata_number'],
      });
    }
  });

export const RegisterCompany = () => {
  const { setOnboardingStep } = useOnboarding();
  useScreenView(MixpanelEvent.CompanyView);

  const { data } = trpc.getAccountInvites.useQuery();

  const methods = useForm<Partial<FormValues>>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      company_name: '',
      country: 'United States',
      iata_number: '',
      no_iata_number: false,
      has_iac: true,
    },
  });

  const { mutateAsync: createAccount, isLoading: isSubmitting } = trpc.createAccount.useMutation();

  const no_iata_number = methods.watch('no_iata_number');

  const handleBack = useCallback(async () => {
    trackEvent(MixpanelEvent.CompanyBackToInvites);
    await setOnboardingStep(OnboardingStep.COMPANY_SELECT);
  }, [setOnboardingStep]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      trackEvent(MixpanelEvent.CompanySubmit);
      await createAccount({
        company_name: values.company_name,
        country: values.country,
        iata_number: values.iata_number,
        has_iac: values.has_iac,
      });
    },
    [createAccount],
  );

  return (
    <Page title="Company" showHeader={false} showFooter={false}>
      <OnboardingLayout heading="Register company" step={1}>
        <SimpleForm {...methods} onSubmit={handleSubmit}>
          <Stack spacing="3">
            <SimpleInput name="company_name" label="Company name" placeholder="Company name" variant="outline" />
            <SimpleSelect
              name="country"
              label="Country/Region"
              placeholder="Country"
              variant="outline"
              options={countries.map((country) => ({
                label: country?.name,
                value: country?.name,
              }))}
            />
            <Show if={!no_iata_number}>
              <SimpleInput
                name="iata_number"
                label="IATA code"
                placeholder="IATA code"
                variant="outline"
                formatter={formatIataCode}
                maxLength={13}
              />
            </Show>
            <SimpleCheckbox name="no_iata_number" label="Sign up without an IATA code" variant="unstyled" />
            <Show if={no_iata_number}>
              <SimpleCheckbox name="has_iac" label="Company has an IAC" variant="unstyled" />
            </Show>

            <Button colorScheme="primary" size="lg" alignSelf="flex-end" type="submit" isLoading={isSubmitting}>
              Continue
            </Button>
          </Stack>
        </SimpleForm>
        <Show if={data?.length > 0}>
          <Stack align="center">
            <Text textStyle="body">Trying to join your company on Pelicargo?</Text>
            <Button variant="link" colorScheme="primary" onClick={handleBack}>
              Accept an invite
            </Button>
          </Stack>
        </Show>
      </OnboardingLayout>
    </Page>
  );
};
