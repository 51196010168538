import { blackListedEmails } from '@pelicargo/utils';

export const validateEmail = (email: string) => {
  const domain = email.split('@')?.[1];

  const regex =
    // eslint-disable-next-line
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const isBusinessEmail = !blackListedEmails.includes(domain);

  if (!isBusinessEmail) {
    return 'Please use a business email address';
  }

  if (!regex.test(email)) {
    return 'Please enter a valid email address';
  }

  return null;
};
