import * as R from 'ramda';

import { DayType } from '../type';
import { DayStyleConfig, defaultDayStyle } from '../type';
import { Active } from './Active';
import { Disable } from './Disable';
import { Normal } from './Normal';

type Props = {
  date: Date;
  day: number;
  dayType: DayType;
  onSetDate?: (date: Date) => void;
  dayStyleConfig?: Partial<DayStyleConfig>;
  disabled?: boolean;
};

export const Day = ({ date, day, dayType = DayType.NORMAL, onSetDate, dayStyleConfig }: Props) => {
  const mergedDayStyleConfig: DayStyleConfig = R.mergeRight(defaultDayStyle, dayStyleConfig);

  switch (dayType) {
    case DayType.NONE:
      return <Disable dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.ACTIVE:
      return <Active dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.DISABLE:
      return <Disable dayStyleConfig={mergedDayStyleConfig} day={day} />;
    case DayType.NORMAL:
      return <Normal onClick={() => onSetDate?.(date)} dayStyleConfig={mergedDayStyleConfig} day={day} />;

    default:
      return <Normal onClick={() => onSetDate?.(date)} dayStyleConfig={mergedDayStyleConfig} day={day} />;
  }
};
