import { QuoteStatus, VariantType } from '@pelicargo/types';
import { useCallback, useEffect, useState } from 'react';

import { trpc } from '../../config/trpc';
import { API } from '../../utils/apiTypes';

type QuoteWithTotalCost = API.QuoteWithTotalCost;
type QuoteStatusType = API.QuoteWithTotalCost['status'];

export const useQuotes = (requestId: number) => {
  const { data, error } = trpc.getRequestQuote.useQuery({ id: requestId }, { refetchOnWindowFocus: false });

  const [quotes, setQuotes] = useState<QuoteWithTotalCost[]>([]);

  useEffect(() => {
    const processedQuotes = processAndSortQuotes(data?.quotes || []);
    setQuotes(processedQuotes || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.quotes]);

  const processAndSortQuotes = useCallback((quotes: QuoteWithTotalCost[]) => {
    if (!quotes.length) return [];

    const priorityTypes: QuoteStatusType[] = [QuoteStatus.Confirmed, QuoteStatus.InProgress];
    const sortedQuotes = quotes
      .filter((quote) => priorityTypes.includes(quote.status))
      .map((quote) => {
        const generalVariantsCost = getQuoteVariantTotal(quote, VariantType.General);
        const expeditedVariantsCost = getQuoteVariantTotal(quote, VariantType.Expedited);
        const urgentVariantsCost = getQuoteVariantTotal(quote, VariantType.Urgent);

        if (generalVariantsCost > 0) quote.totalCost = generalVariantsCost;
        else if (expeditedVariantsCost > 0) {
          quote.totalCost = expeditedVariantsCost;
        } else if (urgentVariantsCost > 0) quote.totalCost = urgentVariantsCost;

        return quote;
      })
      .sort((a, b) => (a.totalCost || 0) - (b.totalCost || 0));

    if (sortedQuotes.length) sortedQuotes[0].is_cheapest = true;
    quotes.forEach((quote) => {
      if (quote.id !== sortedQuotes[0]?.id) quote.is_cheapest = false;
    });

    const expiredOrPendingQuotes = quotes.filter((quote) => !priorityTypes.includes(quote.status));
    return [...sortedQuotes, ...expiredOrPendingQuotes];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Simplified version of getQuoteVariantTotal
  const getQuoteVariantTotal = useCallback((quote: QuoteWithTotalCost, variantType: VariantType): number => {
    return (
      quote.quote_variants
        ?.filter((variant) => variant.type === variantType)
        ?.reduce((total, variant) => total + variant.line_items?.reduce((sum, item) => sum + item.cost, 0), 0) || 0
    );
  }, []);

  // Update quotes when a new quote is created or updated
  const updateQuotes = useCallback(
    (newQuote: QuoteWithTotalCost, { isNew = false, id }: { isNew?: boolean; id?: number }) => {
      setQuotes((currentQuotes) => {
        const updatedQuotes = isNew
          ? [...currentQuotes, newQuote]
          : id !== undefined
            ? currentQuotes.filter((quote) => quote.id !== id)
            : currentQuotes.map((quote) => (quote.id === newQuote.id ? newQuote : quote));
        return processAndSortQuotes(updatedQuotes);
      });
    },
    [processAndSortQuotes],
  );

  trpc.onQuoteCreated.useSubscription(
    { requestId },
    {
      onData: (newData: any) => {
        if (!requestId) return;

        updateQuotes(newData, { isNew: true });
      },
      onError: (error) => console.log('onQuoteCreated.error: ', error),
    },
  );

  trpc.onQuoteUpdated.useSubscription(
    { requestId },
    {
      onData: (newData: any) => {
        if (!requestId) return;
        updateQuotes(newData, {});
      },
      onError: (error) => console.log('onQuoteUpdated.error: ', error),
    },
  );

  trpc.onQuoteDeleted.useSubscription({ requestId } as any, {
    onData: (deletedQuoteId: number) => {
      updateQuotes(null, { id: deletedQuoteId });
    },
    onError: (err: any) => {
      console.log('onQuoteUpdated error: ', err);
    },
  });

  useEffect(() => {
    setQuotes((currentQuotes) => processAndSortQuotes(currentQuotes));
  }, [processAndSortQuotes]);

  return {
    quotes,
    error,
  };
};
