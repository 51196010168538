import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { Stack, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { forwardRef, ReactNode, Ref } from 'react';

import { useFieldValidationStates } from '../hooks/useFieldValidationStates';

export interface SimpleXProps<FormSchema> {
  name: keyof FormSchema;
  label?: string;
  helper?: string;
  errorMessage?: string;
  removePadding?: boolean;
  children?: ReactNode;
}

type SimpleFieldProps<FormSchema, InputType> = SimpleXProps<FormSchema> & InputType;

export const SimpleField = forwardRef<HTMLDivElement, any>(
  (
    {
      name,
      label,
      children,
      helper,
      errorMessage,
      variant,
      isRequired,
      removePadding = false,
      isReactSelect = false,
      ...rest
    }: SimpleFieldProps<any, any>,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const { error, isInvalid } = useFieldValidationStates(name as string);
    const styles = useMultiStyleConfig('SimpleField', {
      variant,
      isInvalid: isInvalid || !!errorMessage,
      removePadding,
    });

    return (
      <Stack spacing="1" h="full" {...rest}>
        <FormControl
          name={name}
          id={name as string}
          isInvalid={isInvalid}
          isRequired={isRequired}
          ref={ref}
          {...styles.form}
          _focusWithin={{
            ...(styles.form as any)._focus,
            shadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
            borderColor: 'blue.500',
          }}
          {...rest}
        >
          <Stack justify="space-between" h="full" spacing={1}>
            {label && (
              <FormLabel margin={0} variant="inputLabel" userSelect="none" p={isReactSelect ? 4 : 0} pb="1">
                {label}
              </FormLabel>
            )}
            {children}
          </Stack>

          {helper && <FormHelperText>{helper}</FormHelperText>}
        </FormControl>
        <Show if={!!errorMessage || !!error?.message}>
          <Text color="error" fontSize="xs" fontWeight="bold">
            {errorMessage ?? error?.message}
          </Text>
        </Show>
      </Stack>
    );
  },
);
