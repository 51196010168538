import { Icon, IconProps, useColorModeValue } from '@chakra-ui/react';

export const IconDrums = ({ ...rest }: IconProps) => {
  return (
    <Icon w="24px" h="24px" fill="none" {...rest}>
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        fillRule="evenodd"
        d="M12.015 5.08c0 .51-.756.923-1.688.923-.931 0-1.687-.413-1.687-.923s.756-.924 1.688-.924c.931 0 1.687.414 1.687.924Zm-.735.214c.161-.088.223-.18.232-.203.003-.007.003-.011.003-.011v-.002l-.003-.01c-.009-.023-.071-.115-.232-.203-.218-.12-.554-.209-.952-.209-.399 0-.735.09-.953.21-.161.087-.223.179-.232.203l-.003.01.002.01.001.002c.009.023.07.115.232.203.218.12.554.209.953.209.398 0 .734-.09.952-.21Z"
        clipRule="evenodd"
      />
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        fillRule="evenodd"
        d="M18.906 5.08a3.255 3.255 0 0 1-.003.178V8.57c.559.3.45 1.007 0 1.331v1.918c.548.294.46 1 0 1.332v2.307c.548.294.46 1 0 1.332v1.423c0 .752-.09 1.353-.495 1.885-.413.543-1.023.94-1.691 1.228-1.334.576-3.121.816-4.842.787-1.721-.028-3.486-.327-4.779-.917-.645-.295-1.234-.689-1.62-1.218-.518-.707-.464-1.596-.412-2.456.015-.257.03-.51.03-.756-.43-.315-.5-.989 0-1.29v-2.35c-.44-.322-.485-.997 0-1.29V9.878c-.43-.315-.5-.988 0-1.29a4272.842 4272.842 0 0 0 0-3.508C5.094 3.517 8.186 2.25 12 2.25c3.814 0 6.906 1.267 6.906 2.83ZM12 6.408c1.761 0 3.286-.295 4.315-.717.361-.148.752-.328 1.029-.613-.277-.284-.668-.464-1.03-.612-1.028-.422-2.553-.717-4.314-.717-1.761 0-3.286.295-4.315.717-.518.212-.838.427-1.005.589l-.006.006-.018.017.018.018.006.006c.167.162.487.377 1.005.59 1.029.42 2.554.716 4.315.716Zm3.927 3.107a9.075 9.075 0 0 0 1.476-.487V6.842c-1.265.65-3.215 1.067-5.403 1.067-2.189 0-4.14-.417-5.405-1.068v2.202c.598.308 1.328.543 2.151.705 1.149.225 2.432.296 3.692.244a17.832 17.832 0 0 0 3.49-.476Zm1.476 2.762v-1.637c-.35.124-.726.235-1.114.331-1.121.279-2.44.464-3.79.52-1.35.054-2.753-.02-4.041-.272-.647-.127-1.278-.301-1.863-.533v1.605c.598.308 1.328.544 2.151.705 1.149.225 2.432.296 3.692.245a17.834 17.834 0 0 0 3.49-.476 9.067 9.067 0 0 0 1.475-.488Zm-1.476 4.127a9.077 9.077 0 0 0 1.476-.487V13.89c-.35.124-.726.234-1.114.33-1.121.28-2.44.464-3.79.52-1.35.055-2.753-.02-4.041-.272-.647-.127-1.278-.3-1.863-.533v1.995c.598.309 1.328.544 2.151.705 1.149.225 2.432.296 3.692.245a17.835 17.835 0 0 0 3.49-.476Zm1.484 1.686c-.004-.189-.008-.372-.008-.561-.35.124-.726.234-1.114.33-1.121.28-2.44.465-3.79.52-1.35.055-2.753-.02-4.041-.272-.647-.127-1.278-.3-1.863-.533 0 .194-.011.407-.022.62-.012.215-.023.431-.023.63.14.502.739.81 1.169 1.006 1.04.475 2.58.756 4.181.783 1.601.026 3.154-.204 4.222-.666.52-.224 1.206-.6 1.297-1.225 0-.227-.004-.433-.008-.632Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
