import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { TimeOfDay } from '@pelicargo/types';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { trpc } from '../config/trpc';
import { SimpleDatePicker } from './SimpleDatePicker';
import { SimpleForm } from './SimpleForm';

type FormValues = {
  tender_date: Date;
  tender_date_time_of_day: TimeOfDay;
};

const schema = z.object({
  tender_date: z.coerce.date({ required_error: 'Tender date is required' }),
});

type Props = {
  requestId: number;
  refetch?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateTenderDateModal = ({ requestId, refetch, isOpen, onClose }: Props) => {
  const toast = useToast();
  const { mutateAsync: updateTenderDate, isLoading: isSubmitting } = trpc.updateTenderDate.useMutation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await updateTenderDate({
          requestId,
          tenderDate: values.tender_date,
          timeOfDay: values.tender_date_time_of_day,
        });

        refetch?.();
        toast({
          title: 'Success',
          description: 'Tender date has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } catch (error) {
        toast({
          title: 'Something went wrong',
          description: error?.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    },
    [onClose, refetch, requestId, toast, updateTenderDate],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Tender Date</ModalHeader>
        <ModalCloseButton />
        <SimpleForm onSubmit={handleSubmit} {...(methods as any)}>
          <ModalBody>
            <SimpleDatePicker
              name="tender_date"
              label="Tender Date *"
              variant="outline"
              matchWidth={false}
              timeOfDay
              timeOfDayName="tender_date_time_of_day"
              disablePreviousDates
              iconFontSize="20px"
            />
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" justifyContent="flex-end">
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="primary" type="submit" isLoading={isSubmitting}>
                Update Tender
              </Button>
            </Stack>
          </ModalFooter>
        </SimpleForm>
      </ModalContent>
    </Modal>
  );
};
