import { DimensionUnit } from '@pelicargo/types';
import { Units } from '@pelicargo/utils';

import { CargoItem } from '../hooks/request/useForm';

const isWithinTolerance = (value: number, target: number, tolerance: number): boolean => {
  return value >= target - tolerance && value <= target + tolerance;
};

type Warning = {
  message: string;
  check: (cargo: CargoItem[], units: Units, gw: number, vw: number) => boolean;
};

export const cargoWarnings: Warning[] = [
  {
    check: (cargo, units) =>
      units.dimensionUnit === DimensionUnit.cm &&
      cargo.some(
        (c) =>
          (isWithinTolerance(c.length, 48, 5) && isWithinTolerance(c.width, 40, 5)) ||
          (isWithinTolerance(c.length, 40, 5) && isWithinTolerance(c.width, 48, 5)) ||
          (isWithinTolerance(c.length, 40, 5) && isWithinTolerance(c.width, 40, 5)),
      ),
    message:
      'This is a common pallet size in inches but you entered in centimeters. Can you please confirm the units before searching?',
  },
  {
    check: (cargo, units) =>
      units.dimensionUnit === DimensionUnit.in &&
      cargo.some(
        (c) =>
          (isWithinTolerance(c.length, 120, 2) && isWithinTolerance(c.width, 100, 2)) ||
          (isWithinTolerance(c.length, 100, 2) && isWithinTolerance(c.width, 120, 2)) ||
          (isWithinTolerance(c.length, 100, 2) && isWithinTolerance(c.width, 100, 2)) ||
          isWithinTolerance(c.length, 305, 2),
      ),
    message:
      'This is a common pallet size in centimeters but you entered in inches. Can you please confirm the units before searching?',
  },
  {
    check: (cargo, units) =>
      units.dimensionUnit === DimensionUnit.in &&
      cargo.some(
        (c) =>
          isWithinTolerance(c.length, 100, 2) &&
          isWithinTolerance(c.width, 100, 2) &&
          isWithinTolerance(c.height, 80, 2),
      ),
    message:
      'This is a common pallet size in centimeters but you entered in inches. Can you please confirm the units before searching?',
  },
  {
    check: (_, __, gw, vw) => {
      const hasNonZeroWeights = gw > 0 && vw > 0;
      const limit = 6 * vw;
      return hasNonZeroWeights && gw > limit;
    },
    message: 'Wow, this is a heavy shipment! Can you please confirm the units, weights, and dims before searching?',
  },
  {
    check: (_, __, gw, vw) => {
      const hasNonZeroWeights = gw > 0 && vw > 0;
      const limit = vw / 6;
      return hasNonZeroWeights && gw <= limit;
    },
    message:
      'Wow, this shipment is quite volumetric! Can you please confirm the units, weights, and dims before searching?',
  },
];
