import { Button, Container, Flex, Heading, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useError, useSuccess } from '@pelicargo/ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { useTargetAccount } from '../../hooks/useTargetAccount';

type FormValues = {
  preferred_name: string;
  full_name: string;
  title: string;
  email: string;
};

const schema = z.object({
  preferred_name: z.string({ required_error: 'Preferred name is required' }).min(1, 'Preferred name is required'),
  full_name: z.string({ required_error: 'Full name is required' }).min(1, 'Full name is required'),
  title: z.string({ required_error: 'Title is required' }).min(1, 'Title is required'),
  email: z.string({ required_error: 'Email is required' }).email({ message: 'Invalid email address' }),
});

export const Profile = () => {
  const { user } = useAuth();
  const { targetAccount } = useTargetAccount();
  const { handleSuccess } = useSuccess();
  const { handleError } = useError();

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
  });
  const { mutateAsync: updateUser } = trpc.updateUser.useMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    methods.reset({
      preferred_name: user?.preferred_name,
      full_name: user?.full_name,
      title: user?.title,
      email: user?.email,
    });
  }, [methods, user?.email, user?.full_name, user?.preferred_name, user?.title]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setIsSubmitting(true);

        await updateUser(values);
        handleSuccess('Profile updated');
      } catch (error) {
        handleError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [handleError, handleSuccess, updateUser],
  );

  return (
    <Page title="Profile" flex="1">
      <Container maxW="container.md" px={{ base: '4', lg: '16' }} py="10">
        <Stack minH="full" w="full" spacing="6">
          <Heading>Profile</Heading>

          <SimpleForm {...methods} onSubmit={handleSubmit}>
            <Stack w="full" spacing="4">
              <Stack direction={{ base: 'column', sm: 'row' }} gap="4">
                <SimpleInput name="preferred_name" label="Preferred Name *" variant="outline" w="full" />
                <SimpleInput name="full_name" label="Full Name *" variant="outline" w="full" />
              </Stack>
              <SimpleInput name="title" label="Title *" variant="outline" />
              <SimpleInput name="email" label="Email *" variant="outline" />

              <Flex justify="flex-end" w={{ base: 'full', md: 'auto' }} minW={{ base: 'full', md: 'xs' }}>
                <Stack spacing="4" w={{ base: 'full', md: 'auto' }}>
                  <Button
                    size="sm"
                    variant="link"
                    colorScheme="orange"
                    as={Link}
                    to="/reset-password"
                    rightIcon={<HiExternalLink />}
                  >
                    Change my password
                  </Button>
                  <Button
                    colorScheme="primary"
                    size="lg"
                    type="submit"
                    isDisabled={isSubmitting || !targetAccount?.id}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </Stack>
              </Flex>
            </Stack>
          </SimpleForm>
        </Stack>
      </Container>
    </Page>
  );
};
