import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { FormType } from '@pelicargo/types';

import { ContactForm, ContactFormValues } from './Forms/ContactForm';

type Props = UseDisclosureProps & {
  defaultValues: Partial<ContactFormValues>;
  type: FormType;
  onSubmit: (values: ContactFormValues) => Promise<void>;
};
export const ContactModal = ({ defaultValues, type, onSubmit, ...props }: Props) => {
  const disclosure = useDisclosure(props);
  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type} a contact</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContactForm onSubmit={onSubmit} type={type} defaultValues={defaultValues} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
