import { Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import { TimeOfDay } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { timeOfDayOptions } from '@pelicargo/utils';
import { addDays, isSameDay, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DayType } from './DatePicker/Calendar/type';
import { DateTimePicker } from './DatePicker/DateTimePicker';
import { SimpleXProps } from './SimpleField';

export type Props<FormSchema> = SimpleXProps<FormSchema> & {
  name: string;
  variant?: string;
  matchWidth?: boolean;
  timeOfDay?: boolean;
  timeOfDayName?: string;
  iconFontSize?: string;
  onChange?: (value: string) => void;
};

export const SimpleDatePicker = <FormSchema,>({
  name,
  timeOfDay = false,
  timeOfDayName = '',
  onChange,
  ...rest
}: Props<FormSchema>) => {
  const { setValue: setFormValue } = useFormContext();
  const formValue = useWatch({ name });
  const timeOfDayValue = useWatch({ name: timeOfDayName });

  const handleTimeChange = (nextTime: TimeOfDay) => {
    setFormValue(timeOfDayName, nextTime);
  };

  const handleSetDate = useCallback(
    (nextDate: Date) => {
      const utcDate = new Date(Date.UTC(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate()));
      setFormValue(name, utcDate.toISOString());
      onChange?.(utcDate.toISOString());
    },
    [setFormValue, name, onChange],
  );

  const onDayTypeRulesFn = useCallback(
    (date: Date) => {
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const todayUtc = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));

      if (utcDate < addDays(todayUtc, -1)) return DayType.DISABLE;

      const formValueDate = formValue ? parseISO(formValue) : null;
      const hasValueAndIsSame = formValueDate && isSameDay(utcDate, formValueDate);
      if (hasValueAndIsSame) return DayType.ACTIVE;

      return DayType.NORMAL;
    },
    [formValue],
  );

  return (
    <Stack w="full" flexDir="column" {...rest}>
      <Show if={timeOfDay}>
        <Text textStyle="body">Tender date</Text>
      </Show>
      <DateTimePicker
        selectedDate={formValue ? parseISO(formValue) : new Date()}
        onSetDate={handleSetDate}
        onDayTypeRulesFn={onDayTypeRulesFn}
      />
      <Show if={timeOfDay}>
        <Stack spacing="2">
          <Text textStyle="body">Time</Text>
          <ButtonGroup w="full">
            {timeOfDayOptions.map((option) => (
              <Button
                key={option.value}
                value={option.value}
                size="xs"
                w="full"
                bg={option.value === timeOfDayValue ? 'gray.800' : 'gray.100'}
                color={option.value === timeOfDayValue ? 'white' : 'gray.800'}
                _hover={{
                  bg: option.value === timeOfDayValue ? 'gray.800' : 'gray.200',
                }}
                onClick={() => handleTimeChange(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
      </Show>
    </Stack>
  );
};
