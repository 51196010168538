import { Heading, Stack } from '@chakra-ui/react';

import { CargoPlane } from '../../components/CargoPlane';
import { Page } from '../../components/Page';

export const NotFound = () => {
  return (
    <Page title="404 Not Found" flex="1" position="relative">
      <Stack align="center" justify="center">
        <Heading variant="h2" mt="30vh" textAlign="center">
          The page you are accessing doesn't exist.
        </Heading>
        <CargoPlane maxW="800px" />
      </Stack>
    </Page>
  );
};
