import { Icon, IconProps, useColorModeValue } from '@chakra-ui/react';

export const IconPails = ({ ...rest }: IconProps) => {
  return (
    <Icon w="24px" h="24px" fill="none" {...rest}>
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        fillRule="evenodd"
        d="M20.481 3.895a2 2 0 0 0-1.94-1.515H5.565a2 2 0 0 0-1.94 1.515h-.81c-.692 0-1.292.577-1.227 1.323.098 1.13.473 3.913 1.91 6.436.274.48.587.952.943 1.4v5.942a2.5 2.5 0 0 0 2.5 2.5h10.223a2.5 2.5 0 0 0 2.5-2.5v-6.148c.293-.38.557-.773.793-1.173 1.489-2.516 1.924-5.295 2.047-6.432.082-.757-.525-1.348-1.225-1.348h-.799Zm-1.82 0a.5.5 0 0 1 .38.485v.53a.5.5 0 0 1-.5.5H5.565a.501.501 0 0 1-.5-.5v-.53a.5.5 0 0 1 .5-.5H18.54c.042 0 .083.005.122.015ZM4.443 10.223c-.89-1.854-1.214-3.777-1.329-4.828h.513c.12.483.416.897.816 1.17v3.658Zm15.223-3.659c.4-.272.696-.686.816-1.17h.495c-.132 1.01-.471 2.807-1.311 4.58v-3.41ZM6.942 19.996a1 1 0 0 1-1-1v-4.43a8.705 8.705 0 0 0 3.042 1.565c.208.601.78 1.033 1.451 1.033h3.236c.7 0 1.29-.468 1.475-1.107a8.89 8.89 0 0 0 3.019-1.647v4.586a1 1 0 0 1-1 1H6.942Zm11.223-7.68c-.846.978-1.94 1.8-3.362 2.274a1.532 1.532 0 0 0-1.132-.497h-3.236c-.474 0-.898.215-1.18.553a7.275 7.275 0 0 1-3.313-2.136V6.97h12.223v5.345Zm-4.494 2.777a.535.535 0 1 1 0 1.07h-3.236a.535.535 0 1 1 0-1.07h3.236Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
