import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const variants = {
  primary: {
    container: {
      bg: 'black',
      py: '2',
      px: '3',
      color: 'white',
      w: 'max-content',
      fontWeight: 'extrabold',
      fontSize: 'xs',
    },
  },
};

export default defineMultiStyleConfig({
  variants,
});
