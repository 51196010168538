import { Button, Center, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import { OnboardingStep } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CompanyCard } from '../../components/CompanyCard';
import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';
import { useOnboarding } from '../../hooks/onboarding/useOnboarding';
import { useScreenView } from '../../hooks/useScreenView';
import { API } from '../../utils/apiTypes';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

export const RegisterSelectCompany = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setOnboardingStep } = useOnboarding();
  useScreenView(MixpanelEvent.CompanySelectView);

  const { data, isLoading } = trpc.getPotentialAccounts.useQuery();
  const { mutateAsync: acceptAccountInvite, isLoading: isAcceptingInvite } = trpc.acceptAccountInvite.useMutation();
  const { mutateAsync: selectAccount, isLoading: isSelectingAccount } = trpc.selectAccount.useMutation();

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);
  const [selectedAccountInviteId, setSelectedAccountInviteId] = useState<number | null>(null);

  const handleSelectAccountInvite = useCallback(
    (accountInvite: API.AccountInvite) => async () => {
      try {
        trackEvent(MixpanelEvent.CompanySelectSubmit);

        setSelectedAccountInviteId(accountInvite?.id);

        if (isAcceptingInvite) return;

        await acceptAccountInvite({ id: accountInvite?.id });

        toast({
          title: 'Company selected!',
          description: 'You have successfully selected your company.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        navigate('/');
      } catch (error) {
        toast({
          title: 'Invite could not be accepted',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setSelectedAccountInviteId(null);
      }
    },
    [acceptAccountInvite, isAcceptingInvite, navigate, toast],
  );

  const handleSelectAccount = useCallback(
    (account: API.AccountInvite['account']) => async () => {
      try {
        trackEvent(MixpanelEvent.CompanySelectSubmit);

        setSelectedAccountId(account?.id);

        if (isSelectingAccount) return;

        await selectAccount({ id: account?.id });

        toast({
          title: 'Company selected!',
          description: 'You have successfully selected your company.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        navigate('/');
      } catch (error) {
        toast({
          title: 'Account could not be selected',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setSelectedAccountId(null);
      }
    },
    [isSelectingAccount, navigate, selectAccount, toast],
  );

  const handleNewCompany = async () => {
    trackEvent(MixpanelEvent.CompanySelectNewCompany);
    await setOnboardingStep(OnboardingStep.COMPANY);
    navigate('/');
  };

  const renderAccountInvite = (accountInvite: API.AccountInvite) => {
    const account = accountInvite.account;

    return (
      <CompanyCard
        key={account.id}
        account={account}
        onClick={handleSelectAccountInvite(accountInvite)}
        isLoading={isAcceptingInvite && selectedAccountInviteId === account.id}
      />
    );
  };

  const renderAccount = (account: API.AccountInvite['account']) => {
    return (
      <CompanyCard
        key={account.id}
        account={account}
        onClick={handleSelectAccount(account)}
        isLoading={isSelectingAccount && selectedAccountId === account.id}
      />
    );
  };

  const accountInvites = data?.accountInvites || [];
  const relatedAccounts = data?.relatedAccounts || [];

  return (
    <Page title="Select Company" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Welcome to Pelicargo!"
        subHeading="Please confirm the primary company you're associated with."
      >
        <Show if={isLoading}>
          <Center>
            <Spinner />
          </Center>
        </Show>
        <Show if={accountInvites?.length > 0}>
          <Stack spacing="5">{accountInvites?.map(renderAccountInvite)}</Stack>
        </Show>
        <Show if={relatedAccounts?.length > 0}>
          <Stack spacing="5">{relatedAccounts?.map(renderAccount)}</Stack>
        </Show>
        <Stack align="center">
          <Text textStyle="body">Don't see your company?</Text>
          <Button variant="link" colorScheme="primary" onClick={handleNewCompany}>
            Register new company
          </Button>
        </Stack>
      </OnboardingLayout>
    </Page>
  );
};
