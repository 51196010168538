import { Box, Flex, Icon, Input, SelectProps, Stack, Text, useOutsideClick } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiChevronDown } from 'react-icons/hi';

import { SimpleField, SimpleXProps } from './SimpleField';
import { useSimpleFormContext } from './SimpleForm';

type SimpleSelectProps<FormSchema> = SimpleXProps<FormSchema> &
  SelectProps & {
    options: { label: string; value: any }[];
    tooltip?: string | JSX.Element;
    onChangeCallback?: (value: any) => void;
  };

export const SimpleSelect = <FormSchema,>({
  options = [],
  name,
  label,
  helper,
  placeholder = '-',
  variant,
  onChangeCallback,
  width = 'full',
  ...rest
}: SimpleSelectProps<FormSchema>) => {
  const focusRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const { simpleOnBlur } = useSimpleFormContext();
  const { control, setValue } = useFormContext();
  const value = useWatch({ name, control });

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const onBlur = useCallback(
    (event: any) => {
      const option = options?.find((node) => node?.value === value);

      if (option?.value) {
        setValue(name, option?.value);
        onChangeCallback?.(option?.value);
      }

      setIsFocused(false);
      setFilteredOptions(options);

      simpleOnBlur?.(event);
    },
    [options, setValue, name, onChangeCallback, simpleOnBlur, value],
  );

  const onFocus = () => {
    setIsFocused(true);
  };

  useOutsideClick({
    ref: focusRef,
    handler: () => setIsFocused(false),
  });

  const onChange = (event: any) => {
    const searchTerm = event.target.value;
    setValue(name, searchTerm);
    const query = searchTerm.toLowerCase();

    if (!query) setFilteredOptions(options);

    const updatedOptions = options.filter(({ label }) => label?.toLowerCase().includes(query));

    setFilteredOptions(updatedOptions);
  };

  const handleItemSelection = (nextValue: string) => () => {
    const option = options?.find((node) => node?.value === nextValue);
    setValue(name, option?.value);
    setIsFocused(false);
    onChangeCallback?.(option?.value);
  };

  const formattedValue = useMemo(() => {
    const option = options?.find((node) => node?.value === value);

    return option?.label ?? value;
  }, [options, value]);

  const renderListItem = ({ label, value }) => (
    <Box
      px={4}
      py={2}
      borderBottom="border.light"
      key={value}
      onMouseDown={handleItemSelection(value)}
      cursor="pointer"
      _hover={{ bg: 'bg.subtle' }}
    >
      <Text fontSize="md">{label}</Text>
    </Box>
  );

  return (
    <>
      <Flex ref={focusRef} position="relative" w={width}>
        <SimpleField
          name={name}
          label={label}
          helper={helper}
          w="full"
          {...rest}
          variant={variant}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onClick={onFocus}
        >
          <Stack direction="row" align="center" justify="space-between" borderRadius="md">
            <Input
              variant="unstyled"
              placeholder={placeholder}
              value={formattedValue}
              autoComplete="none"
              fontSize="md"
              _placeholder={{ color: 'text.placeholder' }}
            />
            <Icon as={HiChevronDown} />
          </Stack>
        </SimpleField>
        <Show if={isFocused}>
          <Stack
            spacing={0}
            bg="bg.surface"
            maxH="md"
            overflowY="auto"
            position="absolute"
            zIndex={1}
            top={73}
            w="full"
            shadow="2xl"
            borderRadius="md"
          >
            {filteredOptions?.map(renderListItem)}
          </Stack>
        </Show>
      </Flex>
    </>
  );
};
