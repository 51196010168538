import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Icon,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  useOutsideClick,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { join } from '@pelicargo/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InstantSearch } from 'react-instantsearch-core';
import usePlacesAutocomplete, { GeocodeResult, getGeocode, getLatLng, Suggestion } from 'use-places-autocomplete';

import { useTypesense } from '../hooks/typesense/useTypesense';
import { IconLanding } from './Icons/IconLanding';
import { IconTakeOff } from './Icons/IconTakeOff';
import { RoutePickerHits } from './RoutePickerHits';
import { RoutePickerSearchBox } from './RoutePickerSearchBox';
import { SimpleField } from './SimpleField';

enum View {
  Origin = 'origin',
  Destination = 'destination',
}

enum SearchType {
  Airport,
  Address,
}

type Address = {
  name: string;
  latitude: number;
  longitude: number;
  country: string;
  place_id: string;
};

export const SimpleRoutePicker = () => {
  const initialFocusRef = useRef<HTMLInputElement>();
  const contentRef = useRef<HTMLDivElement>();
  const disclosure = useDisclosure();

  const {
    suggestions,
    value: locationValue,
    setValue: setLocationValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const { watch, setValue, formState } = useFormContext();

  const [selectedAddress, setSelectedAddress] = useState<Address>();

  const [query, setQuery] = useState<string>('');
  const [view, setView] = useState<View | null>(null);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.Airport);

  const isOrigin = useMemo(() => view === View.Origin, [view]);
  const isDestination = useMemo(() => view === View.Destination, [view]);

  const handleClose = useCallback(() => {
    disclosure.onClose();
  }, [disclosure]);

  const handleOpen = useCallback(
    (nextView: View) => () => {
      if (nextView !== view) {
        setQuery('');
        setSelectedAddress(null);
        setLocationValue('');
        setSearchType(SearchType.Airport);
      }
      setView(nextView);
      disclosure.onOpen();
    },
    [disclosure, setLocationValue, view],
  );

  useOutsideClick({ ref: contentRef, handler: handleClose });

  const getSortBy = useMemo(() => {
    if (selectedAddress) {
      return `airport_main_gateway:desc,airport_latlng(${selectedAddress?.latitude}, ${selectedAddress?.longitude}):asc`;
    }
    const sortBy =
      'airport_main_gateway:desc,' +
      (isDestination ? 'airport_destination_popularity:desc' : 'airport_origin_popularity:desc');
    return sortBy;
  }, [isDestination, selectedAddress]);

  const filters = useMemo(() => {
    const optionalFilters = [];

    if (selectedAddress) {
      optionalFilters.push(`airport_latlng:(${selectedAddress?.latitude}, ${selectedAddress?.longitude}, 2000 mi)`);
    }

    if (isOrigin) {
      optionalFilters.push(`airport_country:=US`);
    }

    if (isDestination) {
      optionalFilters.push(`airport_country:!=US`);
    }

    return optionalFilters.filter(Boolean).join(' && ');
  }, [selectedAddress, isOrigin, isDestination]);

  const typesense = useTypesense({
    additionalSearchParameters: {
      query_by: 'airport_id, airport_name',
      sort_by: getSortBy,
      per_page: 20,
      filter_by: filters,
    },
  } as any);

  const fieldName = useMemo(() => {
    return isOrigin ? 'origin_airports' : 'destination_airports';
  }, [isOrigin]);

  const originAirports = watch('origin_airports');
  const destinationAirports = watch('destination_airports');

  const originValue = useMemo(() => {
    return originAirports?.length > 0 ? join(originAirports) : 'Origin';
  }, [originAirports]);

  const destinationValue = useMemo(() => {
    return destinationAirports?.length > 0 ? join(destinationAirports) : 'Destination';
  }, [destinationAirports]);

  useEffect(() => {
    if (originValue === 'Origin') {
      setValue('cargo_origin', {});
    }
  }, [originValue, setValue]);

  const handleListItemClick = useCallback(
    (airport: string) => () => {
      const airports = watch(fieldName);
      const isFound = airports?.find((item) => item === airport);
      setQuery('');

      if (isFound) {
        const filtered = airports?.filter((item) => item !== airport);
        setValue(fieldName, filtered);
        return;
      }

      if (airports?.length >= 4) return;

      setValue(fieldName, [...(airports || []), airport]);
      disclosure.onClose();
    },
    [watch, fieldName, disclosure, setValue],
  );

  const handleChangeSearchType = useCallback(
    (nextSearchType: SearchType) => () => {
      setSearchType(nextSearchType);
      setQuery('');
      setSelectedAddress(null);
    },
    [],
  );

  const handlePredictionChange = useCallback(
    (prediction: Suggestion) => async () => {
      const isChecked = prediction?.place_id === selectedAddress?.place_id;

      if (isChecked) {
        setSelectedAddress(null);
        return;
      }

      const results = await getGeocode({ address: prediction?.description });
      const result: GeocodeResult = results?.[0];
      const coords = getLatLng(result);

      const city = result?.address_components?.find(
        (component) => component?.types?.indexOf('locality') !== -1,
      )?.short_name;
      const state = result?.address_components?.find(
        (component) => component.types.indexOf('administrative_area_level_1') !== -1,
      )?.short_name;
      const country = result?.address_components?.find(
        (component) => component?.types?.indexOf('country') !== -1,
      )?.short_name;

      if (isOrigin && !originAirports?.length) {
        setValue('cargo_origin', {
          line_one: '',
          line_two: '',
          zip_code: '',
          city,
          state,
          country,
        });
      }

      setSelectedAddress({
        place_id: result?.place_id,
        name: prediction?.description,
        latitude: coords?.lat,
        longitude: coords?.lng,
        country,
      });
    },
    [isOrigin, originAirports?.length, selectedAddress?.place_id, setValue],
  );

  const renderSelectedAirports = useCallback(() => {
    const airports = watch(fieldName);

    return airports?.map((airport: string) => {
      return (
        <WrapItem key={airport}>
          <Tag size="sm" borderRadius="full" variant="outline" color="offBlack">
            <TagLabel>{airport}</TagLabel>
            <TagCloseButton onClick={handleListItemClick(airport)} />
          </Tag>
        </WrapItem>
      );
    });
  }, [fieldName, handleListItemClick, watch]);

  const renderPrediction = useCallback(
    (prediction: Suggestion) => {
      const isChecked = prediction?.place_id === selectedAddress?.place_id;

      return (
        <Checkbox
          key={prediction?.place_id}
          size="md"
          colorScheme="orange"
          isChecked={isChecked}
          whiteSpace="nowrap"
          onChange={handlePredictionChange(prediction)}
        >
          {prediction?.description}
        </Checkbox>
      );
    },
    [handlePredictionChange, selectedAddress],
  );

  const errorMessage = useMemo(() => {
    const errors = formState?.errors;

    if (errors?.origin_airports?.message) {
      return errors?.origin_airports?.message;
    }
    if (errors?.destination_airports?.message) {
      return errors?.destination_airports?.message;
    }

    return null;
  }, [formState?.errors]);

  if (!typesense?.searchClient) return <Skeleton h="full" w="full" />;

  return (
    <InstantSearch searchClient={typesense?.searchClient} indexName="airports">
      <Stack ref={contentRef}>
        <Popover isOpen={disclosure.isOpen} matchWidth returnFocusOnClose={false} initialFocusRef={initialFocusRef}>
          <PopoverTrigger>
            <SimpleField errorMessage={errorMessage} removePadding h="full" tabIndex={-1}>
              <Stack
                direction="row"
                h="full"
                tabIndex={0}
                _focus={{
                  outline: 'none', // Remove default outline if needed
                  boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)', // Example focus style
                  borderColor: 'blue.500', // Example: change border color on focus
                }}
              >
                <Stack direction="column" flex="1" h="full" cursor="pointer" p="4" onClick={handleOpen(View.Origin)}>
                  <Text textStyle="label">Origin</Text>
                  <Stack direction="row" w="full" alignItems="center" spacing="3" h="full">
                    <Icon as={IconTakeOff} />
                    <Text fontSize="md" color={originValue !== 'Origin' ? 'text.default' : 'text.placeholder'}>
                      {originValue}
                    </Text>
                  </Stack>
                </Stack>
                <Stack
                  direction="column"
                  flex="1"
                  h="full"
                  p="4"
                  pr="12"
                  cursor="pointer"
                  onClick={handleOpen(View.Destination)}
                >
                  <Text textStyle="label">Destination</Text>
                  <Stack direction="row" w="full" alignItems="center" spacing="3" h="full">
                    <Icon as={IconLanding} />
                    <Text
                      fontSize="md"
                      color={destinationValue !== 'Destination' ? 'text.default' : 'text.placeholder'}
                    >
                      {destinationValue}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </SimpleField>
          </PopoverTrigger>
          <PopoverContent w="full" maxW="none" overflowY="auto" boxShadow="2xl" maxH={450}>
            <PopoverBody w="full" maxW="none">
              <Stack w="full" maxW="none" pb="4">
                <Wrap>{renderSelectedAirports()}</Wrap>
                <Stack direction="row" w="full" py="4">
                  <Stack direction="row" align="center" flex="1">
                    <Divider orientation="vertical" borderWidth="1px" borderColor="secondary" h="16px" opacity="1" />
                    <Show if={searchType === SearchType.Airport}>
                      <RoutePickerSearchBox
                        ref={initialFocusRef}
                        query={query}
                        setQuery={setQuery}
                        isOrigin={isOrigin}
                      />
                    </Show>
                    <Show if={searchType === SearchType.Address}>
                      <Input
                        ref={initialFocusRef}
                        placeholder={!isOrigin ? 'Where to?' : 'Where from?'}
                        variant="unstyled"
                        value={locationValue}
                        _placeholder={{
                          color: 'text.placeholder',
                        }}
                        onChange={(event: any) => setLocationValue(event.target.value)}
                        autoComplete="off"
                      />
                    </Show>
                  </Stack>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant={searchType === SearchType.Airport ? 'underline' : 'unselected'}
                      onClick={handleChangeSearchType(SearchType.Airport)}
                    >
                      by Airport
                    </Button>
                    <Button
                      size="sm"
                      variant={searchType === SearchType.Address ? 'underline' : 'unselected'}
                      onClick={handleChangeSearchType(SearchType.Address)}
                    >
                      by Address
                    </Button>
                  </ButtonGroup>
                </Stack>

                <Stack>
                  <Show if={searchType === SearchType.Airport}>
                    <RoutePickerHits
                      selectedHits={watch(fieldName)}
                      query={query}
                      searchType={searchType}
                      isDestination={isDestination}
                      hasSelectedAddress={!!selectedAddress?.place_id}
                      handleHitClick={handleListItemClick}
                    />
                  </Show>
                  <Show if={!!selectedAddress}>
                    <Checkbox
                      key={selectedAddress?.place_id}
                      size="md"
                      colorScheme="orange"
                      isChecked
                      whiteSpace="nowrap"
                      onChange={handlePredictionChange(selectedAddress as any)}
                    >
                      {selectedAddress?.name}
                    </Checkbox>
                  </Show>
                  <Show if={searchType === SearchType.Address}>
                    <Show if={!selectedAddress}>{suggestions?.data?.map(renderPrediction)}</Show>
                    <Show if={!!selectedAddress}>
                      <Divider orientation="horizontal" />
                      <RoutePickerHits
                        selectedHits={watch(fieldName)}
                        query={query}
                        searchType={searchType}
                        isDestination={isDestination}
                        hasSelectedAddress={!!selectedAddress?.place_id}
                        handleHitClick={handleListItemClick}
                      />
                    </Show>
                  </Show>
                </Stack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>
    </InstantSearch>
  );
};
