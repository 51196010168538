import { StackProps } from '@chakra-ui/react';
import { SelectOption } from '@pelicargo/types';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ReactSelectProps } from '../utils/types';
import { ReactSelect } from './ReactSelect';
import { SimpleField } from './SimpleField';
import { useSimpleFormContext } from './SimpleForm';

type OptionDataTypes = string | number | boolean;

const convertSingleValToOption = (val: OptionDataTypes): SelectOption => ({
  label: String(val),
  value: String(val),
});
const convertSingleOptionToVal = (val: SelectOption) => val?.value;

const convertValToOption = (val: OptionDataTypes | OptionDataTypes[]): SelectOption | SelectOption[] => {
  if (Array.isArray(val)) return val.map(convertSingleValToOption);
  return convertSingleValToOption(val);
};

const convertOptionToVal = (val: SelectOption | SelectOption[]): OptionDataTypes | OptionDataTypes[] => {
  if (Array.isArray(val)) return val.map(convertSingleOptionToVal);
  return convertSingleOptionToVal(val);
};

type GroupOption = {
  label: string;
  options: SelectOption[];
};

type SimpleReactSelectProps = ReactSelectProps & {
  max?: number;
  defaultValue?: string;
  options?: SelectOption[];
  optionGroupOptions?: GroupOption[];
  simpleFieldProps?: StackProps;
  onCreateOption?: (inputValue: string) => void;
};

export const SimpleReactSelect = ({
  name,
  max,
  options,
  optionGroupOptions,
  label,
  helper,
  defaultValue,
  simpleFieldProps,
  ...rest
}: SimpleReactSelectProps) => {
  const { simpleOnBlur } = useSimpleFormContext();
  const { control } = useFormContext();
  const {
    field: { onChange, value, ...fieldProps },
  } = useController({ name, control });

  const localOnChange = (option: SelectOption) => {
    const val = convertOptionToVal(option);

    onChange(val === 'undefined' ? undefined : val);
    simpleOnBlur?.({ target: { name } } as any);
  };

  const selectedOption = useMemo(() => {
    if (!value && !defaultValue) return null;
    if (!value && defaultValue) convertValToOption(defaultValue);

    const foundOption = options?.find((option: SelectOption) => String(option.value) === String(value)) as
      | SelectOption
      | undefined;
    if (foundOption) return foundOption;
    return convertValToOption(value);
  }, [options, value, defaultValue]);

  return (
    <SimpleField name={name} label={label} helper={helper} isReactSelect p={0} pb={2} {...simpleFieldProps}>
      <ReactSelect
        {...rest}
        max={max}
        options={options || optionGroupOptions}
        onChange={localOnChange}
        value={selectedOption}
        variant="unstyled"
        onCreateOption={rest.onCreateOption}
        {...fieldProps}
      />
    </SimpleField>
  );
};
