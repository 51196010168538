import type { FrontChat, FrontChatOptions } from './types';

const scriptSrc = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

export const boot = async (element?: HTMLElement, options?: FrontChatOptions): Promise<FrontChat> => {
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('src', scriptSrc);

  if (options?.nonce) {
    scriptTag.setAttribute('nonce', options.nonce);
  }

  const scriptContainer = element ?? document.body;

  const loadScriptPromise = new Promise<FrontChat>((resolve) => {
    scriptTag.onload = () => {
      if (!window.FrontChat) {
        throw new Error('[front-chat-sdk] Could not set up window.FrontChat');
      }

      resolve(window.FrontChat);
    };
  });

  scriptContainer.appendChild(scriptTag);

  return loadScriptPromise;
};

export const initialize = async (chatId: string, element?: HTMLElement) => {
  const frontChat = await boot(element);

  frontChat('init', { chatId });
};
