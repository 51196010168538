import { SystemStyleObject, useColorModeValue } from '@chakra-ui/react';
import { ChakraStylesConfig, CreatableSelect, Select as RSelect } from 'chakra-react-select';
import { useState } from 'react';

import { ReactSelectProps } from '../utils/types';

export const ReactSelect = ({
  value: controlledValue,
  onChange: controlledOnChange,
  onCreateOption,
  name,
  max,
  options,
  defaultValue,
  type,
  showDropdownIndicator = true,
  isMulti = false,
  ...rest
}: ReactSelectProps) => {
  const [value, setValue] = useState(defaultValue);

  const defaultOption = defaultValue ? { value: defaultValue, label: defaultValue } : '';
  const bgColor = useColorModeValue('transparent', 'whiteAlpha.50');
  const bgColorHover = useColorModeValue('transparent', 'whiteAlpha.100');
  const tagVariant = useColorModeValue('solid', 'subtle');

  const chakraStyles: ChakraStylesConfig = {
    control: (provided: SystemStyleObject) => ({
      ...provided,
      border: 0,
    }),
    dropdownIndicator: (provided: SystemStyleObject) => ({
      ...provided,
      bgColor,
      px: 2,
      _hover: { bgColor: bgColorHover },
      display: showDropdownIndicator ? 'flex' : 'none',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: (provided: SystemStyleObject, { hasValue, isMulti }) => ({
      ...provided,
      bgColor,
      px: hasValue && isMulti ? 2 : 4,
      py: 2,
      _hover: { bgColor: bgColorHover },
    }),
    option: (provided: SystemStyleObject) => ({
      ...provided,
      pl: 4,
    }),
    group: () => ({ pb: 4, pl: 4 }),
    groupHeading: (provided: SystemStyleObject) => ({
      ...provided,
      p: 4,
      ml: -4,
      fontSize: 16,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderWidth: 0,
    }),
    menuList: (provided: SystemStyleObject) => ({
      ...provided,
      width: '200px',
    }),
  };

  const finalValue: any = controlledValue || value || '';

  const finalSetValue = controlledOnChange || setValue;

  return (
    <>
      {type === 'creatable' ? (
        <CreatableSelect
          chakraStyles={chakraStyles}
          tagVariant={tagVariant}
          size="sm"
          isClearable={false}
          isMulti={isMulti}
          name={name}
          onChange={finalSetValue}
          value={finalValue}
          options={options}
          onCreateOption={onCreateOption}
          defaultValue={defaultOption}
          {...rest}
        />
      ) : (
        <RSelect
          chakraStyles={chakraStyles}
          tagVariant={tagVariant}
          size="sm"
          isClearable={false}
          isMulti={isMulti}
          onChange={finalSetValue}
          value={finalValue}
          options={options}
          isOptionDisabled={() => !!max && finalValue?.length >= max}
          defaultValue={defaultOption}
          {...rest}
        />
      )}
    </>
  );
};
