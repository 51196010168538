import { isValid } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';

export const join = (array: (String | undefined)[], separator?: string) => {
  if (!array) return;

  const defaultSeparator = ', ';
  return array
    .filter(Boolean)
    .map((n) => String(n).trim())
    .join(separator || defaultSeparator);
};

type PrettyDateOptions = {
  showTime?: boolean;
  timeZone?: string;
  onlyTime?: boolean;
  hideYear?: boolean;
  defaultDisplayValue?: string;
};

// Formats a date in military time
export const prettyDate = (date: Date | string | null | undefined, options?: PrettyDateOptions): string => {
  const defaultDisplayValue = options?.defaultDisplayValue ?? '-';

  const hasNoValue = date === null || date === undefined || date === '-';
  if (hasNoValue) return defaultDisplayValue;

  const normalizedDate = new Date(date);

  if (!isValid(normalizedDate)) return defaultDisplayValue;

  const zonedDate = options?.timeZone ? toZonedTime(normalizedDate, options?.timeZone) : (normalizedDate as Date);

  let opt = 'dd MMM yyyy';
  if (options?.showTime) opt = 'dd MMM yyyy HH:mm';
  if (options?.onlyTime) opt = 'HH:mm';
  if (options?.hideYear) opt = 'dd MMM';

  return format(zonedDate, opt);
};
