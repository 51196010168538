import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useError = () => {
  const toast = useToast();

  const handleError = useCallback(
    (error?: any) => {
      const message = error?.response?.data?.detail || error?.response?.data?.message || error?.message || error;

      console.error('useError():', error);

      toast({
        title: 'Something went wrong',
        description: message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
    [toast],
  );

  return {
    handleError,
  };
};
