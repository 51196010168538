import { Container, Heading, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';

import { Page } from '../../components/Page';

export const Terms = () => {
  return (
    <Page title="Terms & Conditions">
      <Container maxW="container.lg" p="8">
        <Stack>
          <Heading as="h1" size="xl">
            Pelicargo Terms of Service
          </Heading>
          <Text fontSize="lg">Updated on Jul 20, 2022</Text>
        </Stack>
        <Stack spacing="6" pt="8">
          <Stack>
            <Heading size="lg">1. General</Heading>
            <Text fontSize="lg">
              These terms and conditions stated herein this Terms of Service (the “Agreement” or “Terms”) constitutes a
              legal agreement between businesses including beneficial cargo owners, third party logistics companies, and
              any other entity with an interest in air cargo for which rates for air transportation have been obtained
              via the Services (&quot;you&quot; or “Customer”) and Pelicargo Inc. (a Delaware Corporation, hereinafter
              referred to as “Pelicargo,” “we” or “us”), and, they govern your access and use of the services found or
              accessed at pelicargo.com, or those services offered by a Pelicargo employee (the “Pelicargo Platform”),
              and the associated software, website, content, products, programs, and services (the “Services”), made
              available to you.
            </Text>
            <Text fontSize="lg">
              These Terms together with the privacy policy available at <a href="privacy">www.pelicargo.com/privacy</a>{' '}
              (&quot;Privacy Policy&quot;) and other additional agreements referred to and/or linked herein, all of
              which are incorporated herein by reference, are, collectively, the “Agreement.” As used herein, “User”
              means any user of the Service, and includes any Customer, Supplier, and any individual representing or
              purporting to represent any such entity.
            </Text>

            <Text fontSize="lg">
              Please, read these Terms and any additional terms applicable to your use of the Services before using it
              and you also need to consent to the collection and use of your data in compliance with our Privacy Policy.
              By accessing or using the Services either as a registered user or not, you confirm your agreement to be
              bound by these Terms. These Terms expressly supersede prior agreements or arrangements with you regarding
              the use of the Services. Pelicargo cannot and will not be liable for any loss or damage arising from
              Customer's failure to comply with the above requirements. We may modify these Terms at any time, at our
              sole discretion as they are subject to change. If we do so, we will let you know either by posting the
              modified Terms on the Service or through other communications such as the email address registered in your
              Account. It's important that you review the Terms whenever we modify them because if you continue to use
              the Services after we have posted modified Terms on the platform, you are indicating to us that you agree
              to be bound by the modified Terms.
            </Text>

            <Text fontSize="lg">
              We may modify these Terms at any time, at our sole discretion as they are subject to change. If we do so,
              we will let you know either by posting the modified Terms on the Service or through other communications
              such as the email address registered in your account. It's important that you review the Terms whenever we
              modify them because if you continue to use the Services after we have posted modified Terms on the
              platform, you are indicating to us that you agree to be bound by the modified Terms.
            </Text>

            <Text fontSize="lg">
              If you do not agree to be bound by the modified Terms, then you may not use the Services anymore. Because
              our Services are evolving over time we may change or discontinue all or any part of the Services, at any
              time and without notice, at our sole discretion
            </Text>

            <Text fontSize="lg">PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.</Text>
          </Stack>
          <Stack>
            <Heading size="lg">2. Description and Scope of Services</Heading>

            <Text fontSize="lg">
              The Services constitute a technology platform designed to help Customer access air cargo capacity and
              rates made available by air carriers and General Sales Agents (“Suppliers”). Through the Pelicargo booking
              system, Customers search for quotes and create bookings with the Suppliers.
            </Text>

            <Text fontSize="lg">
              Pelicargo is not a carrier or a freight forwarder and will not at any point come into physical contact
              with your cargo. Pelicargo is not responsible for any non-performance or breach of any contract entered
              into between users of this Service, including, but not limited to, Customers or Suppliers. We do not own
              or control any of the cargo services nor the products accessed through Pelicargo such as flights, quotes,
              insurance, and packages (the “Third-Party Services”). The Third-Party services are owned, controlled or
              made available by third parties (the “Suppliers”) either directly (e.g. airlines) or as an agent (e.g.,
              GSA). Pelicargo makes no warranties or representations regarding the Suppliers' services, nor the terms or
              conditions pursuant to which the Suppliers provide services. The Supplier's terms and conditions apply to
              your booking and the actual shipping so you must agree to and understand those terms. Your interaction
              with any Supplier accessed through our Service is at your own risk; Pelicargo does not bear any
              responsibility should anything go wrong with your booking or shipment.
            </Text>

            <Text fontSize="lg">
              The display on Pelicargo of a Supplier does not imply, suggest, or constitute a recommendation, approval
              or sponsorship by Pelicargo of such Service. Each Supplier remains responsible at all times for the
              accuracy, completeness and correctness of the information displayed on our website.{' '}
            </Text>

            <Text fontSize="lg">
              Pelicargo does not arrange or offer to arrange for the transportation of air cargo. Consequently,
              Pelicargo is not registered with the Transportation Security Administration (“TSA”) as an Indirect Air
              Carrier. To the extent Customer determines that Pelicargo may perform any security functions under
              Customer's TSA-approved security program, Customer must notify Pelicargo prior to the performance of such
              functions.
            </Text>

            <Text fontSize="lg">
              Access to the Pelicargo Platform occurs via the internet. You are responsible for maintaining the internet
              access and the hardware (e.g. router, smart device) or software (e.g. browser, plug-ins, apps) required by
              the customer to access the Services.
            </Text>

            <Text fontSize="lg">
              The current version of the Pelicargo Platform is made available to the Customer. Pelicargo.com is entitled
              to further develop the platform and provide new versions without notice.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">3. Payment Terms</Heading>
            <Text fontSize="lg">
              All payment rates and charges quoted by Suppliers are communicated from the Supplier to Customer via the
              Pelicargo Platform and/or Services on a pass-through basis, meaning that the rates are based solely on
              information provided by Customer and offered by Supplier. Customer will select the Supplier based on its
              sole discretion and preferences.{' '}
            </Text>
            <Text fontSize="lg">
              Any payment from the Customer to the Supplier will not flow through Pelicargo. Pelicargo specifically
              disclaims any and all responsibilities, duties, or obligations relating to the payment between Supplier
              and Customer. Pelicargo further makes no representations or warranties as to the accuracy of the
              Supplier's charges or rates as all such charges and rates are made based on the information provided by
              the Customer.{' '}
            </Text>
            <Text fontSize="lg">
              Pelicargo shall charge Customer a management fee to be agreed upon between Pelicargo and Customer on a
              shipment-by-shipment basis (the “Charge”). The Charge will be based in part on the shipment cost quoted by
              the Supplier. The Charge, along with any other applicable charges or fees, shall be paid in full, and
              without any deduction or offset, within 15 days of the date of the invoice issued by Pelicargo to
              Customer. Customer shall be liable for all reasonable attorney's fees, litigation expenses, and costs
              incurred by Pelicargo in its attempts to collect any late fees or late payments. Customer further agrees
              that it all fees paid or owed to Pelicargo after 15 days of the date of the invoice issued shall accrue
              interest at a rate of 6% per annum, accruing daily, and will be due and owing as if the interest payments
              were set forth as part of the original invoiced amount.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">4. Relationship With Pelicargo</Heading>

            <Text fontSize="lg">
              YOU ACKNOWLEDGE, AGREE, AND UNDERSTAND THAT PELICARGO IS NOT A PARTY TO THE RELATIONSHIP OR ANY DEALINGS
              BETWEEN USERS OF THE SERVICE. WE DO NOT PROVIDE FREIGHT FORWARDING OR CARRIER SERVICES, AND WE ARE NOT THE
              EMPLOYER OF ANY SUPPLIER. You acknowledge that we do not supervise, direct, or control a Supplier's work
              or Services performed in any manner. A Supplier provides services to you as an independent contractor
              engaged by you directly.
            </Text>

            <Text fontSize="lg">
              By making a booking through Pelicargo, you enter into a direct (legally binding) contractual relationship
              with the Supplier whom you select to book with. From the point at which you make your booking, we act
              solely as an intermediary between you and the Supplier, transmitting the details of your booking to the
              relevant Supplier and returning confirmation to you for on behalf of the Supplier.
            </Text>

            <Text fontSize="lg">
              Customer is solely responsible for determining the suitability of a Supplier for a service and the
              performance of such service. Although we will use reasonable skill and care in performing our services, we
              make no representations nor guarantee about a Supplier, and you agree not to hold us responsible for, the
              quality, safety, or qualification of a Supplier. Likewise, you acknowledge and agree that Suppliers may
              limit the scope or amount of their liability arising from or related to their services, including, but not
              limited to, liability arising from or related to cargo loss, damage or delay. Customer will not use
              Pelicargo to obtain rates for the transportation of any cargo regulated as hazardous materials/dangerous
              goods, nor any cargo requiring controlled temperature transportation, live plants or animals, or cargo
              requiring accompaniment by an individual (e.g., carry-on courier service). Likewise, Suppliers may impose
              liability for cancellation of scheduled services. IN NO EVENT WILL PELICARGO BE LIABLE FOR ANY--AND
              CUSTOMER WILL HOLD HARMLESS, INDEMNIFY AND DEFEND PELICARGO FROM, ANY--LOSS, DAMAGE, DESTRUCTION OR DELAY
              OF OR TO CARGO, FOR ANY FEES, CHARGES OR PENALTIES ASSOCIATED WITH CANCELLATION BY CUSTOMER OF ITS REQUEST
              FOR AIR CARGO TRANSPORTATION, NOR THE FAILURE OF A SUPPLIER TO TRANSPORT CARGO.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">5. Registration as a Customer</Heading>

            <Text fontSize="lg">
              In order to use the Service, Customers must register for a Pelicargo Account on the platform (“Account”).
              By accessing the Services, you represent and warrant that you are at least 18 years old and that you have
              the right and authority to bind such Customer to the Agreement. Customer is responsible for any and all
              use of or access to its Account and for all acts or omissions associated the Account or any User accessing
              the Service via the Account. By registering, the User declares that he/she is entitled to make binding
              bookings for and on behalf of the Customer via the platform.
            </Text>
            <Text fontSize="lg">
              Pelicargo reserves the right to revoke the privileges of the Account or access to or use of the Services,
              and those of any and all linked Accounts without warning if, in our sole discretion, false or misleading
              information has been provided in creating, marketing, or maintaining your Profile or Account.{' '}
            </Text>
            <Text fontSize="lg">
              At all times, an official company email address is required from the respective Users who register. The
              information provided by the User during registration must be complete, truthful and updated immediately by
              the customer in the event of subsequent changes. You agree not to provide any false or misleading
              information. A claim to a certain User name does not exist. The Customer is entitled to register several
              times under different User names for different Users.
            </Text>

            <Text fontSize="lg">
              During the registration process, an initial password is set by the User to a password of the User's own
              choice, that is secure in accordance with the system guidelines. The password must be kept secret by the
              User, protected against misuse and regularly changed in accordance with the system guidelines. In the
              event of misuse or suspicion of misuse of user name or password, or in the event of loss or unauthorized
              access to or of such credentials, the Customer must immediately inform Pelicargo. In the event of misuse,
              the customer shall be liable; unless the customer is not responsible for the misuse. You hereby
              acknowledge and agree that Pelicargo will not in any way be liable to you for any loss caused by
              unauthorized access to and use of your Account.
            </Text>

            <Text fontSize="lg">
              Pelicargo reserves the right to request appropriate information during the registration process and to
              demand proof that the customer is an operational business. This can be, for example, the provision of the
              sales tax identification number, data from the commercial register, and IATA certification number for the
              customer.
            </Text>
            <Text fontSize="lg">
              Customer is solely responsible for the completeness and accuracy of any information input into the Service
              via any Account associated to Customer. Customer shall specifically provide any special or specific
              handling requirements for any goods. The Customer is solely responsible for compliance with any and all
              requirements related to the tendering, offering, or otherwise transporting hazardous materials or
              dangerous goods including, but not limited to, declaring, classifying and packaging. In addition, Customer
              is solely responsible for the packaging of any cargo or goods requiring controlled- temperature
              transportation, including, but not limited to, ensuring such cargo remains within required temperature
              ranges. If Customer inputs shipment specific information into the Service, any resulting transportation of
              such shipment must be booked using the Service; Customer will not engage with any Supplier without using
              the Service.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">6. Intellectual Property Rights and Copyrights</Heading>

            <Text fontSize="lg">
              This platform is controlled and operated by Pelicargo and all material and intellectual property on this
              Service, including but not limited to software, images, illustrations, text, scripts, graphics, audio
              clips, and video clips, and other interactive features made available to you on the Service, logos,
              trademarks, patents, trade secrets and service marks contained herein (collectively, “Intellectual
              Property”), are owned by Pelicargo. Other service marks, logos, and names on this Service are the property
              of their respective owners. Customer's use of the Service and Platform does not transfer to Customer any
              Intellectual Property rights therein. Customer is not permitted to use this Intellectual Property without
              Pelicargo's prior written consent or the consent of such third party which may own the Intellectual
              Property
            </Text>

            <Text fontSize="lg">
              This section, and Pelicargo's ownership rights, exclude Customer's User Content, which is defined in
              Section 6 below.
            </Text>

            <Text fontSize="lg">
              Any use of the Service or the contents made available to you on the Service other than as specifically
              authorized herein, without our prior written permission, is strictly prohibited and will terminate the
              license granted herein. Such unauthorized use may also violate applicable laws including without
              limitation copyright and trademark laws and applicable communications regulations and statutes. Unless
              explicitly stated herein, nothing in these Terms shall be construed as conferring any license to
              intellectual property rights. This license is revocable by Pelicargo at any time without notice and with
              or without cause.{' '}
            </Text>

            <Text fontSize="lg">
              Without the prior written consent of the owner, modification of the materials, use of the materials on any
              other website or networked computer environment, or use of the materials for any purpose other than
              personal, non-commercial use is a violation of the copyrights, trademarks, and other proprietary rights,
              and is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a
              commercial use for the purposes of this clause.{' '}
            </Text>

            <Text fontSize="lg">
              Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-sublicensable,
              revocable, non-transferable license to access and use the Services subject to the Terms of this Agreement.
              Any rights not expressly granted herein are reserved by us.{' '}
            </Text>

            <Text fontSize="lg">
              You may not: (i) remove any copyright, trademark, or other proprietary notices from any portion of the
              Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell,
              resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the
              Services except as expressly permitted by us; (iii) decompile, reverse engineer or disassemble the
              Services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the
              Services; or (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying,
              or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or
              functionality of any aspect of the Services.
            </Text>

            <Text fontSize="lg">
              If Customer provides Pelicargo any feedback, suggestions, bug reports, system errors, and other
              information or ideas regarding the Service (“Feedback”), Customer hereby assigns to Pelicargo all rights
              in the Feedback and agree that Pelicargo shall have the right to use such Feedback and related information
              in any manner it deems appropriate. Unless otherwise required under this Agreement, Pelicargo has no
              obligation to review, respond to, resolve complaints or issues identified in, or otherwise address any
              Feedback Customer provides. Pelicargo will treat any Feedback Customer provides to Pelicargo as
              non-confidential and non-proprietary. Customer agrees that Customer will not submit to Pelicargo any
              information or ideas that Customer considers to be confidential or proprietary. If Customer agrees to
              participate in any case studies, Customer agrees that information Customer provides in connection with the
              case study is deemed Feedback and that Pelicargo may use Customer's name in connection with such Feedback.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">7. User Content</Heading>

            <Text fontSize="lg">
              “User Content” means any and all information, data, and other content that a User submits to, or uses
              with, the Service. Customer is solely responsible for Customer's User Content. Customer assumes all risks
              associated with use of Customer's User Content, including any reliance on its accuracy, completeness or
              usefulness by others, or any disclosure of Customer's User Content that makes Customer or any third party
              personally identifiable. Customer hereby represents and warrants that Customer's User Content does not
              violate the terms of the Agreement. For the avoidance of doubt, User Content may include third-party
              content Customer submits. Customer agrees not to submit third-party content unless Customer has the
              consent of the applicable third-party owner of such content. Customer may not state or imply that
              Customer's User Content is in any way provided, sponsored or endorsed by Pelicargo. Customer alone is
              responsible for any liability resulting from Customer's User Content. Pelicargo does not control User
              Content, and Customer acknowledges and agrees that Pelicargo assumes no responsibility for any User
              Content and makes no guarantees regarding the accuracy, currency, suitability, or quality of any User
              Content.
            </Text>

            <Text fontSize="lg">
              Pelicargo may create anonymous data records (“Anonymous Data”) from Customer's User Content by using
              commercially reasonable efforts to exclude any and all information (such as company name) that would
              identify Customer. Pelicargo may use and disclose Anonymous Data for any purpose, including to improve the
              Service.
            </Text>
            <Text fontSize="lg">
              Pelicargo may share Customer's User Content (a) with Pelicargo's third-party service providers; (b) if
              another company acquires Pelicargo's company, business, or Pelicargo's assets, including through
              bankruptcy, with such acquiring company; and (c) to comply with relevant laws, to respond to subpoenas or
              warrants served on us, to protect or defend Pelicargo's or Pelicargo's Users' rights or property, and/or
              to investigate or assist in preventing any violation or potential violation of the law or this Agreement.
            </Text>
            <Text fontSize="lg">
              Notwithstanding the foregoing or any other of the Terms, Pelicargo will not via the Services or otherwise
              exchange Users' competitively sensitive information between or among the Users in a manner that runs afoul
              of antitrust laws. To the extent Pelicargo discloses the rates or other competitively sensitive
              information of Users to other competing Users, Pelicargo will disclose such information in conformity with
              the antitrust safety zone set forth in the Statement of Department of Justice and Federal Trade Commission
              Enforcement Policy on Provider Participation in Exchanges of Price and Cost Information.{' '}
            </Text>
            <Text fontSize="lg">
              Upon termination of the Agreement, Pelicargo will delete or dispose of any and all identifiable data
              collected from Customer within a commercially reasonable amount of time. This deletion paragraph shall not
              apply to Anonymous Data, and Pelicargo may continue to use and disclose Anonymous Data for any purpose
              after the expiration or termination of the Agreement.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">8. User Representation and Platform Use</Heading>

            <Text fontSize="lg">
              You hereby represent, warrant and agree that no materials of any kind submitted through your account or
              otherwise posted or shared by you through the service will be in violation of the rights of any third
              party, including but not limited to the copyright, trademark, publicity, privacy or other personal or
              proprietary rights. If you become aware that you have violated the rights of any third party for any
              reason, including but not limited to their copyright, trademark, publicity, privacy or other personal or
              proprietary rights, provide immediate written notice to Pelicargo detailing such violation.{' '}
            </Text>

            <Text fontSize="lg">
              Although we are committed to making reasonable efforts to provide a safe user experience, we do not
              guarantee that the platform, or any content in it, will be safe, error-free or uninterrupted, or that it
              will be free from bugs or viruses. From time to time, access to the service may be interrupted, suspended,
              or restricted, including because of a fault, error or unforeseen circumstances, or scheduled maintenance.
              We shall not be liable to you for any loss or damage that you may suffer as a result of the service being
              unavailable at any time for any reason.
            </Text>
            <Text fontSize="lg">
              You agree, undertake and confirm that your use of the platform shall be strictly in accordance with the
              following binding guidelines:
            </Text>
            <Text fontSize="lg">
              You shall not host, display, upload, modify, publish, transmit, update or share any information which:
            </Text>

            <ul>
              <li>
                belongs to another person and to which you do not have any right to make use of or promotes an illegal
                or unauthorized copy of another person&#39;s copyrighted work such as providing pirated computer
                programs or links to them, providing information to circumvent manufacture-installed copy-protect
                devices;{' '}
              </li>
              <li>
                is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous,
                invasive of another&#39;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating
                or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or,
              </li>
              <li>
                is patently offensive to the online community, such as sexually explicit content, or content that
                promotes obscenity, pedophilia, racism, bigotry, hatred, or physical harm of any kind against any group
                or individual or provides material that exploits people in a sexual, violent, or otherwise inappropriate
                manner or solicits personal information from anyone;{' '}
              </li>
              <li>
                Involves the transmission of &quot;junk mail&quot;, &quot;chain letters&quot;, or unsolicited mass
                mailing or &quot;spamming&quot; or contains any trojan horses, worms, time bombs, cancelbots, easter
                eggs, or other computer programming routines that may damage, detrimentally interfere with, diminish the
                value of, surreptitiously intercept or expropriate any system, data or personal information;{' '}
              </li>
              <li>
                promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory, or libelous;
              </li>
              <li>
                Provides instructional information about illegal activities such as making or buying illegal weapons,
                violating someone&#39;s privacy, or providing or creating computer viruses; contains video, photographs,
                or images of another person (with a minor or an adult);
              </li>
              <li>
                tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to
                profiles, blogs, communities, account information, bulletins, or other areas of the Website or solicits
                passwords or personal identifying information for commercial or unlawful purposes from other users.
              </li>

              <li>
                tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to
                profiles, blogs, communities, account information, bulletins, or other areas of the Website or solicits
                passwords or personal identifying information for commercial or unlawful purposes from other users.
              </li>

              <li>
                Solicits gambling or engages in any gambling activity which we, in our sole discretion, believes is or
                could be construed as being illegal;{' '}
              </li>
              <li>
                Interferes with another user&#39;s use and enjoyment of the website or any other individual&#39;s user
                and enjoyment of similar services;{' '}
              </li>

              <li>
                Refers to any website or URL that, in our sole discretion, contains material that is inappropriate for
                the Website or any other website, contains content that would be prohibited, or violates the letter or
                spirit of these Terms of Service.{' '}
              </li>
            </ul>

            <Text fontSize="lg">
              You shall not use any &quot;deep-link&quot;, &quot;page-scrape&quot;, &quot;robot&quot;,
              &quot;spider&quot; or other automatic devices, program, algorithm or methodology, or any similar or
              equivalent manual process, to access, acquire, copy or monitor any portion of the Service or any content,
              or in any way reproduce or circumvent the navigational structure or presentation of the Service or any
              content, to obtain or attempt to obtain any materials, documents or information through any means not
              purposely made available through the Service. We reserve our right to bar any such activity.{' '}
            </Text>

            <Text fontSize="lg">
              You shall not attempt to gain unauthorized access to any portion or feature of the Service, or any other
              systems or networks connected to the Service or to any server, computer, network, or to any of the
              services offered on or through the Service, by hacking, password &quot;mining&quot; or any other
              illegitimate means.{' '}
            </Text>

            <Text fontSize="lg">
              You shall not probe, scan or test the vulnerability of the Service or any network connected to the Website
              nor breach the security or authentication measures on the Website or any network connected to the Website.
              You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to
              Website, or any other customer, including any account on the Website not owned by You, to its source, or
              exploit the Website or any service or information made available or offered by or through the Service, in
              any way where the purpose is to reveal any information, including but not limited to personal
              identification or information, other than Your own information, as provided for by the Website.{' '}
            </Text>

            <Text fontSize="lg">
              You agree not to use any device, software, or routine to interfere or attempt to interfere with the proper
              working of the Service or any transaction being conducted on the Service, or with any other person&#39;s
              use of the Service.{' '}
            </Text>

            <Text fontSize="lg">You agree to not provide access to the Service to any unauthorized employees.</Text>

            <Text fontSize="lg">
              It is possible those other users (including unauthorized users or &quot;hackers&quot;) may post or
              transmit offensive or obscene materials on the Service and that you may be involuntarily exposed to such
              offensive and obscene materials. It also is possible for others to obtain personal information about you
              due to your use of the Service, and that the recipient may use such information to harass or injure you.
              We do not approve of such unauthorized uses, but by using the Service, you acknowledge and agree that we
              are not responsible for the use of any personal information that you publicly disclose or share with
              others on the Service. Please carefully select the type of information that you publicly disclose or share
              with others on the Service.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">9. Liability of Links</Heading>

            <Text fontSize="lg">
              The Service and platform might contain links to third party websites, services, and advertisements for
              third parties (collectively, “Third Party Sites &amp; Ads”). Such Third Party Sites &amp; Ads are not
              under the control of Pelicargo and Pelicargo is not responsible for any Third Party Sites &amp; Ads.
              Pelicargo provides these Third Party Sites &amp; Ads only as a convenience and does not review, approve,
              monitor, endorse, warrant, or make any representations with respect to Third Party Sites &amp; Ads.
              Customer uses all Third Party Sites &amp; Ads at Customer's own risk. When Customer links to a Third Party
              Site &amp; Ad, the applicable third party's terms and policies apply, including the third party's privacy
              and data gathering practices. Customer should make whatever investigation Customer feels necessary or
              appropriate before proceeding with any transaction in connection with such Third Party Sites &amp; Ads.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">10. Change of Services</Heading>

            <Text fontSize="lg">
              Pelicargo reserves the right, at any time, to change, update, patch, modify, suspend, or discontinue the
              Service, pricing model, or any part thereof with or without notice. Customer agrees that Pelicargo will
              not be liable to Customer or to any third party for any change, update, patch, modification, suspension,
              or discontinuance of the Service or any part thereof. This Agreement shall continue to apply.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">11. Consent To Electronic Transaction, Communication and Disclosures</Heading>

            <Text fontSize="lg">
              To the fullest extent permitted by law, these Terms of Service and any other agreements, notices or other
              communications from Pelicargo to you regarding our services being offered (&quot;Communications&quot;) may
              be provided to you electronically, and you consent and agree to receive Communications in an electronic
              form. Electronic Communications may be posted on the pages within the Pelicargo platform and/or delivered
              to your email address.
            </Text>

            <Text fontSize="lg">
              You expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone calling on
              our behalf for any and all purposes, at any telephone number, or physical or electronic address you
              provide or at which you may be reached. You agree we may contact you in any way, including SMS messages
              (including text messages), calls using prerecorded messages or artificial voice, and calls and messages
              delivered using an auto telephone dialing system or an automatic texting system. Automated messages may be
              played when the telephone is answered, whether by you or someone else. In the event that an agent or
              representative calls, he or she may also leave a message on your answering machine, voice mail, or send
              one via text.
            </Text>

            <Text fontSize="lg">
              All Communications in electronic format will be considered to be “in writing” such that they
              electronically satisfy any legal requirement as if they were in a written, hardcopy form, and to have been
              received no later than five (5) Business Days after posting or dissemination, whether or not you have
              received or retrieved the Communication. Pelicargo reserves the right to provide Communications in paper
              format. Your consent to receive Communications electronically is valid until you revoke your consent by
              notifying us. If you revoke your consent to receive Communications electronically, we may terminate your
              right to use the Pelicargo Services, and you accept sole liability for any consequence resulting from
              suspension or termination of Pelicargo, to the extent permitted by law.
            </Text>

            <Text fontSize="lg">
              You may withdraw your consent to receive Communications electronically by contacting us in the manner
              described below. If you withdraw your consent, from that time forward; we may still send you relevant
              information about your account and any transaction detail carried out between you and other users on the
              Pelicargo platform. You further acknowledge and agree that opting out and withdrawing consent may impact
              your use of the Pelicargo service.
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">12. Release</Heading>
            <Text fontSize="lg">
              You hereby release Pelicargo, our Affiliates, and Pelicargo's respective officers, directors, agents,
              subsidiaries, joint ventures, employees and service providers from claims, demands, and damages (actual
              and consequential) of every kind and nature, known and unknown, arising out of or in any way connected
              with any dispute you have with another Customer, whether it be at law or in equity that exists as of the
              time you enter into this Agreement. You further acknowledge that Pelicargo is not a party to any contract
              between Customer and Suppliers for any reason. You also acknowledge that Pelicargo shall not be deemed to
              be an agent or a partner of a Customer or Supplier for any reason.
            </Text>

            <Text fontSize="lg">
              This release includes, for example, and without limitation, any disputes regarding the performance,
              functions, and quality of the Services provided by a Supplier and requests for refunds based upon
              disputes. This release will not apply to a claim made by Customer that Pelicargo failed to meet its
              obligations to Customer under the Terms of Service.{' '}
            </Text>
          </Stack>

          <Stack>
            <Heading size="lg">13. Limitation Of Liability</Heading>

            <Text fontSize="lg">
              TO THE EXTENT PERMITTED BY LAW, WE ASSUME NO RESPONSIBILITY, AND IN NO EVENT AND UNDER NO LEGAL OR
              EQUITABLE THEORY, WHETHER IN TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE SHALL PELICARGO OR ANY OF ITS
              EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, VENDORS OR SUPPLIERS BE LIABLE TO CUSTOMER, ANY THIRD PARTY, ANY
              SUPPLIER, OR ANY OTHER PERSON FOR ANY LOSS, CLAIM, DAMAGE, LOST PROFITS OR ANY SPECIAL, INCIDENTAL,
              INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO, OR
              INABILITY TO ACCESS, THE SITES, OR FROM YOUR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITES, OR ARISING
              FROM UNDERLYING TRANSPORTATION WHERE RATES WERE OBTAINED VIA THE SITES EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.{' '}
            </Text>

            <Text fontSize="lg">
              THE PELICARGO PLATFORM IS ONLY A VENUE FOR CONNECTING USERS. WE ARE NOT RESPONSIBLE FOR ASSESSING THE
              SUITABILITY, LEGALITY OR ABILITY OF ANY THIRD-PARTY SERVICE PROVIDER AND YOU EXPRESSLY WAIVE AND RELEASE
              PELICARGO FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES (ACTUAL, DIRECT OR CONSEQUENTIAL) OF EVERY KIND
              AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING FROM OR IN
              ANY WAY RELATED TO ANY THIRD-PARTY PROVIDER. WE EXPRESSLY DISCLAIM ANY LIABILITY THAT MAY ARISE BETWEEN
              YOU AND ANY THIRD-PARTY PROVIDER. THE QUALITY OF THE SERVICES SCHEDULED OR REQUESTED THROUGH THE USE OF
              THE PELICARGO PLATFORM IS ENTIRELY THE RESPONSIBILITY OF THE THIRD-PARTY SERVICE PROVIDER WHO ULTIMATELY
              PROVIDES SUCH SERVICES TO YOU. YOU UNDERSTAND, THEREFORE, THAT BY USING THE PELICARGO PLATFORM, YOU MAY BE
              EXPOSED TO SERVICES THAT ARE POTENTIALLY DANGEROUS, OFFENSIVE, HARMFUL, UNSAFE OR OTHERWISE OBJECTIONABLE,
              AND THAT YOU USE THE PELICARGO PLATFORM, AND THIRD-PARTY SERVICE PROVIDER, AT YOUR OWN RISK. NOTHING IN
              THIS AGREEMENT OR THE PELICARGO PLATFORM CONSTITUTES OR IS MEANT TO CONSTITUTE, ADVICE OF ANY KIND.{' '}
            </Text>

            <Text fontSize="lg">
              YOU ACKNOWLEDGE THAT THIRD PARTY PROVIDERS MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. YOU AGREE THAT
              WE HAVE NO RESPONSIBILITY OR LIABILITY TO YOU RELATING TO THE SERVICES PROVIDED TO YOU BY THIRD-PARTY
              SERVICE PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">14. Indemnification</Heading>

            <Text fontSize="lg">
              You hereby agree to indemnify, defend, and hold harmless Pelicargo, our Affiliates, and our respective
              directors, officers, employees, representatives, and agents (each an “Indemnified Party”) from and against
              any and all claims, losses, expenses, liabilities, damages or demands (including attorneys&#39; fees and
              costs incurred), in connection with or resulting from, directly or indirectly:{' '}
            </Text>

            <OrderedList>
              <ListItem>
                your use or misuse of or inability to use the Pelicargo Platform, and/or the Services,
              </ListItem>

              <ListItem>your violation of this Agreement,</ListItem>

              <ListItem>your violation of any applicable law or regulation;</ListItem>

              <ListItem>your violation of the rights of another;</ListItem>

              <ListItem>your negligence;</ListItem>

              <ListItem>
                reliance on or compliance with any information or instructions entered into the Pelicargo Platform by
                any User purporting to represent you, and
              </ListItem>

              <ListItem>
                your information and content that you submit or transmit through the Pelicargo Platform.
              </ListItem>
            </OrderedList>

            <Text fontSize="lg">
              Pelicargo reserves the right, at its own expense, to assume the exclusive defense and control of any
              matter otherwise subject to your indemnification. You will not, in any event, settle any such claim or
              matter without the prior written consent of Pelicargo.
            </Text>
          </Stack>

          <Stack>
            <Heading size="lg">15. Agreement Term and Termination</Heading>

            <Text fontSize="lg">
              Termination by Pelicargo: We may terminate this Agreement or terminate or suspend your Account immediately
              at any time for any reason or for no reason upon notice to you, at no cost to Pelicargo. If we terminate
              or suspend your Account, you are prohibited from registering and creating a new account under your name, a
              fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third
              party. In addition to terminating or suspending your Account, we reserve the right to pursue any available
              legal action. When terminating your Account, we may delete the Account and any data or information in it.
              You have no ownership rights to your Account. Without limiting Pelicargo's other rights or remedies, we
              may, but are not obligated to, temporarily or indefinitely revoke or limit access to the Site/Services,
              deny your registration, or permanently revoke your access to the Site and refuse to provide any or all
              Services to you if: (i) you breach the letter or spirit of any terms and conditions of this Agreement or
              any other provisions of the Terms of Service; (ii) we suspect or become aware that you have provided false
              or misleading information to us; (iii) we believe, in our sole discretion, that your actions may cause
              legal liability for you, our Users, or Pelicargo or our Affiliates; may be contrary to the interests of
              the Site or the User community; or may involve illicit or illegal activity; or (iv) we are required to by
              law, legal process, or law enforcement. If your Account is temporarily or permanently closed, you may not
              use the Site under the same Account or a different Account or re-register under a new Account without
              Pelicargo's prior written consent.{' '}
            </Text>

            <Text fontSize="lg">
              Termination by Customer: Customer may terminate this Agreement by written notice. If Customer terminates
              this Agreement while there are still outstanding Services ordered under your Account or any amounts are
              due and owing, this Agreement shall not terminate until such Services expire and all outstanding payments
              are made by Customer to Pelicargo.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">16. Governing Law and Dispute Resolution</Heading>
            <Text fontSize="lg">
              The Agreements (and any non-contractual disputes/claims arising out of or in connection with them) are
              subject to and construed in accordance with laws of the State of Delaware. The Courts of the State of
              Delaware shall have exclusive jurisdiction over any disputes arising out of these Terms.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">17. Survival</Heading>
            <Text fontSize="lg">
              After this Agreement terminates, the terms of this Agreement and the other Terms that expressly or by
              their nature contemplate performance, after this Agreement terminates or expires, will survive and
              continue in full force and effect. For example, the provisions permitting audits, protecting intellectual
              property, indemnification, payment of fees, reimbursement, and setting forth limitations of liability
              each, by their nature, contemplate performance or observance after this Agreement terminates. Without
              limiting any other provisions of the Terms, the termination of this Agreement for any reason will not
              release you or Pelicargo from any obligations incurred prior to termination of this Agreement or that
              thereafter may accrue in respect of any act or omission prior to such termination except where otherwise
              stated in this Agreement.{' '}
            </Text>
          </Stack>
          <Stack>
            <Heading size="lg">18. General</Heading>
            <Text fontSize="lg">
              a) Severability: If any provision of these Terms is determined by a court to be unlawful, unenforceable,
              or invalid as a whole, the parties agree that the court shall amend that provision to achieve as nearly as
              possible the same effect as the original provision and the remainder of these Terms will remain in effect.{' '}
            </Text>

            <Text fontSize="lg">
              b) Waiver: No delay or omission by Pelicargo to exercise any right occurring upon any noncompliance on
              your part with respect to any of the terms of this Terms shall impair any such right or power or be
              construed to be a waiver thereof. Any waiver by us of any of the covenants, conditions, or agreements to
              be performed by you shall not be construed to be a waiver of any succeeding breach thereof or of any
              covenant, condition, or agreement herein contained.{' '}
            </Text>
            <Text fontSize="lg">
              c) Assignability: A user may not assign these Terms, or any of its rights or obligations hereunder,
              without Pelicargo's prior written consent in the form of a written instrument signed by a duly authorized
              representative of Pelicargo. We may freely assign this Agreement and the other Terms without the User's
              consent. Any attempted assignment or transfer in violation of this subsection will be null and void.
              Subject to the foregoing restrictions, the Terms herein are binding upon and will inure to the benefit of
              the successors, heirs, and permitted assigns of the parties{' '}
            </Text>

            <Text fontSize="lg">
              d) Updates to these Terms: The Terms herein are subject to change as we may from time to time make
              important revisions to these terms. Any changes to these Terms will be posted on the Service without prior
              notice to you. All accessing, browsing, using, framing, and/or linking to Sites is subject to the
              provisions of this Terms, as amended.{' '}
            </Text>

            <Text fontSize="lg">
              The contents of this website may include technical inaccuracies or typographical errors. We may publish
              changes to the contents at any time without prior notice to you. We may also make improvements and/or
              changes in the products and services and/or the features described herein at any time without prior notice
              to you. We shall have the right to revise and edit these contents at any time. By linking, accessing,
              browsing, and/or using this site, you agree to be bound by any such revisions.{' '}
            </Text>

            <Text fontSize="lg">
              e) Force Majeure: The parties to this Agreement will not be responsible for the failure to perform, or any
              delay in performance of, any obligation hereunder for a reasonable period due to labor disturbances,
              accidents, fires, floods, telecommunications or Internet failures, strikes, wars, riots, rebellions,
              blockades, acts of government, governmental requirements and regulations or restrictions imposed by law or
              any other conditions beyond the reasonable control of such party.{' '}
            </Text>

            <Text fontSize="lg">
              f) Entire Agreement: These Terms with other policies presented to you, constitute the entire agreement
              between you and us with respect to the platform and its use and supersedes all prior agreements,
              proposals, communications between the parties, and understandings, whether oral or written.{' '}
            </Text>
            <Text fontSize="lg">
              g) Conflict: If there is any conflict or inconsistency between the terms and conditions set forth in this
              Agreement and the terms set forth in any other contract between the parties, the terms and conditions of
              this Agreement shall control.{' '}
            </Text>
            <Text fontSize="lg">
              h) Compliance with Law: Customer represents and warrants that it will use these Services in compliance
              with this Agreement, all applicable laws, rules and regulations of all relevant governmental authorities.{' '}
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
