import { useCallback, useEffect, useRef, useState } from 'react';

import { boot } from '../utils/frontchat';
import type { FrontChat, FrontChatOptions, FrontChatParams } from '../utils/types';

/*
 * Types.
 */

type Initialize = (params?: FrontChatParams) => void;

export interface UseFrontChatBootReturn {
  frontChat: FrontChat | undefined;
  isInitialized: boolean;
  initialize: Initialize | undefined;
}

enum FrontChatStatusesEnum {
  IDLE = 'idle',
  READY = 'ready',
  INITIALIZED = 'initialized',
}

/*
 * Hook.
 */

export const useFrontChatBoot = (element?: HTMLElement, options?: FrontChatOptions): UseFrontChatBootReturn => {
  const scriptTagAppended = useRef(false);

  const [status, setStatus] = useState<FrontChatStatusesEnum>(FrontChatStatusesEnum.IDLE);

  const loadScript = useCallback(async () => {
    if (scriptTagAppended.current) {
      return;
    }
    scriptTagAppended.current = true;

    try {
      await boot(element, options);
      setStatus(FrontChatStatusesEnum.READY);
    } catch (error) {
      console.error(error);
    }
  }, [element, options]);

  useEffect(() => {
    loadScript();
  }, [element, loadScript, options]);

  if (status === FrontChatStatusesEnum.IDLE) {
    return {
      frontChat: undefined,
      isInitialized: false,
      initialize: undefined,
    };
  }

  const frontChat: FrontChat = (cmdType, params) => {
    if (!window?.FrontChat) {
      console.error('[front-chat-sdk] Have not finished setting up window.FrontChat');
      return;
    }

    if (cmdType === 'init') {
      const onInitCompleted = () => {
        setStatus(FrontChatStatusesEnum.INITIALIZED);
      };

      return window?.FrontChat('init', {
        chatId: params?.chatId,
        useDefaultLauncher: true,
        userHash: params?.token,
        email: params?.email,
        name: params?.name,
        customFields: {
          requestId: params?.requestId,
        },
        onInitCompleted,
      });
    }

    if (cmdType === 'shutdown') {
      setStatus(FrontChatStatusesEnum.READY);
    }

    return window.FrontChat(cmdType, params);
  };

  const initialize = (params?: FrontChatParams) => {
    if (status === FrontChatStatusesEnum.INITIALIZED) {
      return;
    }

    frontChat('init', params);
  };

  return {
    frontChat,
    isInitialized: status === FrontChatStatusesEnum.INITIALIZED,
    initialize,
  };
};
