import { trpc } from '../../config/trpc';
import { MutateFunction, UseMutateResource } from '../../utils/types';
import { RequestInput, RequestOutput } from './useRequests';

type RequestMutateFunction = MutateFunction<RequestInput, RequestOutput>;
type UseCreateRequest = UseMutateResource<RequestMutateFunction>;

export const useCreateRequest = (): UseCreateRequest => {
  const { mutateAsync, isLoading, error } = trpc.createRequest.useMutation();

  const create: RequestMutateFunction = async (args) => mutateAsync(args as any);

  return [create, isLoading, error?.message];
};
