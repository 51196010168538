import { OnboardingStep } from '@pelicargo/types';

import { trpc } from '../../config/trpc';

export const useOnboarding = () => {
  const { mutateAsync: updateOnboardingStep } = trpc.updateOnboardingStep.useMutation();

  const handleUpdateOnboardingStep = async (step: OnboardingStep) => {
    try {
      await updateOnboardingStep({
        onboarding_step: step,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    setOnboardingStep: handleUpdateOnboardingStep,
  };
};
