import { useMemo } from 'react';
import TypesenseInstantsearchAdapter, { TypesenseInstantsearchAdapterOptions } from 'typesense-instantsearch-adapter';

import { trpc } from '../../config/trpc';

export const useTypesense = (config?: Partial<TypesenseInstantsearchAdapterOptions>) => {
  const { data } = trpc.getTypesenseConfigCustomer.useQuery(null, {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const typesense = useMemo(() => {
    if (!data) return;

    return new TypesenseInstantsearchAdapter({
      server: {
        apiKey: data?.apiKey,
        nodes: [
          {
            host: data?.host,
            port: Number(data?.port),
            protocol: data?.protocol,
          },
        ],
      },
      ...config,
      additionalSearchParameters: {
        query_by: '',
        per_page: 50,
        ...config?.additionalSearchParameters,
      },
    });
  }, [config, data]);

  return {
    searchClient: typesense?.searchClient,
  };
};
