import { Image, Input, Stack } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import SignatureLabelIcon from '../../assets/signature-label.svg';
import { SimpleField } from '../SimpleField';

type Props = {
  name: string;
  variant?: 'inline' | 'outline';
};

export const SimpleSignature = ({ name, variant }: Props) => {
  const { watch, setValue } = useFormContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value);
  };

  return (
    <SimpleField label="Type to Sign" variant={variant}>
      <Stack direction="row" align="center" spacing="4">
        <Image src={SignatureLabelIcon} h="40px" userSelect="none" />

        <Input
          name="signature"
          type="text"
          placeholder=""
          variant="unstyled"
          fontSize="4xl"
          fontStyle="italic"
          fontFamily="'Ephesis', cursive"
          w="full"
          value={watch(name)}
          onChange={handleChange}
        />
      </Stack>
    </SimpleField>
  );
};
