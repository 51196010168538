import { Button, Flex, Icon, IconButton, Stack, StackProps, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { HandlingOptions } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { ReactElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HiOutlinePlusCircle, HiTrash } from 'react-icons/hi2';

interface SimpleFieldArrayForm {
  prefix: string;
  isFirst: boolean;
  isLast: boolean;
  hasMultiple: boolean;
}

type SimpleFieldArrayProps = StackProps & {
  name: string;
  form: (props: SimpleFieldArrayForm) => ReactElement;
  onRemove?: (values: any) => void;
  ignoreStyles?: boolean;
  isFormInModal?: boolean;
  addButtonLabel?: string;
  maxDisplayedItems?: number;
};

export const SimpleFieldArray = ({
  name,
  form,
  onRemove,
  ignoreStyles = false,
  isFormInModal = false,
  addButtonLabel = 'Add Item',
  maxDisplayedItems,
  ...rest
}: SimpleFieldArrayProps) => {
  const { control } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name,
  });
  const { isOpen: isShowingAll, onOpen: showAll, onToggle } = useDisclosure({ defaultIsOpen: !maxDisplayedItems });

  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hasMultiple = fields.length > 1;

  const handleAddItem = () => {
    if (!isShowingAll) showAll();

    append({
      quantity: 1,
      weight: undefined,
      height: undefined,
      length: undefined,
      width: undefined,
      handling: HandlingOptions.Stackable,
      packing: [],
    });
  };

  const handleRemove = (index: number) => () => {
    onRemove?.(index);
    remove(index);
  };

  const renderItem = (field: any, index: number) => {
    const isFirst = index === 0;
    const isLast = index === fields.length - 1;

    return (
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        position="relative"
        key={field.id}
        pt={ignoreStyles ? 0 : isFirst ? 0 : 8}
        borderTop={ignoreStyles ? 0 : isFirst ? '' : '1px solid'}
        alignItems="center"
        borderTopColor={ignoreStyles ? null : borderColor}
      >
        <Stack spacing={ignoreStyles ? 0 : 8} direction="column" width="full">
          {form({
            prefix: `${name}.${index}`,
            isFirst,
            isLast,
            hasMultiple,
          })}
        </Stack>
        <IconButton
          position={{
            base: 'static',
            md: isFormInModal ? 'initial' : 'absolute',
          }}
          right={isFormInModal ? 'initial' : -12}
          size="sm"
          aria-label="Remove Row"
          variant="ghost"
          colorScheme="red"
          icon={<Icon as={HiTrash} fontSize={16} />}
          onClick={handleRemove(index)}
        />
      </Stack>
    );
  };

  return (
    <Stack direction="column" spacing={ignoreStyles ? 0 : 8} width="full" {...rest}>
      <Show if={isShowingAll}>{fields.map(renderItem)}</Show>
      <Show if={!isShowingAll}>{fields.slice(0, maxDisplayedItems).map(renderItem)}</Show>

      <Flex justify="space-between">
        <Button
          variant="link"
          textTransform="uppercase"
          fontWeight="500"
          color="offBlack"
          leftIcon={<HiOutlinePlusCircle />}
          onClick={handleAddItem}
          size="lg"
        >
          {addButtonLabel}
        </Button>

        <Show if={!!maxDisplayedItems && fields.length > maxDisplayedItems}>
          <Button
            variant="link"
            textTransform="uppercase"
            fontWeight="500"
            color="offBlack"
            onClick={onToggle}
            size="lg"
          >
            {isShowingAll ? 'Show Less' : 'Show More'}
          </Button>
        </Show>
      </Flex>
    </Stack>
  );
};
