import { Flex, RadioProps, ResponsiveValue, useMultiStyleConfig, useRadio } from '@chakra-ui/react';

type Props = RadioProps & {
  variant?: ResponsiveValue<string>;
  isVertical?: boolean;
};

// https://chakra-ui.com/docs/components/radio#custom-radio-buttons
export const Radio = ({ variant, isVertical = false, children, ...rest }: Props) => {
  const { getInputProps, getRadioProps, getLabelProps } = useRadio(rest);

  const style = useMultiStyleConfig('CustomRadio', { variant, isVertical });

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();

  return (
    <Flex
      as="label"
      width="full"
      {...getLabelProps()}
      sx={style.container}
      tabIndex={0}
      _focus={{
        boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
        outline: 'none',
        borderColor: 'blue.500',
      }}
    >
      {/* Need to use native input because ChakraUI was causing form validation issues */}
      <input {...inputProps} name={undefined} hidden />
      <Flex {...checkboxProps} sx={style.button}>
        {children}
      </Flex>
    </Flex>
  );
};
