import { trpc, TrpcInputs, TrpcOutputs } from '../../config/trpc';
import { MutateFunction, UseMutateResource } from '../../utils/types';

type RequestInput = TrpcInputs['updateCustomerRef'];
type RequestOutput = TrpcOutputs['updateCustomerRef'];

type MutateRequest = MutateFunction<RequestInput, RequestOutput>;
type UseUpdateRequest = UseMutateResource<MutateRequest>;

export const useUpdateCustomerRef = (id?: number): UseUpdateRequest => {
  const { mutateAsync, isLoading, error } = trpc.updateCustomerRef.useMutation();

  const mutate: MutateRequest = (args) => mutateAsync({ id, ...args } as RequestInput);

  return [mutate, isLoading, error?.message];
};
