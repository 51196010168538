import { Button, Stack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { trpc } from '../../config/trpc';
import { useScreenView } from '../../hooks/useScreenView';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  code: string;
};

const schema = z.object({
  code: z.string({ required_error: 'Code is required' }),
});

export const RegisterConfirm = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const toast = useToast({
    position: 'bottom',
    size: 'sm',
    duration: 3000,
    isClosable: true,
  });
  useScreenView(MixpanelEvent.ConfirmView);

  const { mutateAsync: verifyEmail } = trpc.verifyEmail.useMutation();

  const methods = useForm<Partial<FormValues>>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      code: params.get('code') || '',
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        trackEvent(MixpanelEvent.ConfirmSubmit);
        await verifyEmail({
          code: values.code,
        });
        navigate('/register/personal');
      } catch (error) {
        toast({
          title: 'Email could not be verified',
          description: error.message,
          status: 'error',
        });
      }
    },
    [navigate, toast, verifyEmail],
  );

  return (
    <Page title="Confirm" showHeader={false} showFooter={false}>
      <OnboardingLayout
        heading="Check email for code"
        subHeading="Please submit the confirmation code we sent to your email address to validate your email address."
      >
        <SimpleForm {...(methods as any)} onSubmit={handleSubmit}>
          <Stack>
            <SimpleInput name="code" label="Code" placeholder="000 000" variant="outline" />
          </Stack>

          <Button colorScheme="primary" size="lg" alignSelf="flex-end" type="submit">
            Submit
          </Button>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
