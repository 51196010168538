import { mode, type StyleFunctionProps } from '@chakra-ui/theme-tools';

const baseStyle = (props: StyleFunctionProps) => ({
  borderRadius: 'lg',
  boxShadow: 'none',
  p: '4',
  bg: 'bg.surface',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _hover: { borderColor: mode('gray.300', 'gray.600')(props) },
  _checked: {
    borderColor: mode('black', 'primary.200')(props),
  },
  _focus: {
    borderColor: mode('black', 'primary.200')(props),
  },
  _active: {
    borderColor: mode('black', 'primary.200')(props),
  },
});

export default {
  baseStyle,
};
