import { Input, InputProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { SimpleField, SimpleXProps } from './SimpleField';

export type SimpleInputProps<FormSchema> = SimpleXProps<FormSchema> &
  InputProps & {
    formatter?: (value: string) => string;
    inputProps?: InputProps;
  };

export const SimpleInput = <FormSchema,>({
  name,
  label,
  placeholder,
  helper,
  type = 'text',
  variant,
  formatter,
  maxLength,
  pattern,
  inputProps = {},
  ...rest
}: SimpleInputProps<FormSchema>) => {
  const { register, watch } = useFormContext();
  const formProps = register(name);

  const value = formatter ? formatter?.(watch(name)) : watch(name);

  return (
    <SimpleField name={name} helper={helper} label={label} variant={variant} {...rest}>
      <Input
        type={type}
        variant="unstyled"
        placeholder={placeholder}
        fontSize="md"
        step={type === 'number' ? 'any' : undefined}
        borderRadius="0"
        {...formProps}
        value={value || ''}
        maxLength={maxLength}
        pattern={pattern}
        _placeholder={{ color: 'text.placeholder' }}
        {...inputProps}
      />
    </SimpleField>
  );
};
