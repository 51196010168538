import { Checkbox, CheckboxGroup, IconButton, Stack, Switch, Text } from '@chakra-ui/react';
import { SelectOption } from '@pelicargo/types';
import { useCallback } from 'react';
import { HiChevronLeft } from 'react-icons/hi';

import { QuoteFilters } from '../../hooks/useQuoteFilters';

type Props = {
  filters: QuoteFilters;
  airlines?: SelectOption[];
  handleCheckboxFilterChange: (key: string) => (nextValue: string[]) => void;
  resetView: () => void;
};

export const AirlineFilter = ({ filters, airlines = [], handleCheckboxFilterChange, resetView }: Props) => {
  const handleChange = useCallback(() => {
    if (filters?.airlines?.length === airlines?.length) {
      handleCheckboxFilterChange('airlines')([]);
    } else {
      handleCheckboxFilterChange('airlines')(airlines.map((airline) => airline.value));
    }
  }, [filters, airlines, handleCheckboxFilterChange]);

  return (
    <Stack spacing="2" pt="2">
      <Stack direction="row" align="center" spacing="2">
        <IconButton
          aria-label="Go back"
          icon={<HiChevronLeft />}
          variant="link"
          size="sm"
          display={{ base: 'flex', md: 'none' }}
          minW="auto"
          onClick={resetView}
        />
        <Text textStyle="label">Airlines</Text>
      </Stack>
      <Stack direction="row" align="center" justify="space-between" w="full">
        <Text>Select all airlines</Text>
        <Switch size="sm" isChecked={filters?.airlines?.length === airlines?.length} onChange={handleChange} />
      </Stack>
      <Stack>
        <CheckboxGroup value={filters.airlines} onChange={handleCheckboxFilterChange('airlines')}>
          {airlines?.map((airline) => (
            <Checkbox key={airline?.value} value={airline?.value}>
              {airline?.label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Stack>
    </Stack>
  );
};
