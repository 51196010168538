import { Icon, useColorModeValue } from '@chakra-ui/react';

export const IconLanding = () => {
  return (
    <Icon w="24px" h="24px" fill="none">
      <path
        fill={useColorModeValue('black', 'white')}
        d="m6.3 12.477 13.212 3.54c1.213.327 2.418-.421 2.59-1.06.171-.637-.461-1.88-1.71-2.215l-5.469-1.471-4.971-9.595a.373.373 0 0 0-.376-.17l-1.195.307a.286.286 0 0 0-.22.307l.94 7.584c-1.334-.36-2.604-.7-3.694-.994L3.244 4.673a.324.324 0 0 0-.35-.157l-.784.2a.32.32 0 0 0-.228.305s-.088-.247.245 3.577c0 0 3.028 3.573 4.173 3.88ZM0 21.901v1.303h24V21.9H0Z"
      />
    </Icon>
  );
};
