import { defineStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// Button
export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: '500',
  },
  variants: {
    unselected: {
      borderBottomWidth: '2px',
      borderBottomColor: 'transparent',
      borderRadius: 0,
      fontSize: 'sm',
    },
    underline: {
      borderBottomWidth: '2px',
      borderBottomColor: 'secondary',
      borderRadius: 0,
      fontSize: 'sm',
    },
  },

  sizes: {
    lg: {
      h: '40px',
      px: 5,
    },
    xl: {
      h: '60px',
      px: 5,
    },
  },
});

const simpleFieldHelpers = createMultiStyleConfigHelpers(['form', 'label']);

export const SimpleField = simpleFieldHelpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    form: {
      bgColor: 'bg.surface',
      borderRadius: 'md',
      padding: props?.removePadding ? 0 : 4,
      transition: 'all 0.2s ease-in 0s',
      borderWidth: '1px',
      borderColor: props?.isInvalid ? 'error' : 'border.default',
      _hover: {
        bgColor: 'bg.surface',
        borderColor: 'border.hover',
      },
      _focus: {
        // borderColor: 'transparent',
        borderColor: 'border.focused',
      },
      _invalid: {
        borderColor: 'error',
      },
    },
  }),
  variants: {
    inline: {
      form: {
        background: 'transparent',
        p: '0',
        _hover: {
          background: 'unset',
        },
      },
    },
    outline: (props) => ({
      form: {
        borderRadius: 6,
        background: 'bg.surface',
        borderWidth: '1px',
        borderColor: props?.isInvalid ? 'error' : 'border.default',
        _hover: {
          borderColor: 'border.hover',
        },
        _focus: {
          borderColor: 'border.focused',
        },
        _invalid: {
          borderColor: 'red.500',
        },
      },
    }),
    unstyled: {
      form: {
        p: '0',
        borderWidth: '0',
        background: 'transparent',
        _hover: {
          background: 'transparent',
        },
      },
    },
  },
});

const radioHelpers = createMultiStyleConfigHelpers(['container', 'button']);

export const CustomRadio = radioHelpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    container: {
      color: 'black',
      borderRadius: 'md',
      '& > input:checked ~ div': {
        bg: 'gray.800',
        color: 'white',
        borderRadius: 'md',
      },
      _first: {
        '& > input:checked ~ div': {
          bg: 'gray.800',
          borderRadius: 'md',
          color: 'white',
        },
        borderRadius: 'md',
      },
      _last: {
        '& > input:checked ~ div': {
          bg: 'gray.800',
          borderRadius: 'md',
          color: 'white',
        },
        borderRadius: 'md',
      },
    },
    button: {
      cursor: 'pointer',
      px: 4,
      _checked: {
        color: 'white',
        fontWeight: '900',
      },
      alignItems: 'center',
      fontSize: 'lg',
      width: '100%',
      justifyContent: props?.isVertical ? 'flex-start' : 'space-around',
    },
  }),
  variants: {
    inline: {
      container: {
        bg: 'white',
        color: 'black',
        _first: {
          '& > input:checked ~ div': {
            bg: 'gray.800',
            borderLeftRadius: 'md',
            color: 'white',
          },
          borderLeftRadius: 'md',
        },
        _last: {
          '& > input:checked ~ div': {
            bg: 'gray.800',
            borderRightRadius: 'md',
            color: 'white',
          },
          borderRightRadius: 'md',
        },
      },
    },
    outline: {
      container: {
        bg: 'gray.200',
        color: 'black',
        _first: {
          '& > input:checked ~ div': {
            bg: 'gray.800',
            borderLeftRadius: 'md',
            color: 'white',
          },
          borderLeftRadius: 'md',
        },
        _last: {
          bg: 'gray.200',
          '& > input:checked ~ div': {
            bg: 'gray.800',
            borderRightRadius: 'md',
            color: 'white',
          },
          borderRightRadius: 'md',
        },
      },
    },
  },
});

const tooltipHelpers = createMultiStyleConfigHelpers(['icon', 'content']);

export const SimpleTooltip = tooltipHelpers.defineMultiStyleConfig({
  baseStyle: {
    icon: {
      position: 'absolute',
      right: '0.25em',
      top: '0.25em',
    },
    content: {
      fontSize: 'md',
      bg: 'gray.800',
      color: 'white',
      padding: '0.25rem',
    },
  },
  variants: {
    inline: {
      icon: {
        position: 'unset',
        marginLeft: '0.5rem',
      },
    },
  },
});

const tableHelpers = createMultiStyleConfigHelpers(['table', 'thead', 'tbody', 'tr', 'th', 'td']);

export const Table = tableHelpers.defineMultiStyleConfig({
  baseStyle: {
    table: {},
    th: {},
    td: {
      whiteSpace: 'nowrap',
    },
  },
});
