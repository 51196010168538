import { Heading, Stack, Text } from '@chakra-ui/react';

import { Page } from '../../components/Page';

export const PaySuccess = () => {
  return (
    <Page title="Payment Successful">
      <Stack spacing={4} h="80vh" align="center" justify="center">
        <Heading textAlign="center">Thank you for your payment</Heading>
        <Text textAlign="center" size="md">
          Your payment was successful. You will receive an email confirmation shortly. Welcome to Pelicargo!
        </Text>
      </Stack>
    </Page>
  );
};
