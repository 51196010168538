import { Input } from '@chakra-ui/react';
import { ChangeEvent, forwardRef, useCallback, useEffect } from 'react';
import { useSearchBox } from 'react-instantsearch-core';
import { useDebounce } from 'usehooks-ts';

type Props = {
  query: string;
  setQuery: (query: string) => void;
  isOrigin: boolean;
};

export const RoutePickerSearchBox = forwardRef(({ query, setQuery, isOrigin }: Props, ref: any) => {
  const { refine } = useSearchBox();

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    refine?.(debouncedQuery);
  }, [debouncedQuery, refine]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery],
  );

  return (
    <Input
      ref={ref}
      placeholder={!isOrigin ? 'Where to?' : 'Where from?'}
      _placeholder={{
        color: 'text.placeholder',
      }}
      variant="unstyled"
      value={query}
      onChange={handleSearchChange}
      autoComplete="off"
    />
  );
});
