import { RequestStatus, SelectOption } from '@pelicargo/types';
import { differenceInHours } from 'date-fns';
import { getCountries } from 'libphonenumber-js';

import { API } from './apiTypes';

export type Airport = API.Requests[0]['origin_airports'][0];
export type Cargo = API.Requests[0]['cargo'][0];

export const formatOriginAirports = (airports: Airport[]) => {
  return airports?.map(({ airport_as_origin_id }) => airport_as_origin_id).join(', ');
};

export const formatDestinationAirports = (airports: Airport[]) => {
  return airports?.map(({ airport_as_destination_id }) => airport_as_destination_id).join(', ');
};

export const formatCargoQuantity = (cargos: Cargo[]) => {
  return cargos.reduce((previous, current) => previous + current.quantity, 0).toString();
};

export const translateRequestStatusFromOptions = (values: SelectOption[]) => {
  if (!values.length) return;

  // Translate the statuses from customer facing to the RequestStatus enum so it makes sense to our backend
  const rawTranslateStatuses = values.flatMap(({ value }) => {
    if (value === 'InProgress') {
      return [
        RequestStatus.New,
        RequestStatus.InfoPending,
        RequestStatus.QuotingPending,
        RequestStatus.QuotingInProgress,
        RequestStatus.QuotingComplete,
      ];
    }
    if (value === 'Booked') {
      return [RequestStatus.BookingPending, RequestStatus.BookingRequested, RequestStatus.BookingComplete];
    }
    if (value === 'Cancelled') return [RequestStatus.Cancelled];
  });

  // Remove duplicates
  const cleanTranslatedStatuses = [...new Set(rawTranslateStatuses)];

  return cleanTranslatedStatuses;
};

export const getCountryCodes = () => getCountries();

export const getLayoverTime = (
  flightPath: API.FlightSegment[],
  flightSegment: API.FlightSegment,
): string | undefined => {
  if (!flightPath) return;

  const currIndex = flightPath?.findIndex((segment) => segment.id === flightSegment.id);

  if (currIndex <= 0) return;

  const lastIndex = currIndex - 1;
  const lastFlightArrives = new Date(flightPath[lastIndex]?.arrives_at);
  const currFlightDeparts = new Date(flightPath[currIndex]?.departs_at);

  if (lastFlightArrives && currFlightDeparts) {
    const layover = differenceInHours(currFlightDeparts, lastFlightArrives).toString();
    const isPlural = layover === '1' ? '' : 's';

    return `${layover}hr${isPlural} layover time`;
  }
};
