export enum DayType {
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  DISABLE = 'DISABLE',
  ACTIVE = 'ACTIVE',
}

export type DayStyleConfig = {
  fontSize: string;
  size: string | number;
  activeBgColor: string;
  activeColor: string;
};

export const defaultDayStyle: DayStyleConfig = {
  fontSize: 'md',
  size: 10,
  activeBgColor: 'primary.500',
  activeColor: 'white',
};
