import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { HiMagnifyingGlass } from 'react-icons/hi2';

type Props = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const DataTableSearch = ({ value, onChange }: Props) => {
  return (
    <InputGroup maxW="sm" size="sm">
      <InputLeftElement pointerEvents="none" color="gray.600">
        <Icon as={HiMagnifyingGlass} />
      </InputLeftElement>
      <Input placeholder="Search" w="full" value={value} onChange={onChange} />
    </InputGroup>
  );
};
