import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { BatteryType, CargoType, WeightUnit } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  batteryTypeOptions,
  cargoCoolingOptions,
  cargoTemperatureRangeOptions,
  customerCargoTypeOptions,
  dimensionUnitOptions,
  ensureMetricCargo,
  getGrossWeight,
  getVolumetricWeight,
  kgToLb,
  prettyNumber,
  Units,
  weightUnitOptions,
} from '@pelicargo/utils';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { HiInformationCircle } from 'react-icons/hi2';
import { useSessionStorage } from 'usehooks-ts';

import { getCommodities } from '../../constants/commodities';
import { PER_ITEM_OPTIONS, SHIPPER_OPTIONS } from '../../constants/options';
import { RequestFormValues, useRequestForm } from '../../hooks/request/useForm';
import { API } from '../../utils/apiTypes';
import { cargoWarnings } from '../../utils/cargoWarnings';
import { CargoListItem } from '../CargoListItem';
import { LabeledValue } from '../LabeledValue';
import { SimpleDatePickerInput } from '../SimpleDatePickerInput';
import { SimpleFieldArray } from '../SimpleFieldArray';
import { SimpleForm } from '../SimpleForm';
import { SimpleInput } from '../SimpleInput';
import { SimpleRoutePicker } from '../SimpleRoutePicker';
import { SimpleSelect } from '../SimpleSelect';
import { SimpleSwitch } from '../SimpleSwitch';
import { DangerousGoodsForm } from './DangerousGoodsForm';

const KnownShipperTooltip = () => {
  return (
    <Stack>
      <Text>Is the shipper a TSA Known Shipper? Link:</Text>
      <Link target="popup" href="https://www.tsa.gov/for-industry/cargo-screening-program" textDecoration="underline">
        https://www.tsa.gov/for-industry/cargo-screening-program
      </Link>
    </Stack>
  );
};

const isCommodityRequired = (values: RequestFormValues, length: number, width: number, height: number, cw: number) => {
  return length >= 318 || width >= 244 || height >= 162 || cw >= 2000 || values?.cargo_type !== CargoType.GeneralCargo;
};

type SimpleRequestFormProps = {
  isUpdate?: boolean;
  submitLabel?: string;
  ingestedRequest?: Partial<RequestFormValues>;
  onSubmit?: (request: Partial<RequestFormValues>) => void;
};

export const SimpleRequestForm = memo(
  ({ submitLabel = 'Search Deals', onSubmit, ingestedRequest }: SimpleRequestFormProps) => {
    const [request] = useSessionStorage('request', {} as Partial<RequestFormValues>);
    const { methods } = useRequestForm();

    useEffect(() => {
      if (ingestedRequest) methods.reset(ingestedRequest);
    }, [ingestedRequest]);

    const cargo = useWatch({ control: methods.control, name: 'cargo' });
    const cargoType = useWatch({ control: methods.control, name: 'cargo_type' });
    const cargoDetails = useWatch({ control: methods.control, name: 'cargo_details' });
    const totalPieces = useWatch({ control: methods.control, name: 'total_pieces' });
    const grossWeight = useWatch({ control: methods.control, name: 'gross_weight' });
    const volumetricWeight = useWatch({ control: methods.control, name: 'volumetric_weight' });
    const dimensionUnit = useWatch({
      control: methods.control,
      name: 'original_dimension_unit',
      defaultValue: request?.original_dimension_unit,
    });
    const weightUnit = useWatch({
      control: methods.control,
      name: 'original_weight_unit',
      defaultValue: request?.original_weight_unit,
    });

    const isGrossWeight = cargoDetails === 'Gross Weight';

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState<string>('');

    const handleOpenTooltip = () => setIsTooltipOpen(true);
    const handleCloseTooltip = () => setIsTooltipOpen(false);
    const handleToggleTooltip = () => setIsTooltipOpen((prev) => !prev);

    const units = useMemo(() => ({ dimensionUnit, weightUnit }), [dimensionUnit, weightUnit]);

    const checkWarnings = (cargo: API.CargoItem[], units: Units, gw: number, vw: number) => {
      for (const cargoWarning of cargoWarnings) {
        const normalizedCargo = cargo.map((c) => ({
          ...c,
          length: Number(c.length),
          width: Number(c.width),
          height: Number(c.height),
          quantity: Number(c.quantity),
        }));

        if (cargoWarning.check(normalizedCargo, units, gw, vw)) return cargoWarning.message;
      }
      return '';
    };

    useEffect(() => {
      const handle = setTimeout(() => {
        // Ensure the cargo is in metric for our calculations
        const metricCargo = cargo?.map((c) => ensureMetricCargo(c, units));
        const weightShouldBeMetric = units.weightUnit === WeightUnit.kg;

        // Count the total number of pieces
        const totalPieces = cargo?.reduce((a, n) => a + Number(n.quantity), 0);
        methods.setValue('total_pieces', totalPieces);

        const mGw = metricCargo?.reduce((a, n) => a + getGrossWeight(n), 0);
        const gw = weightShouldBeMetric ? mGw : kgToLb(mGw);

        // Customer's can enter gross weight manually, or we can calculate it.
        // If GW is 0, we want to make sure the input has no value but shows the placeholder
        if (!isGrossWeight) methods.setValue('gross_weight', gw > 0 ? gw : null);

        // Calculate Volumetric Weight
        const mVw = metricCargo?.reduce((a, n) => a + getVolumetricWeight(n, { ceil: false }), 0);
        const vw = weightShouldBeMetric ? mVw : kgToLb(mVw);

        methods.setValue('volumetric_weight', vw);
        const warning = checkWarnings(cargo, units, gw, vw);
        setWarningMessage(warning);
      }, 250);

      // Cleanup on effect re-run or component unmount
      return () => clearTimeout(handle);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargo, isGrossWeight, units]);

    const resetFields = useCallback(
      (fields: string[]) => {
        fields.forEach((field: any) => methods.setValue(field, ''));
      },
      [methods],
    );

    const handleCargoTypeChange = () => {
      resetFields([
        'battery_type',
        'battery_type_variant',
        'un_number',
        'class',
        'packing_group',
        'aircraft_variant',
        'packing_instruction',
        'section',
        'cargo_temperature_range',
        'cargo_cooling',
        'commodity',
      ]);
    };

    const handleBatteryTypeChange = (nextValue: BatteryType) => {
      if (nextValue === BatteryType.DG_BATTERIES_IN_EQUIPMENT) {
        resetFields(['battery_type_variant']);
        methods.setValue('commodity', 'DG Batteries');
      }
      if (nextValue === BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT) {
        resetFields(['un_number', 'class', 'packing_group', 'aircraft_variant', 'packing_instruction', 'section']);
        methods.setValue('commodity', 'Non-Restricted Batteries in Equipment');
      }
    };

    const handleSubmit = useCallback(
      (values: RequestFormValues) => {
        // Validate the size of the shipment and confirm if the commodity is required

        const calculateDimensions = (cargo) =>
          cargo.reduce(
            (acc, c) => ({
              length: Math.max(acc.length, c.length),
              width: Math.max(acc.width, c.width),
              height: Math.max(acc.height, c.height),
              grossWeight: acc.grossWeight + getGrossWeight(c),
            }),
            { length: 0, width: 0, height: 0, grossWeight: 0 },
          );

        const inMetric = values?.cargo?.map((c) => ensureMetricCargo(c, units)) || [];
        const {
          length: greatestLength,
          width: greatestWidth,
          height: greatestHeight,
          grossWeight: calcGw,
        } = calculateDimensions(inMetric);

        const gw = isGrossWeight ? values?.gross_weight : calcGw;
        const cw = Math.max(values?.volumetric_weight, gw);

        const requiresCommodity = isCommodityRequired(values, greatestLength, greatestWidth, greatestHeight, cw);

        // Ensure commodity is set if required

        if (requiresCommodity && !values?.commodity && cargoType !== CargoType.Batteries) {
          const message = 'The actual commodity is required for this shipment.';
          return methods.setError('commodity', { message });
        }

        onSubmit?.(values);
      },
      [isGrossWeight, cargoType, onSubmit, units, methods],
    );

    return (
      <SimpleForm onSubmit={handleSubmit} {...(methods as UseFormReturn<any, any>)}>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(10, 1fr)' }} gap={4}>
          <GridItem colSpan={{ base: 1, lg: 6 }}>
            <SimpleRoutePicker />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 4 }}>
            <SimpleDatePickerInput name="dropoff_by" label="Expected Drop Off" />
          </GridItem>
        </Grid>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(10, 1fr)' }} gap={4}>
          <GridItem colSpan={{ base: 1, lg: 6 }}>
            <Grid templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' }} gap={4}>
              <GridItem colSpan={{ base: 1, lg: 3 }}>
                <SimpleSelect
                  name="cargo_type"
                  label="Cargo Type"
                  placeholder="General Cargo"
                  options={customerCargoTypeOptions}
                  onChangeCallback={handleCargoTypeChange}
                />
              </GridItem>
              <GridItem colSpan={{ base: 1, lg: 2 }} position="relative" h="full">
                <SimpleSwitch
                  name="is_known_shipper"
                  variant="outline"
                  label="Known Shipper"
                  options={SHIPPER_OPTIONS}
                  tooltip={<KnownShipperTooltip />}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 4 }}>
            <Show if={cargoType === CargoType.Batteries}>
              <SimpleSelect
                name="battery_type"
                label="Battery Type"
                placeholder="Battery Type"
                options={batteryTypeOptions}
                onChangeCallback={handleBatteryTypeChange}
              />
            </Show>
            <Show if={cargoType !== CargoType.Batteries}>
              <SimpleSelect
                name="commodity"
                label="commodity"
                placeholder="Commodity"
                options={getCommodities(cargoType)}
              />
            </Show>
          </GridItem>
        </Grid>

        <Show if={cargoType === CargoType.Perishable}>
          <Grid templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(10, 1fr)' }} gap={4}>
            <GridItem colSpan={{ base: 1, lg: 5 }}>
              <SimpleSelect
                name="cargo_temperature_range"
                label="Temperature Range"
                options={cargoTemperatureRangeOptions}
              />
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 5 }}>
              <SimpleSelect name="cargo_cooling" label="Cooling" options={cargoCoolingOptions} />
            </GridItem>
          </Grid>
        </Show>

        <Show if={cargoType === CargoType.DangerousGoods || cargoType === CargoType.Batteries}>
          <DangerousGoodsForm />
        </Show>

        <Heading variant="h6" zIndex={0}>
          Cargo Details
        </Heading>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ base: 'flex-start', lg: 'center' }}
          justify="space-between"
          spacing="4"
          w="full"
        >
          <Stack direction="row" align="center" position="relative">
            <SimpleSwitch variant="inline" fieldVariant="inline" name="cargo_details" options={PER_ITEM_OPTIONS} />
            <Tooltip
              hasArrow
              placement="bottom"
              label="Use Weight Per Piece when the weight should be multiplied by # of pieces in each line. Use Gross Weight when the weight entered is the gross weight of all items in each line."
              bg="gray.800"
              color="white"
              isOpen={isTooltipOpen}
            >
              <IconButton
                height="auto"
                minW="auto"
                aria-label="Toggle tooltip"
                variant="unstyled"
                icon={<HiInformationCircle />}
                onMouseOver={handleOpenTooltip}
                onMouseLeave={handleCloseTooltip}
                onClick={handleToggleTooltip}
              />
            </Tooltip>
          </Stack>

          <Stack direction="row" align="center" justify={{ base: 'flex-start', lg: 'flex-end' }} w="full">
            <Text zIndex={0}>Units</Text>
            <SimpleSwitch
              variant="inline"
              fieldVariant="inline"
              name="original_dimension_unit"
              spacing="0"
              options={dimensionUnitOptions}
            />
            <SimpleSwitch
              variant="inline"
              fieldVariant="inline"
              name="original_weight_unit"
              spacing="0"
              options={weightUnitOptions}
            />
          </Stack>
        </Stack>

        <SimpleFieldArray
          name="cargo"
          ignoreStyles
          spacing={4}
          isFormInModal={submitLabel === 'Update'}
          addButtonLabel="Add More Cargo"
          maxDisplayedItems={3}
          form={({ prefix }) => <CargoListItem prefix={prefix} dimensionUnit={dimensionUnit} weightUnit={weightUnit} />}
        />

        <Show if={!!warningMessage}>
          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <AlertDescription>{warningMessage}</AlertDescription>
          </Alert>
        </Show>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(25, 1fr)' }} gap={4} pt="4">
          <GridItem colSpan={{ base: 1, lg: 5 }}>
            <LabeledValue label="Total Pieces" value={totalPieces || '-'} spacing="2" />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 6 }}>
            <Show if={isGrossWeight}>
              <SimpleInput
                name="gross_weight"
                placeholder="Gross Weight"
                label={`Gross Weight (${weightUnit})`}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
            </Show>
            <Show if={!isGrossWeight}>
              <LabeledValue
                label={`Gross Weight (${weightUnit})`}
                value={prettyNumber(grossWeight || 0) || '-'}
                spacing="2"
              />
            </Show>
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 6 }}>
            <LabeledValue
              label={`Vol. Weight (${weightUnit})`}
              value={prettyNumber(volumetricWeight || 0) || '-'}
              spacing="2"
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 8 }}>
            <Button type="submit" colorScheme="primary" size="xl" w="full">
              {submitLabel}
            </Button>
          </GridItem>
        </Grid>
      </SimpleForm>
    );
  },
);
