import { Box, Card, CardBody, CardProps, Divider, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { useCallback, useMemo } from 'react';

import { SingleFlightPath } from '../SingleFlightPath';

type FlightSegment = {
  origin: string;
  destination: string;
  aircraftType: string;
};

type Props = CardProps & {
  flightSegments: FlightSegment[];
  estimatedTransitTime?: string;
  showHeader?: boolean;
  airlineName?: string;
  airlineLogoUri?: string;
  originAirportId?: string;
  destinationAirportId?: string;
};

export const FlightSegmentsCard = ({
  flightSegments = [],
  estimatedTransitTime,
  airlineName,
  airlineLogoUri,
  originAirportId,
  destinationAirportId,
  ...rest
}: Props) => {
  const renderFlightSegment = useCallback((segment: FlightSegment, index: number) => {
    return (
      <Stack key={index} direction="row" align="center" justify="space-between" spacing="8" w="auto">
        <SingleFlightPath from={segment?.origin} to={segment?.destination} />
        <Text textStyle="captionRegular" minW="20">
          {segment?.aircraftType}
        </Text>
        <Stack direction="row" align="center" justify="flex-end" w="full">
          {/* Scheduling */}
        </Stack>
      </Stack>
    );
  }, []);

  const stopCount = useMemo(() => flightSegments.length - 1, [flightSegments]);

  const stopCountLabel = useMemo(() => {
    if (stopCount === 0) {
      return 'Nonstop';
    }
    if (stopCount === 1) {
      return '1 Stop';
    }
    return `${stopCount} Stops`;
  }, [stopCount]);

  return (
    <Card h="auto" {...rest} px="4" py="3" borderRadius="lg">
      <CardBody w="full" p="0">
        <Stack h="full" justify="space-between">
          <Stack>
            <Show if={!!(airlineLogoUri && airlineName)}>
              <Stack spacing="2" pt="3">
                <Stack direction="row" align="center" spacing="6">
                  <Image src={airlineLogoUri} height="48px" width="48px" alt={airlineName} />
                  <Heading variant="h4">
                    {originAirportId}{' '}
                    <Box as="span" color="gray.400">
                      →
                    </Box>{' '}
                    {destinationAirportId}
                  </Heading>
                  <Text textStyle="bodySmallRegular">{stopCountLabel}</Text>
                </Stack>
                <Text textStyle="captionSmallRegular">{airlineName}</Text>
              </Stack>
            </Show>
            <Show if={!!(airlineLogoUri && airlineName)}>
              <Divider />
            </Show>
            <Stack direction="row">
              <Stack spacing="8" w="auto">
                {flightSegments?.map(renderFlightSegment)}
              </Stack>
            </Stack>
          </Stack>
          <Show if={!!estimatedTransitTime}>
            <Stack justify="flex-end" align="flex-end">
              <Text textStyle="bodySmallRegular" align="left">
                Estimated Transit: {estimatedTransitTime}
              </Text>
            </Stack>
          </Show>
        </Stack>
      </CardBody>
    </Card>
  );
};
