import { Button, Stack, Text, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { blackListedEmails } from '@pelicargo/utils';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';

import { AuthLayout } from '../../components/AuthLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimplePasswordInput } from '../../components/SimplePasswordInput';
import { trpc } from '../../config/trpc';
import { useScreenView } from '../../hooks/useScreenView';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';
import { normalizeEmail } from '../../utils/normalizeEmail';
import { supabaseClient } from '../../utils/supabaseClient';

type FormValues = {
  email: string;
  password: string;
  confirm_password: string;
};

const schema = z
  .object({
    email: z
      .string({ required_error: 'Email is required' })
      .email()
      .refine((value) => {
        const domain = value.split('@')[1];
        return !blackListedEmails.includes(domain);
      }, 'Please enter a work email address to continue'),
    password: z.string({ required_error: 'Password is required' }).min(8, 'Password must have at least 8 characters.'),
    confirm_password: z.string({
      required_error: 'Password confirmation is required',
    }),
  })
  .superRefine(({ password, confirm_password }, ctx) => {
    if (password !== confirm_password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Passwords must match',
        path: ['confirm_password'],
      });
    }
  });

export const RegisterSignup = () => {
  const navigate = useNavigate();
  const toast = useToast();
  useScreenView(MixpanelEvent.SignupView);

  const { mutateAsync: register } = trpc.register.useMutation();
  const [onboardingEmail, setOnboardingEmail] = useLocalStorage('onboardingEmail', null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
    },
  });

  useEffect(() => {
    if (onboardingEmail) {
      methods.setValue('email', onboardingEmail);
    }
  }, [methods, onboardingEmail]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        trackEvent(MixpanelEvent.SignupSubmit);
        const email = normalizeEmail(values.email);
        setIsSubmitting(true);
        setOnboardingEmail(email);
        await register({
          email,
          password: values.password,
        });

        const { error } = await supabaseClient.auth.signUp({
          email,
          password: values.password,
        });

        if (error) {
          throw new Error(error.message);
        }

        navigate('/register/confirm');
      } catch (error) {
        toast({
          title: 'User could not be created',
          description: error.message,
          status: 'error',
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate, register, setOnboardingEmail, toast],
  );

  return (
    <Page title="Register" showHeader={false} showFooter={false}>
      <AuthLayout heading="Get started for free">
        <SimpleForm {...methods} onSubmit={handleSubmit}>
          <Stack spacing="8">
            <Stack spacing="3">
              <SimpleInput name="email" label="Email" placeholder="Email" variant="outline" />
              <SimplePasswordInput
                name="password"
                label="Create Password"
                placeholder="Create Password"
                variant="outline"
                w="full"
              />
              <SimplePasswordInput
                name="confirm_password"
                label="Confirm Password"
                placeholder="Confirm Password"
                variant="outline"
                w="full"
              />
            </Stack>

            <Button colorScheme="primary" size="lg" w="full" type="submit" isLoading={isSubmitting}>
              Continue
            </Button>
          </Stack>
        </SimpleForm>
        <Stack direction="row" align="center" justify="center" wrap="wrap" w="full" spacing="1">
          <Text fontSize="md">Already have an account?</Text>
          <Link to="/login">
            <Button variant="link" colorScheme="primary" w="auto">
              Log in
            </Button>
          </Link>
        </Stack>
      </AuthLayout>
    </Page>
  );
};
