import { Box, BoxProps, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { AircraftType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { join } from '@pelicargo/utils';
import { FaTruck } from 'react-icons/fa';
import { IoAirplane } from 'react-icons/io5';

import { FlightData, IFlightLayover, IFlightLeg, IFlightStop } from '../../hooks/useFlightData';

export const FlightStop = ({
  date,
  time,
  showDateTime,
  airportName,
  airportId,
}: Omit<IFlightStop, 'type' | 'variant'>) => {
  return (
    <Stack direction="row" align="center" spacing="4" w="full">
      <Show if={!!showDateTime}>
        <Text textStyle="bodyMedium" minW="48px">
          {time}
        </Text>
        <Text textStyle="bodyRegular" minW="67px" color="text.subtle">
          {date}
        </Text>
      </Show>
      <Text textStyle="bodyRegular" noOfLines={1}>
        {airportName} ({airportId})
      </Text>
    </Stack>
  );
};

export const FlightLeg = ({
  flightDuration,
  airlineLogo,
  airlineId,
  flightNumber,
  aircraftType,
  aircraftName,
}: Omit<IFlightLeg, 'variant'>) => {
  return (
    <Stack direction="row" align="center" spacing="2" w="full">
      <Show if={!!flightDuration}>
        <Text textStyle="captionSmallRegular" color="text.subtle">
          {flightDuration}
        </Text>
        <Text textStyle="captionSmallRegular" color="text.subtle">
          •
        </Text>
      </Show>
      <Image h="24px" w="24px" src={airlineLogo} />
      <Text textStyle="captionSmallRegular" color="text.subtle">
        {airlineId} {flightNumber}
      </Text>
      <Text textStyle="captionSmallRegular" color="text.subtle">
        {join([aircraftType, aircraftName], ' - ')}
      </Text>
    </Stack>
  );
};

export const FlightLayover = ({ layoverDuration }: Omit<IFlightLayover, 'variant'>) => {
  return (
    <Stack direction="row" align="center" w="full">
      <Text textStyle="bodySmallRegular" color="text.default">
        {layoverDuration} layover
      </Text>
    </Stack>
  );
};

interface TimelineItemProps extends BoxProps {
  icon?: any;
  boxProps?: BoxProps;
  showCircle?: boolean;
  skipTrail?: boolean;
}

const TimelineItem = ({
  icon,
  boxProps = {},
  skipTrail = false,
  showCircle = false,
  children,
  ...props
}: TimelineItemProps) => {
  return (
    <Flex {...props}>
      <Flex flexDir="column" alignItems="flex-start" mr={4} pos="relative">
        <Show if={!!icon}>
          <Box as={icon} color="border.focused" fontSize="16px" ml="-6px" mt="-4px" mr="-3.5px" />
        </Show>
        <Show if={showCircle}>
          <Box
            h="8px"
            w="8px"
            bg="transparent"
            borderRadius="full"
            borderColor="border.default"
            borderWidth="2px"
            ml="-3px"
            mr="2px"
            boxSize="8px"
          />
        </Show>
        <Show if={!skipTrail}>
          <Box w="2px" flex={1} bg="border.default" my="0" />
        </Show>
      </Flex>
      <Box w="full" {...boxProps} mt="-2" ml={!showCircle && !icon ? '5px' : 0}>
        {children}
      </Box>
    </Flex>
  );
};

type Props = {
  data: FlightData[];
};

const getIcon = (data: FlightData) => {
  const isLeg = data?.variant === 'leg';
  if (!isLeg) return null;

  const isRfs = data?.aircraftType === AircraftType.RFS;

  if (isRfs) return FaTruck;
  return IoAirplane;
};

const getCircle = (data: FlightData, isFirstOrLast: boolean) => {
  const isLayover = data?.variant === 'layover';
  if (isLayover) return true;

  const isStop = data?.variant === 'stop';
  return isStop && isFirstOrLast;
};

export const Timeline = ({ data }: Props) => {
  const renderItem = (node: FlightData, index: number) => {
    const isFirstItem = index === 0;
    const isLastItem = index === data.length - 1;

    const icon = getIcon(node);
    const showCircle = getCircle(node, isFirstItem || isLastItem);

    return (
      <TimelineItem icon={icon} skipTrail={index === data.length - 1} showCircle={showCircle} minH={10} w="full">
        <Show if={node?.variant === 'stop'}>
          <FlightStop
            date={node?.date}
            time={node?.time}
            showDateTime={node?.showDateTime}
            airportName={node?.airportName}
            airportId={node?.airportId}
          />
        </Show>
        <Show if={node?.variant === 'leg'}>
          <FlightLeg
            flightDuration={node?.flightDuration}
            airlineLogo={node?.airlineLogo}
            airlineId={node?.airlineId}
            flightNumber={node?.flightNumber}
            aircraftType={node?.aircraftType}
            aircraftName={node?.aircraftName}
          />
        </Show>
        <Show if={node?.variant === 'layover'}>
          <FlightLayover layoverDuration={(node as IFlightLayover)?.layoverDuration} />
        </Show>
      </TimelineItem>
    );
  };

  return <Box>{data?.map(renderItem)}</Box>;
};
