import { AspectRatio, Heading, Stack, Text } from '@chakra-ui/react';

import { LatestBlogs } from '../../components/LatestBlogs';
import { Page } from '../../components/Page';
import { useAuth } from '../../hooks/auth/useAuth';

export const PendingVerification = () => {
  const { user } = useAuth();

  return (
    <Page title="Pending Verification" flex="1">
      <Stack alignItems="center">
        <Stack maxW="container.lg" p="8" spacing="8">
          <Stack spacing="4" align="center">
            <Heading>Welcome to Pelicargo, {user?.preferred_name}</Heading>
            <Stack align="center">
              <Text>Thank you for your interest in Pelicargo! Your account is currently undergoing verification.</Text>
              <Text fontWeight="medium">You will receive an email notification once the verification is complete.</Text>
            </Stack>
          </Stack>
          <AspectRatio ratio={16 / 9} maxW="container.lg">
            <iframe
              src="https://www.youtube.com/embed/aJBXV-fxgCI?si=kQ_FSD5_d00Unlbu&autoplay=0&controls=0&fullscreen=1"
              title="Pelicargo Overview Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ borderRadius: '8px' }}
            />
          </AspectRatio>
          <LatestBlogs
            heading="Latest articles by the team"
            subheading="Take a peek to see what we've been up to while you wait to be verified."
            max={3}
          />
        </Stack>
      </Stack>
    </Page>
  );
};
