import {
  Button,
  Divider,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Cargo, CargoType, DimensionUnit, WeightUnit } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  ensureDimensionUnit,
  getHandlingAndPacking,
  getPrettyCargoCooling,
  getPrettyCargoTempRange,
  getPrettyCargoType,
  getPrettyDimensionUnit,
  kgToLb,
  prettyNumber,
} from '@pelicargo/utils';
import { Fragment, useMemo, useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi2';

import { API } from '../../utils/apiTypes';
import { LabeledValue } from '../LabeledValue';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  cargoType: CargoType;
  isKnownShipper: boolean;
  commodity: string;
  grossWeight: number;
  volumetricWeight: number;
  cargo?: Cargo[];
  dimensionUnit: DimensionUnit;
  weightUnit: WeightUnit;
  origin: string;
  destination: string;
  dropOffBy: string;
  request: API.QuoteWithTotalCost['request'];
};

export const QuoteListingModal = ({
  isOpen,
  onClose,
  cargoType,
  isKnownShipper,
  commodity,
  grossWeight,
  volumetricWeight,
  cargo = [],
  dimensionUnit,
  weightUnit,
  origin,
  destination,
  dropOffBy,
  request,
}: Props) => {
  const [showCargo, setShowCargo] = useState(false);

  const handleToggle = () => {
    setShowCargo(!showCargo);
  };

  const isInLbs = weightUnit === WeightUnit.lb;

  const renderCargoItem = (item: Cargo) => {
    const width = ensureDimensionUnit(item?.width, dimensionUnit);
    const height = ensureDimensionUnit(item?.height, dimensionUnit);
    const length = ensureDimensionUnit(item?.length, dimensionUnit);

    return (
      <Stack key={item?.id} direction="row" align="center" spacing="4">
        <Text textStyle="captionRegular">{item?.quantity} Pcs</Text>
        <Divider orientation="vertical" h="4" borderWidth="1px" borderColor="border.default" />
        <Text textStyle="captionRegular">
          {prettyNumber(length)}x{prettyNumber(width)}x{prettyNumber(height)} {getPrettyDimensionUnit(dimensionUnit)}
        </Text>
        <Divider orientation="vertical" h="4" borderWidth="1px" borderColor="border.default" />
        <Show if={isInLbs}>
          <Tooltip
            label={`This weight has been converted from ${prettyNumber(kgToLb(item?.weight))} LBs to ${prettyNumber(item?.weight)} KGs`}
          >
            <Text textStyle="captionRegular">{prettyNumber(item?.weight)} kg</Text>
          </Tooltip>
        </Show>
        <Show if={!isInLbs}>
          <Text textStyle="captionRegular">{prettyNumber(item?.weight)} kg</Text>
        </Show>
        <Divider orientation="vertical" h="4" borderWidth="1px" borderColor="border.default" />
        <Text textStyle="captionRegular">{getHandlingAndPacking(item)}</Text>
      </Stack>
    );
  };

  const dangerousGoodsList = useMemo(() => {
    return [
      request?.un_number,
      request?.class,
      request?.packing_instruction,
      request?.packing_group,
      request?.aircraft_variant,
      request?.section,
    ].filter(Boolean);
  }, [
    request?.aircraft_variant,
    request?.class,
    request?.packing_group,
    request?.packing_instruction,
    request?.section,
    request?.un_number,
  ]);

  const perishableList = useMemo(() => {
    return [
      request?.commodity,
      getPrettyCargoCooling(request?.cargo_cooling),
      getPrettyCargoTempRange(request?.cargo_temperature_range),
    ].filter(Boolean);
  }, [request?.cargo_cooling, request?.cargo_temperature_range, request?.commodity]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', lg: '4xl' }} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader p={0}>
          <Heading px={6} py={3} as="h4">
            <Stack direction="row">
              <Text>{origin}</Text>
              <Text color="gray.400">→</Text>
              <Text>{destination}</Text>
            </Stack>
          </Heading>
        </ModalHeader>
        <ModalBody px={6}>
          <Stack spacing="2">
            <Stack spacing="6">
              <Stack direction="row" justify="flex-start" wrap="wrap" spacing="10">
                <LabeledValue label="Cargo Type" value={getPrettyCargoType(cargoType)} />
                <LabeledValue label="Commodity" value={commodity} />
                <LabeledValue label="Expected Drop-Off" value={dropOffBy} />

                <LabeledValue
                  label="Weight"
                  value={`Gross ${prettyNumber(grossWeight)} kg | Vol ${prettyNumber(volumetricWeight)} kg`}
                />
                <LabeledValue label="Known Shipper" value={isKnownShipper ? 'Yes' : 'No'} />
              </Stack>
              <Show if={cargoType === CargoType.DangerousGoods}>
                <Stack>
                  <Text textStyle="label">Dangerous Goods</Text>
                  <Stack direction="row" align="center" spacing="4">
                    {dangerousGoodsList?.map((node, index) => (
                      <Fragment key={node}>
                        <Text textStyle="captionRegular">{node}</Text>
                        <Show if={index < dangerousGoodsList.length - 1}>
                          <Divider orientation="vertical" h="4" borderWidth="1px" borderColor="border.default" />
                        </Show>
                      </Fragment>
                    ))}
                  </Stack>
                </Stack>
              </Show>
              <Show if={cargoType === CargoType.Perishable}>
                <Stack>
                  <Text textStyle="label">Perishable</Text>
                  <Stack direction="row" align="center" spacing="4">
                    {perishableList?.map((node, index) => (
                      <Fragment key={node}>
                        <Text textStyle="captionRegular">{node}</Text>
                        <Show if={index < perishableList.length - 1}>
                          <Divider orientation="vertical" h="4" borderWidth="1px" borderColor="border.default" />
                        </Show>
                      </Fragment>
                    ))}
                  </Stack>
                </Stack>
              </Show>
              <Stack>
                <Text textStyle="label">Cargo</Text>
                {cargo?.slice(0, showCargo ? cargo?.length : 3)?.map(renderCargoItem)}
              </Stack>
            </Stack>
            <Show if={cargo?.length > 3}>
              <Stack alignItems="center">
                <Button variant="ghost" onClick={handleToggle} maxW="min-content">
                  {showCargo ? 'View less' : 'View more'}
                </Button>
              </Stack>
            </Show>
          </Stack>
        </ModalBody>
        <ModalFooter px={6} py={4}>
          <Stack direction="row" align="center" spacing="4" p="4" borderRadius="md" bg="gray.800">
            <Icon as={HiInformationCircle} color="white" boxSize="6" />
            <Text fontSize="sm" color="white">
              If you wish to edit/adjust your cargo information, please go back to listing. If you've already submitted
              your booking request, please contact Pelicargo through our customer service chatbot.
            </Text>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
