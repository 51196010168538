const SHIPPER_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const PER_ITEM_OPTIONS = [
  { label: 'Weight Per Piece', value: 'Weight Per Piece' },
  { label: 'Gross Weight', value: 'Gross Weight' },
];

export { PER_ITEM_OPTIONS, SHIPPER_OPTIONS };
