import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle(() => {
  return {
    container: {
      h: 'full',
      w: 'full',
      borderWidth: '1px',
      borderColor: 'border.default',
    },
  };
});

export default defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'outline',
  },
});
