import { createClient } from '@supabase/supabase-js';

import { ENV } from '../config/env';

// Github actions fails if there's no env var. This will detect if it's in a CI and toss in a placeholder
const [ciUrl, ciKey] = ENV.CI ? ['http://ci.pelicargo', 'CI_Placeholder'] : [null, null];

const SUPABASE_URL = ciUrl || ENV.SUPABASE_URL || 'missing_env_var';
const SUPABASE_KEY = ciKey || ENV.SUPABASE_KEY || 'missing_env_var';

if (!SUPABASE_URL || !SUPABASE_KEY) {
  throw new Error('Missing Supabase URL or Key');
}

export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY);
