import { Checkbox, Highlight, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { GATEWAYS, METERS_TO_MILES } from '@pelicargo/utils';
import { useCallback } from 'react';
import { useHits } from 'react-instantsearch-core';

enum SearchType {
  Airport,
  Address,
}

type Props = {
  selectedHits: string[];
  isDestination: boolean;
  searchType: SearchType;
  query: string;
  hasSelectedAddress: boolean;
  handleHitClick: (id: string) => () => void;
};

export const RoutePickerHits = ({
  selectedHits = [],
  isDestination,
  searchType,
  query,
  hasSelectedAddress,
  handleHitClick,
}: Props) => {
  const { hits } = useHits();
  // Not an ideal situation, but with the faster TS reset the order has changed. This preserves the old order.
  hits?.sort(
    (a, b) =>
      GATEWAYS.indexOf(a.id as string) - GATEWAYS.indexOf(b.id as string),
  );

  const renderHit = useCallback(
    (hit) => {
      const isSelected = selectedHits?.includes(hit?.airport_id);

      if (isDestination && hit.airport_main_gateway && hasSelectedAddress) {
        return;
      }

      const itemSelectedByOrigin = hits?.find((node) => node === hit?.airport_id);
      if (isDestination && itemSelectedByOrigin) return;

      const distance = Math.round((hit?.geo_distance_meters?.airport_latlng || 0) * METERS_TO_MILES).toFixed(0);

      const isByAirport = searchType === SearchType.Airport;

      if (!hit?.airport_main_gateway && isByAirport && !query) {
        return;
      }

      return (
        <Checkbox
          key={hit?.airport_id}
          size="sm"
          w="full"
          colorScheme="blue"
          fontWeight={hit?.airport_main_gateway ? 'bold' : 'unset'}
          color={isSelected ? 'text.default' : 'text.subtle'}
          sx={{
            '.chakra-checkbox__label': {
              width: '100%',
            },
          }}
          isChecked={isSelected}
          onChange={handleHitClick(hit?.airport_id)}
        >
          <Stack direction="row" align="center" justify="space-between" spacing="4" w="full">
            <Stack direction="row" align="center" justify="flex-start" w="full" flex="4">
              <Show if={hit?.geo_distance_meters}>
                <Text w="fit-content" minW="50px" whiteSpace="nowrap">
                  {distance} mi
                </Text>
              </Show>
              <Stack direction="row">
                <Text w="auto" whiteSpace="nowrap" noOfLines={isByAirport ? undefined : 1}>
                  <Highlight query={query} styles={{ fontWeight: 'bold' }}>
                    {hit?.airport_name}
                  </Highlight>
                </Text>
              </Stack>
            </Stack>
            <Stack direction="row" align="center" justify="flex-end" w="fit-content" flex="1">
              <Text fontSize="sm">
                <Highlight query={query} styles={{ fontWeight: 'bold' }}>
                  {hit?.airport_id}
                </Highlight>
              </Text>
            </Stack>
          </Stack>
        </Checkbox>
      );
    },
    [handleHitClick, hasSelectedAddress, hits, isDestination, query, searchType, selectedHits],
  );

  return (
    <Stack>
      {hits?.map((hit) => {
        return renderHit(hit);
      })}
    </Stack>
  );
};
