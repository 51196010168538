import { Button, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { OnboardingStep } from '@pelicargo/types';
import { useError } from '@pelicargo/ui';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { OnboardingLayout } from '../../components/OnboardingLayout';
import { Page } from '../../components/Page';
import { SimpleForm } from '../../components/SimpleForm';
import { SimpleInput } from '../../components/SimpleInput';
import { SimplePhoneNumberInput } from '../../components/SimplePhoneNumberInput';
import { SimpleSelect } from '../../components/SimpleSelect';
import { trpc } from '../../config/trpc';
import { countries } from '../../constants/countries';
import { useScreenView } from '../../hooks/useScreenView';
import { getRouteForOnboardingStep } from '../../utils/getRouteForOnboardingStep';
import { MixpanelEvent, trackEvent } from '../../utils/mixpanel';

type FormValues = {
  preferredName: string;
  fullName: string;
  phoneNumber: string;
  countryCode: string;
};

export const RegisterPersonal = () => {
  const navigate = useNavigate();
  const { handleError } = useError();
  useScreenView(MixpanelEvent.PersonalView);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState<CountryCode>('US');

  const { mutateAsync: completePersonalOnboarding } = trpc.completePersonalOnboarding.useMutation();

  // The schema is put here because it needs to be redefined every time the country changes
  const schema = z
    .object({
      preferredName: z.string({ required_error: 'Preferred name is required' }),
      fullName: z.string({ required_error: 'Full name is required' }),
      phoneNumber: z.string({ required_error: 'Phone number is required' }),
      countryCode: z.string({ required_error: 'Country is required' }),
    })
    .superRefine((input, ctx) => {
      if (!isValidPhoneNumber(input.phoneNumber, input.countryCode as CountryCode)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Invalid phone number`,
          path: ['phoneNumber'],
        });
      }
    });

  const methods = useForm<Partial<FormValues>>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      preferredName: '',
      fullName: '',
      countryCode: 'US',
      phoneNumber: '',
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        trackEvent(MixpanelEvent.PersonalSubmit);
        setIsSubmitting(true);
        const phoneNumber = parsePhoneNumber(values.phoneNumber, country as CountryCode).number;

        const data = await completePersonalOnboarding({
          preferred_name: values.preferredName,
          full_name: values.fullName,
          phone_number: phoneNumber,
        });
        const nextRoute = getRouteForOnboardingStep(data as OnboardingStep);
        navigate(nextRoute);
      } catch (error) {
        handleError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [completePersonalOnboarding, country, handleError, navigate],
  );

  return (
    <Page title="Register" showHeader={false} showFooter={false}>
      <OnboardingLayout heading="Personal details">
        <SimpleForm {...methods} onSubmit={handleSubmit}>
          <Stack spacing="3">
            <Stack direction={{ base: 'column', lg: 'row' }} align="center" w="full" spacing="3">
              <SimpleInput
                name="preferredName"
                label="Preferred Name"
                placeholder="Preferred Name"
                variant="outline"
                w="full"
              />
              <SimpleInput name="fullName" label="Full Name" placeholder="Full Name" variant="outline" w="full" />
            </Stack>

            <Stack direction={{ base: 'column', lg: 'row' }} spacing="3" align="baseline">
              <SimpleSelect
                name="countryCode"
                label="Country"
                placeholder="Country"
                variant="outline"
                autoComplete="off"
                options={countries.map((country) => ({
                  label: `${country?.flag} ${country?.code} ${country?.dialCode}`,
                  value: country?.code,
                }))}
                onChangeCallback={(value) => {
                  setCountry(value as CountryCode);
                }}
                width={{ base: 'full', lg: 'auto' }}
              />
              <SimplePhoneNumberInput
                name="phoneNumber"
                label="Phone number"
                placeholder="Phone number"
                variant="outline"
                w="full"
              />
            </Stack>

            <Button colorScheme="primary" size="lg" alignSelf="flex-end" type="submit" isLoading={isSubmitting}>
              Continue
            </Button>
          </Stack>
        </SimpleForm>
      </OnboardingLayout>
    </Page>
  );
};
