import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';

import { trpc } from '../config/trpc';

type Props = {
  requestId: number;
  refetch?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const CancelBookingModal = ({ requestId, refetch, isOpen, onClose }: Props) => {
  const toast = useToast();
  const { mutateAsync: cancelBooking, isLoading: isSubmitting } = trpc.cancelBooking.useMutation();

  const handleCancelBooking = async () => {
    try {
      await cancelBooking({ requestId });
      refetch?.();
      onClose();
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error?.message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Booking</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to cancel this booking? This action cannot be reversed.</ModalBody>

        <ModalFooter>
          <Stack direction="row" justify="flex-end" w="full">
            <Button onClick={onClose}>No, go back</Button>
            <Button colorScheme="red" onClick={handleCancelBooking} isLoading={isSubmitting}>
              Cancel Booking
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
