import { IconProps } from '@chakra-ui/react';
import { JSX } from 'react';

import { IconDrums } from '../components/Icons/IconDrums';
import { IconNonStackable } from '../components/Icons/IconNonStackable';
import { IconPails } from '../components/Icons/IconPails';
import { IconStackable } from '../components/Icons/IconStackable';
import { IconSupersack } from '../components/Icons/IconSupersack';

type OptionWithIcon = {
  label: string;
  value: string;
  icon: ({ ...rest }: IconProps) => JSX.Element;
};

export const HANDLING_OPTIONS: OptionWithIcon[] = [
  {
    label: 'Stackable',
    value: 'Stackable',
    icon: IconStackable,
  },
  {
    label: 'Non Stackable',
    value: 'NonStackable',
    icon: IconNonStackable,
  },
];

export const PACKING_OPTIONS: OptionWithIcon[] = [
  {
    label: 'Packed in Drums',
    value: 'Drums',
    icon: IconDrums,
  },
  { label: 'Packed in Pails', value: 'Pails', icon: IconPails },
  {
    label: 'Packed in Supersacks',
    value: 'Supersacks',
    icon: IconSupersack,
  },
];
