import { Icon, IconProps, useColorModeValue } from '@chakra-ui/react';

export const IconSupersack = ({ ...rest }: IconProps) => {
  return (
    <Icon w="24px" h="24px" fill="none" {...rest}>
      <path
        fill={useColorModeValue('#1d2025', '#fff')}
        fillRule="evenodd"
        d="M8.613 3.622c.053-.756.33-1.371.76-1.82a2.637 2.637 0 0 1 1.936-.8c.695.008 1.397.285 1.886.847.358.41.58.947.618 1.585a17.6 17.6 0 0 1 3.086.824c.049-.629.176-1.13.37-1.48.126-.225.316-.452.596-.548.316-.108.614-.007.83.175.199.17.344.415.449.684.108.276.19.612.24 1.004.047.355.069.763.06 1.223l.277.13a.75.75 0 0 1 .425.599c1.293 4.73 1.341 8.013.711 13.331 0 0 .014.674-.27.875-5.2 3.673-11.68 3.137-16.872-.443a.75.75 0 0 1-.316-.73c-.884-5.01-.85-8.042 0-13.17a.75.75 0 0 1 .524-.596c.371-.17.734-.33 1.09-.48.018-.276.05-.531.095-.763.143-.76.426-1.37.84-1.67.225-.163.521-.252.83-.15.291.097.484.33.603.572.135.277.21.638.223 1.069.34-.1.676-.19 1.009-.268ZM6.119 7.26c-.138.5-.894.466-.976-.056a14.26 14.26 0 0 1-.024-.16l-.37-.08c-.693 4.473-.678 7.275.12 11.815 1.286.864 2.355 1.432 3.505 1.834 1.052.369 2.197.608 3.672.793V8.536l-.8-.172.005.081a.5.5 0 0 1-.998.069 4.156 4.156 0 0 1-.009-.366L6.119 7.26Zm11.074-1.286c-2.109-.842-3.858-1.225-5.554-1.217-1.604.006-3.205.36-5.066 1.066l4.034.87c.113-.207.25-.392.406-.556a2.637 2.637 0 0 1 1.936-.8c.694.008 1.397.285 1.886.847.096.11.182.23.258.357l2.1-.567Zm1.093 1.258-2.827.764a3.86 3.86 0 0 1-.046.559.5.5 0 0 1-.989-.152l.018-.132-.896.242v12.884c1.2-.169 2.139-.367 3-.664.93-.322 1.797-.77 2.813-1.456.57-4.785.577-7.775-.367-11.697a.498.498 0 0 1-.706-.348Zm-.153-3.949c-.116.22-.232.658-.253 1.35.186.076.373.154.562.235a6.535 6.535 0 0 0-.05-.646 3.314 3.314 0 0 0-.179-.768 1.312 1.312 0 0 0-.08-.171ZM6.09 4.254a4.74 4.74 0 0 0-.027.158c.18-.068.36-.134.537-.196.024-.491-.042-.8-.118-.956v-.002c-.111.122-.283.42-.392.996Zm5.208-2.252a1.637 1.637 0 0 0-1.203.492 1.769 1.769 0 0 0-.451.926 12.71 12.71 0 0 1 3.151-.115 1.55 1.55 0 0 0-.354-.8c-.28-.322-.699-.498-1.143-.503Zm.358 4.916 1.136.245 1.27-.343a1.532 1.532 0 0 0-1.125-.483 1.637 1.637 0 0 0-1.28.581Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
