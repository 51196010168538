import { BatteryType, BatteryTypeVariant, CargoType, PackingInstruction, Section } from '@pelicargo/types';

import { RequestFormValues } from '../hooks/request/useForm';

const formatEmptyString = (value: any) => (value === '' ? null : value);

const batteryVariantMapper: Record<BatteryTypeVariant, any> = {
  [BatteryTypeVariant.LiIon_UN3481_PI967]: {
    class: 9,
    un_number: 3481,
    packing_instruction: PackingInstruction.PI967,
    section: Section.II,
  },
  [BatteryTypeVariant.LiIon_UN3481_PI966]: {
    class: 9,
    un_number: 3481,
    packing_instruction: PackingInstruction.PI966,
    section: Section.II,
  },
  [BatteryTypeVariant.LiMetal_UN3091_PI969]: {
    class: 9,
    un_number: 3091,
    packing_instruction: PackingInstruction.PI969,
    section: Section.II,
  },
  [BatteryTypeVariant.LiMetal_UN3091_PI970]: {
    class: 9,
    un_number: 3091,
    packing_instruction: PackingInstruction.PI970,
    section: Section.II,
  },
};

const formatBatteryTypeVariant = (batteryTypeVariant: BatteryTypeVariant) => batteryVariantMapper[batteryTypeVariant];

const getCargoType = (cargoType: CargoType, batteryType: BatteryType | '') => {
  if (cargoType !== CargoType.Batteries) return cargoType;

  switch (batteryType) {
    case BatteryType.DG_BATTERIES_IN_EQUIPMENT:
      return CargoType.DangerousGoods;
    case BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT:
      return CargoType.GeneralCargo;
    default:
      return null;
  }
};

type FormatRequestFormOptions = {
  isFinalFormat?: boolean;
};

export const formatRequestForm = (values: RequestFormValues, options?: FormatRequestFormOptions) => {
  const cargo = values?.cargo?.map((cargo) => ({
    ...cargo,
    quantity: Number(cargo.quantity) ?? 1,
    length: Number(cargo.length) || null,
    width: Number(cargo.width) || null,
    height: Number(cargo.height) || null,
    weight: Number(cargo.weight) || null,
  }));

  const valuesWithNoEmptyStrings = Object.fromEntries(
    Object.entries(values).map(([key, value]) => [key, formatEmptyString(value)]),
  );

  const finalFormatted = {
    ...valuesWithNoEmptyStrings,
    cargo,
    cargo_type: options?.isFinalFormat ? getCargoType(values?.cargo_type, values?.battery_type) : values?.cargo_type,
    ...(options?.isFinalFormat && !!values?.battery_type_variant
      ? formatBatteryTypeVariant(values?.battery_type_variant as BatteryTypeVariant)
      : {}),
  };

  return {
    ...finalFormatted,
    cargo,
  };
};
