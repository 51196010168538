import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { Footer } from '../Footer';
import { Header } from '../Header';

type Props = BoxProps & {
  title: string;
  showHeader?: boolean;
  showFooter?: boolean;
  children: ReactNode;
};

export const Page = ({ title = '', showHeader = true, showFooter = true, children, ...rest }: Props) => {
  return (
    <>
      <Helmet>
        <title>{title} | Pelicargo</title>
      </Helmet>
      <Flex minH="100vh" h="full" overflowY="auto" direction="column" position="relative" bgColor="bg.default">
        <Show if={showHeader}>
          <Header />
        </Show>
        <Box {...rest}>{children}</Box>
        <Show if={showFooter}>
          <Footer />
        </Show>
      </Flex>
    </>
  );
};
