import { Center, IconButton, Input, List, ListItem, Stack, Text } from '@chakra-ui/react';
import { ChangeEvent, useMemo } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

type Props = {
  page: number;
  pageCount: number;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  handleChange: (page: number) => void;
  handlePrevious: () => void;
  handleNext: () => void;
};

export const Pagination = ({
  page,
  pageCount,
  isPreviousDisabled,
  isNextDisabled,
  handleChange,
  handlePrevious,
  handleNext,
}: Props) => {
  const value = useMemo(() => page + 1, [page]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextPage = Number((event.target as any).value);
    handleChange?.(nextPage);
  };

  return (
    <List gap="1" display="flex" justifyContent="space-between" p={4} borderTopWidth="2px">
      <ListItem as={Center}>
        <Stack direction="row" align="center">
          <Text>Page</Text>
          <Input type="number" maxW="72px" value={value} onChange={onChange} />
          <Text>of {pageCount}</Text>
        </Stack>
      </ListItem>
      <Stack direction="row">
        <ListItem>
          <IconButton
            aria-label="Previous Page"
            icon={<HiChevronLeft />}
            isDisabled={isPreviousDisabled}
            onClick={handlePrevious}
          />
        </ListItem>
        <ListItem>
          <IconButton
            icon={<HiChevronRight />}
            aria-label="Next Page"
            isDisabled={isNextDisabled}
            onClick={handleNext}
          />
        </ListItem>
      </Stack>
    </List>
  );
};
