import { FormLabel, Input, Stack } from '@chakra-ui/react';
import { DimensionUnit } from '@pelicargo/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { SimpleField } from './SimpleField';

const getFieldName = (name: string, prefix?: string) => (prefix ? `${prefix}.${name}` : name);

type Props = {
  prefix?: string;
  dimensionUnit: DimensionUnit;
};

export const SimpleDimensionsInput = ({ dimensionUnit, prefix = '' }: Props) => {
  const { register, getFieldState } = useFormContext();

  const lengthField = register(getFieldName('length', prefix));
  const widthField = register(getFieldName('width', prefix));
  const heightField = register(getFieldName('height', prefix));

  const lengthErrors = getFieldState(getFieldName('length', prefix));
  const widthErrors = getFieldState(getFieldName('width', prefix));
  const heightErrors = getFieldState(getFieldName('height', prefix));

  const errorMessage = useMemo(() => {
    if (lengthErrors.error) return lengthErrors.error.message;
    if (widthErrors.error) return widthErrors.error.message;
    if (heightErrors.error) return heightErrors.error.message;
    return null;
  }, [lengthErrors, widthErrors, heightErrors]);

  return (
    <SimpleField errorMessage={errorMessage}>
      <Stack direction="row" align="center" spacing="2">
        <Stack spacing={0}>
          <FormLabel variant="inputLabel">L ({dimensionUnit})</FormLabel>
          <Input
            type="number"
            variant="unstyled"
            placeholder="L"
            fontSize="md"
            _placeholder={{ color: 'text.subtle' }}
            {...lengthField}
          />
        </Stack>
        <Stack spacing={0}>
          <FormLabel variant="inputLabel">W ({dimensionUnit})</FormLabel>
          <Input
            type="number"
            variant="unstyled"
            placeholder="W"
            fontSize="md"
            _placeholder={{ color: 'text.subtle' }}
            {...widthField}
          />
        </Stack>
        <Stack spacing={0}>
          <FormLabel variant="inputLabel">H ({dimensionUnit})</FormLabel>
          <Input
            type="number"
            variant="unstyled"
            placeholder="H"
            fontSize="md"
            _placeholder={{ color: 'text.subtle' }}
            {...heightField}
          />
        </Stack>
      </Stack>
    </SimpleField>
  );
};
