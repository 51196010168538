import { Card, CardBody, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import { Show } from '@pelicargo/ui';
import { HiChevronRight } from 'react-icons/hi';

import { API } from '../utils/apiTypes';

type CompanyCardProps = {
  account: API.AccountInvite['account'];
  onClick?: () => void;
  isLoading?: boolean;
};

export const CompanyCard = ({ account, onClick, isLoading = false }: CompanyCardProps) => {
  return (
    <Card key={account.id} cursor="pointer" shadow="none" _hover={{ bg: 'gray.50' }} onClick={onClick}>
      <CardBody>
        <Stack direction="row" align="center" justify="space-between">
          <Flex />
          <Stack spacing="0">
            <Text fontWeight="bold">{account?.name}</Text>
            <Show if={!!account?.iata_number}>
              <Text textStyle="body">{account?.iata_number}</Text>
            </Show>
          </Stack>
          <IconButton
            icon={<HiChevronRight />}
            aria-label="Select Company Button"
            variant="unstyled"
            color="orange.500"
            colorScheme="primary"
            size="lg"
            isLoading={isLoading}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};
