import { Center, Flex, FlexProps, Text } from '@chakra-ui/react';

import { defaultWeekStyleConfig, WeekStyleConfig } from './type';

type Props = FlexProps & {
  weekStyleConfig?: Partial<WeekStyleConfig>;
};

export const Week = ({ weekStyleConfig, ...props }: Props) => {
  const weekString = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const mergeWeekStyle = weekStyleConfig ? { ...defaultWeekStyleConfig, ...weekStyleConfig } : defaultWeekStyleConfig;

  const { fontSize } = mergeWeekStyle;
  return (
    <Flex {...props}>
      {weekString.map((weekDay) => {
        return (
          <Center flex="1" key={weekDay}>
            <Text fontSize={fontSize} fontWeight="bold" color="muted">
              {weekDay}
            </Text>
          </Center>
        );
      })}
    </Flex>
  );
};
