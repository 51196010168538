import { Box, Stack, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from '../Logo';

export const Footer = memo(() => {
  return (
    <Box w="full" px={{ base: '4', lg: '10' }}>
      <Stack
        direction="row"
        align="center"
        justify={{ base: 'center', md: 'space-between' }}
        w="full"
        spacing="4"
        as="footer"
        role="contentinfo"
        wrap="wrap"
        borderTopWidth="1px"
        borderTopColor="border.default"
        py="8"
      >
        <Stack direction="row" align="center" spacing="8">
          <Link to="/">
            <Logo />
          </Link>
        </Stack>
        <Text fontSize="sm">© Pelicargo Inc. | All rights reserved.</Text>
      </Stack>
    </Box>
  );
});
