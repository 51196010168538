import { Stack, useToast } from '@chakra-ui/react';
import React, { createContext, useContext } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

type SimpleFormContextValues = {
  submitOnBlur?: boolean;
  simpleOnBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;

  defaultValues?: Record<string, any>;
  updateField: (fieldName: string, value?: any) => Promise<void> | void;
  onValid: (fields: Record<string, any>) => Promise<any> | any;
  onSubmit: (fields: Record<string, any>) => Promise<any> | any;
  validateName: (name: string, value?: any) => Promise<Record<string, any> | undefined>;
  validateField: (name: string, value?: any) => Promise<Record<string, any> | undefined>;
  ensureDifferent: (name: string, value?: any) => Record<string, any> | undefined;
};

export const SimpleFormContext = createContext({} as SimpleFormContextValues);
export const useSimpleFormContext = () => useContext(SimpleFormContext);

type SimpleFormProps = UseFormReturn & {
  onValid?: (fields: Record<string, any>) => Promise<any> | any;
  onSubmit?: (fields: Record<string, any>) => Promise<any> | any;
  children: React.ReactNode;
  defaultValues?: Record<string, any>;
  submitOnBlur?: boolean;
};
export const SimpleForm = ({
  onValid,
  onSubmit,
  children,
  defaultValues,
  submitOnBlur = true,
  ...methods
}: SimpleFormProps) => {
  const toast = useToast({ size: 'sm' });

  const validateName = async (name: string, value?: any): Promise<Record<string, any> | undefined> => {
    const isValid = await methods.trigger(name);
    if (!isValid) return;

    value = value ?? methods.getValues(name);

    return { [name]: value };
  };

  const ensureDifferent = (key: string, value?: any): Record<string, any> | undefined => {
    // TODO: manage comparing types better than loose comparisons
    // eslint-disable-next-line eqeqeq
    const isDifferent = (defaultValues as any)?.[key] != value;
    if (!isDifferent) return;

    return { [key]: value };
  };

  const validateField = async (key: string, value?: any) => {
    let field = await validateName(key, value);
    if (!field) return;

    field = ensureDifferent(key, field[key]);
    if (!field) return;

    return field;
  };

  const updateField = async (fieldName: string, value?: any) => {
    const field = await validateField(fieldName, value);
    if (field?.[fieldName] === undefined) return;
    if (!onValid) return;

    try {
      await onValid?.(field);
      toast({ title: 'Successfully updated', status: 'success' });
    } catch (error) {
      toast({
        title: "Oops, that didn't work",
        status: 'error',
        description: error?.message,
      });
    }
  };

  const simpleOnBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!submitOnBlur) return;

    const fieldName = event.target.name as string;
    await updateField(fieldName);
  };

  return (
    <SimpleFormContext.Provider
      value={{
        simpleOnBlur,
        onValid,
        validateName,
        defaultValues,
        ensureDifferent,
        validateField,
        updateField,
        submitOnBlur,
        onSubmit,
      }}
    >
      <FormProvider {...methods}>
        <form style={{ width: '100%', height: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack spacing={4}>{children}</Stack>
        </form>
      </FormProvider>
    </SimpleFormContext.Provider>
  );
};
