import { Container, Heading, Stack } from '@chakra-ui/react';
import { BusinessType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { useEffect, useState } from 'react';

import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';
import { useTargetAccount } from '../../hooks/useTargetAccount';
import { CONTAINER_2XL } from '../../utils/constants';

export const Metrics = () => {
  const { targetAccount } = useTargetAccount();
  const isAirline = targetAccount?.business_type === BusinessType.AIRLINE;

  const { data: iframeCustomerUrl } = trpc.getMetricsUrl.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: !isAirline,
  });
  const { data: iframeAirlineUrl } = trpc.getAirlineDashboardUrl.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: isAirline,
  });
  const [height, setHeight] = useState<number>(1900);

  useEffect(() => {
    const recalcScreen = () => {
      // Not the prettiest solution, but I'm not a frontend dev.
      // Metabase reorganizes itself at a width of 770, but we need to account for
      // the main page's padding around the iframe, which bumps it up to a magic 822
      if (window.innerWidth >= 1092) {
        setHeight(1900);
      } else if (window.innerWidth >= 992) {
        setHeight(3930);
      } else {
        setHeight(1900);
      }
    };
    recalcScreen();
    addEventListener('resize', recalcScreen);

    return () => removeEventListener('resize', recalcScreen);
  }, []);

  return (
    <Page title="Metrics" flex="1">
      <Container maxW={CONTAINER_2XL} px={{ base: '4', lg: '16' }} py="10">
        <Stack minH="full" w="full" spacing="6">
          <Heading variant="h3">Metrics</Heading>
          <Show if={!!iframeCustomerUrl}>
            <iframe
              src={iframeCustomerUrl}
              frameBorder={0}
              // If more rows are added, this will have to be adjusted, but otherwise should fit all
              height={height}
              style={{ backgroundColor: 'white' }}
            />
          </Show>
          <Show if={!!iframeAirlineUrl}>
            <iframe
              src={iframeAirlineUrl}
              frameBorder={0}
              // If more rows are added, this will have to be adjusted, but otherwise should fit all
              height={height}
              style={{ backgroundColor: 'white' }}
            />
          </Show>
        </Stack>
      </Container>
    </Page>
  );
};
