import { Alert, AlertIcon, Button, Container, Stack, Text } from '@chakra-ui/react';
import { useError, useSuccess } from '@pelicargo/ui';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Page } from '../../components/Page';
import { trpc } from '../../config/trpc';

export const Unsubscribe = () => {
  const { token } = useParams();
  const { handleSuccess } = useSuccess();
  const { handleError } = useError();

  const { mutate: unsubscribe } = trpc.unnsubscribe.useMutation();
  const { mutateAsync: resubscribe } = trpc.resubscribe.useMutation();

  useEffect(() => {
    unsubscribe({ id: token });
  }, [token, unsubscribe]);

  const handleResubscribe = async () => {
    try {
      await resubscribe({
        id: token,
      });
      handleSuccess('You are subscribed again.');
    } catch (error) {
      handleError('Something went wrong');
    }
  };

  return (
    <Page title="Unsubscribe" flex="1">
      <Container maxW="container.md" p="8" justifyContent="center" alignItems="center" h="80vh">
        <Stack justify="center" h="full" w="full" spacing="6">
          <Alert status="success" justifyContent="center">
            <AlertIcon />
            Unsubscribed Successfully
          </Alert>
          <Text>
            You have unsubscribed from this shipment and will not receive any further emails specific to this shipment.
          </Text>
          <Text>Didn't mean to unsubscribe? Click the button below to resubscribe!</Text>
          <Stack direction="row" align="center" w="full">
            <Button w="full" as={Link} to="/" aria-label="Home">
              Home
            </Button>
            <Button w="full" colorScheme="primary" onClick={handleResubscribe}>
              Resubscribe
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};
