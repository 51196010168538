import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { getPrettyTimeOfDay, prettyDate } from '@pelicargo/utils';
import { parseISO } from 'date-fns';
import { useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { HiCalendarDays } from 'react-icons/hi2';

import { Props, SimpleDatePicker } from './SimpleDatePicker';
import { SimpleField } from './SimpleField';

export const SimpleDatePickerInput = <FormSchema,>({
  name,
  label,
  helper,
  variant,
  matchWidth = true,
  timeOfDay = false,
  timeOfDayName = '',
  iconFontSize = '24px',
  ...rest
}: Props<FormSchema>) => {
  const disclosure = useDisclosure();
  const ref = useRef();
  useOutsideClick({ ref, handler: disclosure.onClose });
  const formValue = useWatch({ name });
  const timeOfDayValue = useWatch({ name: timeOfDayName });

  const handleOnChange = () => {
    if (!timeOfDay) disclosure?.onClose();
  };

  const displayValue = useMemo(() => {
    if (!formValue) return 'Choose a date';

    const formattedDate = prettyDate(parseISO(formValue), { timeZone: 'UTC' });

    return `${formattedDate}${timeOfDayValue ? `, ${getPrettyTimeOfDay(timeOfDayValue)}` : ''}`;
  }, [formValue, timeOfDayValue]);

  return (
    <Box w="full" ref={ref}>
      <Popover {...disclosure} matchWidth={matchWidth} closeOnBlur={false}>
        <PopoverTrigger>
          <SimpleField name={name} label={label} helper={helper} variant={variant} spacing={0} {...rest}>
            <Stack
              direction="row"
              alignItems="center"
              spacing="3"
              w="full"
              tabIndex={0}
              _focus={{
                outline: 'none',
                boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
                borderColor: 'blue.500',
              }}
            >
              <Icon as={HiCalendarDays} fontSize={iconFontSize} />
              <Text fontSize="md" color={formValue ? 'text.default' : 'text.subtle'} w="full">
                {displayValue}
              </Text>
            </Stack>
          </SimpleField>
        </PopoverTrigger>
        <PopoverContent w="full">
          <Flex py="3" px="2" w="full" boxShadow="sm" flexDir="column">
            <SimpleDatePicker
              name={name}
              timeOfDay={timeOfDay}
              timeOfDayName={timeOfDayName}
              onChange={handleOnChange}
            />
          </Flex>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
