import { Button, Stack } from '@chakra-ui/react';
import { FormType } from '@pelicargo/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../hooks/auth/useAuth';
import { SimpleForm } from '../SimpleForm';
import { SimpleInput } from '../SimpleInput';

export type ContactFormValues = {
  full_name: string;
  preferred_name: string;
  email: string;
  title: string;
  phone_number: string;
};

type Props = {
  defaultValues: Partial<ContactFormValues>;
  type: FormType;
  onSubmit: (values: ContactFormValues) => Promise<void>;
};

export const ContactForm = ({ defaultValues, type, onSubmit }: Props) => {
  const methods = useForm<ContactFormValues>({ defaultValues });
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const domain = user?.email?.split('@')?.[1] || 'example.com';

  const submitValues = async (values: ContactFormValues) => {
    try {
      setIsLoading(true);
      await onSubmit(values);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = methods.handleSubmit(submitValues);

  return (
    <SimpleForm {...(methods as any)} onSubmit={handleSubmit}>
      <Stack direction="column" spacing={4} w="full">
        <Stack direction={{ base: 'column', md: 'row' }} w="full">
          <SimpleInput name="full_name" label="Full Name" placeholder="Jane Doe" isRequired />
          <SimpleInput name="preferred_name" label="Preferred Name" placeholder="Jane" isRequired />
        </Stack>
        <SimpleInput name="title" label="Title" placeholder="Booking Specialist" />
        <SimpleInput name="email" label="Email" placeholder={`someone@${domain}`} isRequired />

        <SimpleInput name="phone_number" label="Phone" placeholder="Enter phone number" />
      </Stack>
      <Button colorScheme="primary" mt={4} w="full" isLoading={isLoading} onClick={handleSubmit}>
        {type} contact
      </Button>
    </SimpleForm>
  );
};
