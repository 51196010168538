import { Button, Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Contact, NotificationCategory, RequireOnly } from '@pelicargo/types';
import { Show, useError } from '@pelicargo/ui';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { trpc } from '../config/trpc';
import { useTargetAccount } from '../hooks/useTargetAccount';
import { API } from '../utils/api-typings';
import { ContactModal } from './ContactModal';
import { SimpleFieldArray } from './SimpleFieldArray';
import { SimpleReactSelect } from './SimpleReactSelect';

type Props = {
  notificationCategory: NotificationCategory;
};

export const DefaultContactsCard = ({ notificationCategory }: Props) => {
  const params = useParams<{ requestId: string }>();
  const { handleError } = useError();
  const { mutateAsync: createContact, data: newContact } = trpc.createContact.useMutation();
  const [newContactEmail, setNewContactEmail] = useState('');

  const createContactDisclosure = useDisclosure();

  const { targetAccount } = useTargetAccount();
  const { data: defaultContactsFromAccount } = trpc.getDefaultContactsByAccount.useQuery(
    {
      id: targetAccount.id,
      updates_for: notificationCategory,
    },
    { enabled: !!targetAccount.id },
  );
  const { data: defaultContactsFromRequest } = trpc.getDefaultContactsByRequest.useQuery(
    {
      id: Number(params?.requestId),
      updates_for: notificationCategory,
    },
    { enabled: !!params?.requestId },
  );
  // Unique contacts by contact_id prioritizing request contacts
  const defaultContacts = useMemo(
    () => uniqBy([...(defaultContactsFromRequest || []), ...(defaultContactsFromAccount || [])], 'contact_id'),
    [defaultContactsFromAccount, defaultContactsFromRequest],
  );

  const contacts = defaultContacts?.map((defaultContact) => defaultContact.contact) || [];
  const { data: contactsFromAccount } = trpc.getContactsByAccountId.useQuery({
    id: Number(targetAccount?.id),
  });

  const contactOptions = useMemo(() => {
    const contacts = contactsFromAccount || [];

    if (newContact) {
      contacts.push(newContact);
    }

    return contacts.map((contact) => ({
      label: contact.email,
      value: String(contact.id),
    }));
  }, [contactsFromAccount, newContact]);

  const isNoDefaultContacts = defaultContacts?.length === 0;

  const [isEditing, setIsEditing] = useState(false);

  const notificationCategoryOptions = Object.values(API.NotificationCategory).map((category) => ({
    label: `${category} emails`,
    value: category,
  }));

  const renderDefaultContact = (contact: RequireOnly<Contact, 'preferred_name' | 'email' | 'id'>) => {
    return (
      <Text key={contact?.id} textStyle="captionRegular">
        {contact?.preferred_name} {`<${contact?.email}>`}
      </Text>
    );
  };

  const ActionButton = useMemo(() => {
    if (isNoDefaultContacts) {
      return null;
    }

    if (!isEditing) {
      return (
        <Button leftIcon={<HiPencilAlt />} onClick={() => setIsEditing(true)}>
          Edit
        </Button>
      );
    }
  }, [isEditing, isNoDefaultContacts]);

  const handleCreateContact = useCallback(
    async (values) => {
      try {
        await createContact(values);

        createContactDisclosure.onClose();
      } catch (error) {
        handleError(error);
      }
    },
    [createContact, createContactDisclosure, handleError],
  );

  return (
    <>
      <Stack spacing="6">
        <Stack direction="row" justify="space-between">
          <Stack spacing="0">
            <Heading variant="h4">Additional Email Notifications</Heading>
            <Text textStyle="captionRegular">
              Everyone in the list below, in addition to you, will be notified about this request.
            </Text>
          </Stack>
          {ActionButton}
        </Stack>
        <Stack borderWidth="thin" p="4" borderRadius="md" pr={{ base: '4', md: '16' }}>
          <Show if={isNoDefaultContacts || isEditing}>
            <SimpleFieldArray
              name="default_contacts"
              ignoreStyles
              spacing={4}
              w="full"
              addButtonLabel="Add Contact"
              form={({ prefix }) => (
                <Stack direction="row" spacing="4" justify="stretch">
                  <SimpleReactSelect
                    variant="outline"
                    name={`${prefix}.contact_id`}
                    label="Contact"
                    simpleFieldProps={{ w: 'full' }}
                    type="creatable"
                    isValidNewOption={() => true}
                    createOptionPosition="first"
                    formatCreateLabel={(val?: string) => {
                      if (!val) return 'Add new contact';
                      return `Add ${val}`;
                    }}
                    onCreateOption={(inputValue?: string) => {
                      setNewContactEmail(inputValue);
                      createContactDisclosure.onOpen();
                    }}
                    options={contactOptions}
                    p={0}
                  />
                  <SimpleReactSelect
                    variant="outline"
                    name={`${prefix}.updates_for`}
                    label="Email Preferences"
                    simpleFieldProps={{ w: 'full' }}
                    options={notificationCategoryOptions}
                    isMulti
                  />
                </Stack>
              )}
            />
          </Show>
          <Show if={!isEditing && !isNoDefaultContacts}>{contacts?.map(renderDefaultContact)}</Show>
        </Stack>
      </Stack>
      <ContactModal
        defaultValues={{ email: newContactEmail }}
        type="Create"
        onSubmit={handleCreateContact}
        {...createContactDisclosure}
      />
    </>
  );
};
