import { Text } from '@chakra-ui/react';
import { SelectOption } from '@pelicargo/types';
import { useFormContext, useWatch } from 'react-hook-form';

import { RadioCard, RadioCardGroup } from './RadioCardGroup';
import { SimpleField } from './SimpleField';

type Props = {
  name: string;
  variant?: string;
  options: SelectOption[];
};

export const SimpleRadio = ({ name, variant, options }: Props) => {
  const { setValue } = useFormContext();

  const handleChange = (value: string) => {
    setValue(name, value);
  };

  const value = useWatch({ name });

  return (
    <SimpleField name={name} variant={variant}>
      <RadioCardGroup spacing="1" value={value} onChange={handleChange}>
        {options?.map((option) => (
          <RadioCard key={option?.value} value={option?.value}>
            <Text color="text.paragraph" fontWeight="medium" fontSize="sm">
              {option?.label}
            </Text>
          </RadioCard>
        ))}
      </RadioCardGroup>
    </SimpleField>
  );
};
