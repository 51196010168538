import { OnboardingStep } from '@pelicargo/types';
import * as Sentry from '@sentry/react';
import { isEmpty, isEqual } from 'lodash';
import { createContext, ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { trpc } from '../config/trpc';
import { TargetAccount, useTargetAccount } from '../hooks/useTargetAccount';
import { supabaseClient } from '../utils/supabaseClient';

type User = {
  id: number;
  created_at: Date;
  updated_at: Date;
  email: string;
  supabase_user_id: string;
  first_name: string | null;
  last_name: string | null;
  preferred_name: string | null;
  full_name: string | null;
  title: string | null;
  phone_number: string | null;
  // JSON object
  hints: any;
  email_verified_at: Date | null;
  is_admin: boolean;
  onboarding_step: OnboardingStep;
  preferred_account_user_id: number | null;
  contact_id: number | null;
  accounts: TargetAccount[];
  preferredAccount: TargetAccount;
};

type Context = {
  isLoggedIn: boolean;
  token?: string;
  isSuperAdmin: boolean;
  user: User;
  isLoading?: boolean;
  logout: () => void;
};

export const AuthContext = createContext({} as Context);

type Props = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { targetAccount, changeTargetAccount } = useTargetAccount();

  const [token, setToken] = useLocalStorage<string>('accessToken', '');
  const [user, setUser] = useLocalStorage<User>('user', null);

  trpc.onUserUpdated.useSubscription(undefined, {
    enabled: !!token,
    onData: (data) => {
      setUser(data);
    },
    onError: (error) => {
      console.error(error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    const preferredAccount = user?.preferredAccount;
    const firstAccount = user?.accounts?.[0];
    const nextTargetAccount = preferredAccount || firstAccount;
    const hasCompletedOnboarding =
      user?.onboarding_step === OnboardingStep.SIGNATURE || user?.onboarding_step === OnboardingStep.COMPLETE;

    if (isEmpty(targetAccount) && hasCompletedOnboarding) {
      changeTargetAccount({
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
        business_type: nextTargetAccount?.business_type,
      });
    }
  }, [
    changeTargetAccount,
    user?.accounts,
    user?.preferredAccount,
    targetAccount,
    targetAccount?.id,
    user?.onboarding_step,
  ]);

  useEffect(() => {
    const nextTargetAccount = user?.accounts?.find((n) => n.id === targetAccount?.id);

    const isCurrentTargetAccountUpdated = !isEqual(
      {
        id: targetAccount?.id,
        name: targetAccount?.name,
        iata_number: targetAccount?.iata_number,
        is_iata_number_verified: targetAccount?.is_iata_number_verified,
        business_type: targetAccount?.business_type,
      },
      {
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
        business_type: nextTargetAccount?.business_type,
      },
    );

    if (isCurrentTargetAccountUpdated && nextTargetAccount?.id) {
      changeTargetAccount({
        id: nextTargetAccount?.id,
        name: nextTargetAccount?.name,
        iata_number: nextTargetAccount?.iata_number,
        is_iata_number_verified: nextTargetAccount?.is_iata_number_verified,
        business_type: nextTargetAccount?.business_type,
      });
    }
  }, [changeTargetAccount, user?.accounts, user?.preferredAccount, targetAccount, targetAccount?.id]);

  const handleLogout = useCallback(async () => {
    try {
      setToken(null);
      setUser(null);
      Sentry.setUser(null);
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }, [navigate, setToken, setUser]);

  useEffect(() => {
    const { data: listener } = supabaseClient.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') setToken(session?.access_token);
      if (event === 'SIGNED_OUT') handleLogout();
    });

    return () => listener.subscription.unsubscribe();
  }, [handleLogout, setToken, setUser]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        user,
        token,
        isSuperAdmin: user?.is_admin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
